// frontend/src/utils/joinUrl.js

import config from '../components/contexts/config'; // Ensure correct path

/**
 * Utility function to safely join multiple URL parts.
 * Ensures that there is exactly one slash between each part.
 * @param  {...string} parts - The URL segments to join.
 * @returns {string} - The concatenated URL.
 */
export const joinUrl = (...parts) => {
  return parts
    .map((part, index) => {
      if (typeof part !== 'string') {
        console.error(`joinUrl: Argument at index ${index} is not a string.`, { part });
        return '';
      }
      if (index === 0) {
        // Remove trailing slashes from the first part
        return part.replace(/\/+$/, '');
      } else {
        // Remove leading and trailing slashes from subsequent parts
        return part.replace(/^\/+|\/+$/g, '');
      }
    })
    .filter(Boolean)
    .join('/');
};

/**
 * Utility function to get full image URL
 * @param {string} imagePath - Path to the image
 * @returns {string} - Full image URL
 */
export const getImageUrl = (imagePath) => {
  if (!imagePath) return '/default-image.jpg'; // fallback image

  // If imagePath is already absolute, just return it
  if (/^(http|https):\/\//i.test(imagePath)) {
    return imagePath;
  }

  // Use IMAGE_BASE_URL for constructing absolute image URLs
  return joinUrl(config.IMAGE_BASE_URL, imagePath);
};
console.log('IMAGE_BASE_URL in joinUrl:', config.IMAGE_BASE_URL); // Debugging