// src/facebook.js

import React, { useEffect } from 'react';
import { useUserContext } from './components/contexts/UserContext'; // Adjust the import path as needed

const FacebookSDK = () => {
  const { loginWithFacebook } = useUserContext(); // Ensure this function is defined in your context

  useEffect(() => {
    // Load the Facebook SDK script
    ((d, s, id) => {
      const element = d.getElementById(id);
      if (element) {
        return;
      }
      const fjs = d.getElementsByTagName(s)[0];
      const js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      js.onload = () => {
        window.FB.init({
          appId: process.env.REACT_APP_FACEBOOK_APP_ID, // Use environment variable
          cookie: true,
          xfbml: true,
          version: 'v13.0', // Use the latest SDK version
        });

        window.FB.AppEvents.logPageView();
      };
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, []);

  return null; // This component doesn't render anything
};

export default FacebookSDK;
