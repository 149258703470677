// frontend/src/components/contexts/CartContext.js

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useUserContext } from './UserContext';
import api from '../../api';
import logger from '../../utils/logger';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../features/notifcation/notificationsSlice';
import { v4 as uuidv4 } from 'uuid';

// Create CartContext with default values
const CartContext = createContext({
  cartItems: [],
  savedItems: [],
  orderType: 'pickup', // Default orderType
  setOrderType: () => {},
  fetchCart: () => {},
  fetchSavedItems: () => {},
  addItemToCart: () => {},
  removeItemFromCart: () => {},
  increaseItemQuantity: () => {},
  decreaseItemQuantity: () => {},
  increaseIngredientQuantity: () => {},
  decreaseIngredientQuantity: () => {},
  moveToSaved: () => {},
  moveToCart: () => {},
  clearCart: () => {},
});

// Export useCart hook for easy consumption with context validation
export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};

// CartProvider component to wrap around parts of the app that need access to cart state
export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [savedItems, setSavedItems] = useState([]);
  const [orderType, setOrderType] = useState('pickup'); // Default to 'pickup'
  const { user, token } = useUserContext();
  const dispatch = useDispatch(); // Initialize dispatch

  /**
   * Increases the quantity of a cart item by a specified amount.
   * For authenticated users, sends a PUT request to the backend.
   * For unauthenticated users, updates local storage.
   * @param {number|string} itemId - The ID of the cart item to increase.
   * @param {number} amount - The amount to increase (default is 1).
   */
  const increaseItemQuantity = useCallback(
    async (itemId, amount = 1) => {
      if (token) {
        try {
          const existingItem = cartItems.find((ci) => ci.id === itemId);
          if (!existingItem) {
            toast.error('Item not found in cart.');
            return;
          }

          const updatedItem = { ...existingItem, quantity: existingItem.quantity + amount };
          const response = await api.put(`/api/cart/${itemId}`, updatedItem, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          setCartItems((prevCart) =>
            prevCart.map((ci) => (ci.id === itemId ? response.data.data : ci))
          );
          logger.info(`Increased quantity for cart item ID ${itemId}`);

          // Dispatch notification to Redux store
          const notification = {
            id: uuidv4(),
            message: 'Item quantity increased.',
            timestamp: new Date().toISOString(),
          };
          dispatch(addNotification(notification));

          toast.success('Item quantity increased.');
        } catch (error) {
          logger.error(`Failed to increase quantity for cart item ID ${itemId}:`, error);
          toast.error('Failed to increase item quantity. Please try again.');
        }
      } else {
        // Handle unauthenticated users by updating local storage
        try {
          const localCart = JSON.parse(localStorage.getItem('cart')) || [];
          const itemIndex = localCart.findIndex((ci) => ci.id === itemId);
          if (itemIndex === -1) {
            toast.error('Item not found in cart.');
            return;
          }

          localCart[itemIndex].quantity += amount;
          localStorage.setItem('cart', JSON.stringify(localCart));
          setCartItems(localCart);
          logger.info(`Increased quantity for cart item ID ${itemId} in local cart`);

          // Dispatch notification to Redux store
          const notification = {
            id: uuidv4(),
            message: 'Item quantity increased.',
            timestamp: new Date().toISOString(),
          };
          dispatch(addNotification(notification));

          toast.success('Item quantity increased.');
        } catch (error) {
          logger.error(`Failed to increase quantity for cart item ID ${itemId} in local storage:`, error);
          toast.error('Failed to increase item quantity. Please try again.');
        }
      }
    },
    [token, cartItems, dispatch]
  );

  /**
   * Decreases the quantity of a cart item by a specified amount.
   * For authenticated users, sends a PUT request to the backend.
   * For unauthenticated users, updates local storage.
   * @param {number|string} itemId - The ID of the cart item to decrease.
   * @param {number} amount - The amount to decrease (default is 1).
   */
  const decreaseItemQuantity = useCallback(
    async (itemId, amount = 1) => {
      if (token) {
        try {
          const existingItem = cartItems.find((ci) => ci.id === itemId);
          if (!existingItem) {
            toast.error('Item not found in cart.');
            return;
          }

          if (existingItem.quantity - amount < 1) {
            toast.error('Quantity cannot be less than 1. To remove the item, use the remove option.');
            return;
          }

          const updatedItem = { ...existingItem, quantity: existingItem.quantity - amount };
          const response = await api.put(`/api/cart/${itemId}`, updatedItem, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          setCartItems((prevCart) =>
            prevCart.map((ci) => (ci.id === itemId ? response.data.data : ci))
          );
          logger.info(`Decreased quantity for cart item ID ${itemId}`);

          // Dispatch notification to Redux store
          const notification = {
            id: uuidv4(),
            message: 'Item quantity decreased.',
            timestamp: new Date().toISOString(),
          };
          dispatch(addNotification(notification));

          toast.success('Item quantity decreased.');
        } catch (error) {
          logger.error(`Failed to decrease quantity for cart item ID ${itemId}:`, error);
          toast.error('Failed to decrease item quantity. Please try again.');
        }
      } else {
        // Handle unauthenticated users by updating local storage
        try {
          const localCart = JSON.parse(localStorage.getItem('cart')) || [];
          const itemIndex = localCart.findIndex((ci) => ci.id === itemId);
          if (itemIndex === -1) {
            toast.error('Item not found in cart.');
            return;
          }

          if (localCart[itemIndex].quantity - amount < 1) {
            toast.error('Quantity cannot be less than 1. To remove the item, use the remove option.');
            return;
          }

          localCart[itemIndex].quantity -= amount;
          localStorage.setItem('cart', JSON.stringify(localCart));
          setCartItems(localCart);
          logger.info(`Decreased quantity for cart item ID ${itemId} in local cart`);

          // Dispatch notification to Redux store
          const notification = {
            id: uuidv4(),
            message: 'Item quantity decreased.',
            timestamp: new Date().toISOString(),
          };
          dispatch(addNotification(notification));

          toast.success('Item quantity decreased.');
        } catch (error) {
          logger.error(`Failed to decrease quantity for cart item ID ${itemId} in local storage:`, error);
          toast.error('Failed to decrease item quantity. Please try again.');
        }
      }
    },
    [token, cartItems, dispatch]
  );

  /**
   * Increases the quantity of an ingredient in a cart item by a specified amount.
   * For authenticated users, sends a PUT request to the backend.
   * For unauthenticated users, updates local storage.
   * @param {number} itemId - The ID of the cart item containing the ingredient.
   * @param {number} ingredientId - The ID of the ingredient to increase.
   * @param {number} amount - The amount to increase (default is 1).
   */
  const increaseIngredientQuantity = useCallback(
    async (itemId, ingredientId, amount = 1) => {
      if (token) {
        try {
          const existingItem = cartItems.find((ci) => ci.id === itemId);
          if (!existingItem) {
            toast.error('Item not found in cart.');
            return;
          }

          const updatedIngredients = existingItem.attributes.ingredients.map((ing) =>
            ing.ingredientId === ingredientId
              ? { ...ing, quantity: ing.quantity + amount }
              : ing
          );

          const updatedItem = {
            ...existingItem,
            attributes: {
              ...existingItem.attributes,
              ingredients: updatedIngredients,
            },
          };

          const response = await api.put(`/api/cart/${itemId}`, updatedItem, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          setCartItems((prevCart) =>
            prevCart.map((ci) => (ci.id === itemId ? response.data.data : ci))
          );
          logger.info(`Increased quantity for ingredient ID ${ingredientId} in cart item ID ${itemId}`);

          // Dispatch notification to Redux store
          const notification = {
            id: uuidv4(),
            message: 'Ingredient quantity increased.',
            timestamp: new Date().toISOString(),
          };
          dispatch(addNotification(notification));

          toast.success('Ingredient quantity increased.');
        } catch (error) {
          logger.error(`Failed to increase ingredient quantity for ingredient ID ${ingredientId}:`, error);
          toast.error('Failed to increase ingredient quantity. Please try again.');
        }
      } else {
        // Handle unauthenticated users by updating local storage
        try {
          const localCart = JSON.parse(localStorage.getItem('cart')) || [];
          const itemIndex = localCart.findIndex((ci) => ci.id === itemId);
          if (itemIndex === -1) {
            toast.error('Item not found in cart.');
            return;
          }

          const ingredientIndex = localCart[itemIndex].attributes.ingredients.findIndex(
            (ing) => ing.ingredientId === ingredientId
          );
          if (ingredientIndex === -1) {
            toast.error('Ingredient not found in cart item.');
            return;
          }

          localCart[itemIndex].attributes.ingredients[ingredientIndex].quantity += amount;

          localStorage.setItem('cart', JSON.stringify(localCart));
          setCartItems(localCart);
          logger.info(`Increased quantity for ingredient ID ${ingredientId} in local cart item ID ${itemId}`);

          // Dispatch notification to Redux store
          const notification = {
            id: uuidv4(),
            message: 'Ingredient quantity increased.',
            timestamp: new Date().toISOString(),
          };
          dispatch(addNotification(notification));

          toast.success('Ingredient quantity increased.');
        } catch (error) {
          logger.error(`Failed to increase ingredient quantity for ingredient ID ${ingredientId} in local storage:`, error);
          toast.error('Failed to increase ingredient quantity. Please try again.');
        }
      }
    },
    [token, cartItems, dispatch]
  );

  /**
   * Decreases the quantity of an ingredient in a cart item by a specified amount.
   * For authenticated users, sends a PUT request to the backend.
   * For unauthenticated users, updates local storage.
   * If the quantity reaches zero, the ingredient is removed from the cart item.
   * @param {number} itemId - The ID of the cart item containing the ingredient.
   * @param {number} ingredientId - The ID of the ingredient to decrease.
   * @param {number} amount - The amount to decrease (default is 1).
   */
  const decreaseIngredientQuantity = useCallback(
    async (itemId, ingredientId, amount = 1) => {
      if (token) {
        try {
          const existingItem = cartItems.find((ci) => ci.id === itemId);
          if (!existingItem) {
            toast.error('Item not found in cart.');
            return;
          }

          const updatedIngredients = existingItem.attributes.ingredients
            .map((ing) =>
              ing.ingredientId === ingredientId
                ? { ...ing, quantity: ing.quantity - amount }
                : ing
            )
            .filter((ing) => ing.quantity > 0); // Remove ingredients with quantity <= 0

          const updatedItem = {
            ...existingItem,
            attributes: {
              ...existingItem.attributes,
              ingredients: updatedIngredients,
            },
          };

          const response = await api.put(`/api/cart/${itemId}`, updatedItem, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          setCartItems((prevCart) =>
            prevCart.map((ci) => (ci.id === itemId ? response.data.data : ci))
          );
          logger.info(`Decreased quantity for ingredient ID ${ingredientId} in cart item ID ${itemId}`);

          // Dispatch notification to Redux store
          const notification = {
            id: uuidv4(),
            message: 'Ingredient quantity decreased.',
            timestamp: new Date().toISOString(),
          };
          dispatch(addNotification(notification));

          toast.success('Ingredient quantity decreased.');
        } catch (error) {
          logger.error(`Failed to decrease ingredient quantity for ingredient ID ${ingredientId}:`, error);
          toast.error('Failed to decrease ingredient quantity. Please try again.');
        }
      } else {
        // Handle unauthenticated users by updating local storage
        try {
          const localCart = JSON.parse(localStorage.getItem('cart')) || [];
          const itemIndex = localCart.findIndex((ci) => ci.id === itemId);
          if (itemIndex === -1) {
            toast.error('Item not found in cart.');
            return;
          }

          const ingredientIndex = localCart[itemIndex].attributes.ingredients.findIndex(
            (ing) => ing.ingredientId === ingredientId
          );
          if (ingredientIndex === -1) {
            toast.error('Ingredient not found in cart item.');
            return;
          }

          localCart[itemIndex].attributes.ingredients[ingredientIndex].quantity -= amount;

          // Remove ingredient if quantity <= 0
          if (localCart[itemIndex].attributes.ingredients[ingredientIndex].quantity <= 0) {
            localCart[itemIndex].attributes.ingredients.splice(ingredientIndex, 1);
          }

          localStorage.setItem('cart', JSON.stringify(localCart));
          setCartItems(localCart);
          logger.info(`Decreased quantity for ingredient ID ${ingredientId} in local cart item ID ${itemId}`);

          // Dispatch notification to Redux store
          const notification = {
            id: uuidv4(),
            message: 'Ingredient quantity decreased.',
            timestamp: new Date().toISOString(),
          };
          dispatch(addNotification(notification));

          toast.success('Ingredient quantity decreased.');
        } catch (error) {
          logger.error(`Failed to decrease ingredient quantity for ingredient ID ${ingredientId} in local storage:`, error);
          toast.error('Failed to decrease ingredient quantity. Please try again.');
        }
      }
    },
    [token, cartItems, dispatch]
  );

  /**
   * Adds an item to the cart.
   * For authenticated users, sends a POST request to the backend.
   * For unauthenticated users, updates local storage.
   * @param {Object} item - The item to add.
   * @param {number} item.productId - The product ID.
   * @param {Array} [item.selectedOptions] - Array of selected option objects with groupId and choiceId.
   * @param {number} [item.quantity=1] - The quantity (optional, defaults to 1).
   * @param {Object} [item.attributes] - Additional attributes like size and ingredients.
   * @param {number} [item.price] - Total price of the item.
   * @param {string} [item.imageUrl] - Image URL of the product.
   */
  const addItemToCart = useCallback(
    async (item) => {
      const {
        productId,
        selectedOptions = [],
        quantity = 1,
        attributes = {},
        price,
        imageUrl,
      } = item;

      if (!productId) {
        toast.error('Product ID is required to add to cart.');
        return;
      }

      if (token) {
        try {
          // Check if the item already exists in the cart based on productId, size, and selected options
          const existingItem = cartItems.find(
            (ci) =>
              ci.productId === productId &&
              ci.attributes.size === attributes.size &&
              JSON.stringify(ci.selectedOptions) === JSON.stringify(selectedOptions)
          );

          if (existingItem) {
            // If item exists, increase its quantity
            await increaseItemQuantity(existingItem.id, quantity);
          } else {
            // If item does not exist, add as new with selectedOptions and attributes
            const response = await api.post(
              `/api/cart`,
              {
                productId,
                selectedOptions,
                quantity,
                attributes,
                price,
                imageUrl,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            setCartItems((prevCart) => [...prevCart, response.data.data]);
            logger.info(`Added product ID ${productId} to cart for user ID ${user?.id || 'unknown'}`);

            // Dispatch notification to Redux store
            const notification = {
              id: uuidv4(),
              message: 'Item added to cart!',
              timestamp: new Date().toISOString(),
            };
            dispatch(addNotification(notification));

            toast.success('Item added to cart!');
          }
        } catch (error) {
          logger.error('Failed to add item to cart:', error);
          if (error.response && error.response.data && error.response.data.errors) {
            const errorMessages = error.response.data.errors.map((err) => err.msg).join(' ');
            toast.error(`Failed to add item to cart: ${errorMessages}`);
          } else {
            toast.error('Failed to add item to cart. Please try again.');
          }
        }
      } else {
        // Handle unauthenticated users by updating local storage
        try {
          const localCart = JSON.parse(localStorage.getItem('cart')) || [];
          // Check for duplicates based on productId, size, and selected options
          const existingIndex = localCart.findIndex(
            (ci) =>
              ci.productId === productId &&
              ci.attributes.size === attributes.size &&
              JSON.stringify(ci.selectedOptions) === JSON.stringify(selectedOptions)
          );

          if (existingIndex !== -1) {
            // If exists, increase quantity
            localCart[existingIndex].quantity += quantity;

            // Dispatch notification to Redux store
            const notification = {
              id: uuidv4(),
              message: 'Item quantity updated in cart!',
              timestamp: new Date().toISOString(),
            };
            dispatch(addNotification(notification));

            toast.success('Item quantity updated in cart!');
          } else {
            // Else, add as new with selectedOptions and attributes
            localCart.push({
              id: uuidv4(), // Assign a unique ID for local items
              productId,
              selectedOptions,
              quantity,
              attributes,
              price,
              imageUrl,
            });

            // Dispatch notification to Redux store
            const notification = {
              id: uuidv4(),
              message: 'Item added to cart!',
              timestamp: new Date().toISOString(),
            };
            dispatch(addNotification(notification));

            toast.success('Item added to cart!');
          }

          localStorage.setItem('cart', JSON.stringify(localCart));
          setCartItems(localCart);
          logger.info(`Added product ID ${productId} to local cart for unauthenticated user`);
        } catch (error) {
          logger.error('Failed to add item to local cart:', error);
          toast.error('Failed to add item to cart. Please try again.');
        }
      }
    },
    [token, cartItems, user?.id, dispatch, increaseItemQuantity]
  );

  /**
   * Removes an item from the cart.
   * For authenticated users, sends a DELETE request to the backend.
   * For unauthenticated users, updates local storage.
   * @param {Object} item - The item to remove.
   * @param {number} item.id - The ID of the cart item (for authenticated users).
   * @param {number} [item.index] - The index of the cart item (for unauthenticated users).
   */
  const removeItemFromCart = useCallback(
    async (item) => {
      if (token) {
        try {
          await api.delete(`/api/cart/${item.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setCartItems((prevCart) => prevCart.filter((ci) => ci.id !== item.id));
          logger.info(`Removed cart item ID ${item.id} for user ID ${user?.id || 'unknown'}`);

          // Dispatch notification to Redux store
          const notification = {
            id: uuidv4(),
            message: 'Item removed from cart.',
            timestamp: new Date().toISOString(),
          };
          dispatch(addNotification(notification));

          toast.info('Item removed from cart.');
        } catch (error) {
          logger.error(`Failed to remove cart item ID ${item.id}:`, error);
          toast.error('Failed to remove item from cart. Please try again.');
        }
      } else {
        // Handle unauthenticated users by updating local storage
        try {
          const localCart = JSON.parse(localStorage.getItem('cart')) || [];
          const updatedCart = localCart.filter((ci) => ci.id !== item.id);
          localStorage.setItem('cart', JSON.stringify(updatedCart));
          setCartItems(updatedCart);
          logger.info(`Removed cart item ID ${item.id} from local cart for unauthenticated user`);

          // Dispatch notification to Redux store
          const notification = {
            id: uuidv4(),
            message: 'Item removed from cart.',
            timestamp: new Date().toISOString(),
          };
          dispatch(addNotification(notification));

          toast.info('Item removed from cart.');
        } catch (error) {
          logger.error(`Failed to remove cart item ID ${item.id} from local storage:`, error);
          toast.error('Failed to remove item from cart. Please try again.');
        }
      }
    },
    [token, user?.id, dispatch]
  );

  /**
   * Moves an item to saved-for-later.
   * For authenticated users, updates the item via PUT request.
   * For unauthenticated users, moves the item in local storage.
   * @param {Object} item - The item to move.
   * @param {number} item.id - The ID of the cart item (for authenticated users).
   * @param {number} [item.index] - The index of the cart item (for unauthenticated users).
   */
  const moveToSaved = useCallback(
    async (item) => {
      if (token) {
        try {
          const response = await api.put(
            `/api/cart/${item.id}`,
            { isSavedForLater: true },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setCartItems((prevCart) => prevCart.filter((ci) => ci.id !== item.id));
          setSavedItems((prevSaved) => [...prevSaved, response.data.data]);
          logger.info(`Moved cart item ID ${item.id} to saved for later for user ID ${user?.id || 'unknown'}`);

          // Dispatch notification to Redux store
          const notification = {
            id: uuidv4(),
            message: 'Item moved to saved for later.',
            timestamp: new Date().toISOString(),
          };
          dispatch(addNotification(notification));

          toast.info('Item moved to saved for later.');
        } catch (error) {
          logger.error(`Failed to move cart item ID ${item.id} to saved for later:`, error);
          toast.error('Failed to move item to saved for later. Please try again.');
        }
      } else {
        // Handle unauthenticated users by moving items in local storage
        try {
          const localCart = JSON.parse(localStorage.getItem('cart')) || [];
          const localSaved = JSON.parse(localStorage.getItem('savedItems')) || [];

          const itemToSave = localCart.find((ci) => ci.id === item.id);
          if (!itemToSave) {
            toast.error('Item not found in cart.');
            return;
          }

          // Remove from cart and add to saved
          const updatedCart = localCart.filter((ci) => ci.id !== item.id);
          const updatedSaved = [...localSaved, { ...itemToSave, isSavedForLater: true }];

          localStorage.setItem('cart', JSON.stringify(updatedCart));
          localStorage.setItem('savedItems', JSON.stringify(updatedSaved));
          setCartItems(updatedCart);
          setSavedItems(updatedSaved);
          logger.info(`Moved cart item ID ${item.id} to saved for later in local storage`);

          // Dispatch notification to Redux store
          const notification = {
            id: uuidv4(),
            message: 'Item moved to saved for later.',
            timestamp: new Date().toISOString(),
          };
          dispatch(addNotification(notification));

          toast.info('Item moved to saved for later.');
        } catch (error) {
          logger.error(`Failed to move cart item ID ${item.id} to saved for later in local storage:`, error);
          toast.error('Failed to move item to saved for later. Please try again.');
        }
      }
    },
    [token, user?.id, dispatch]
  );

  /**
   * Moves an item from saved-for-later back to the cart.
   * For authenticated users, updates the item via PUT request.
   * For unauthenticated users, moves the item in local storage.
   * @param {Object} item - The item to move.
   * @param {number} item.id - The ID of the saved cart item (for authenticated users).
   * @param {number} [item.index] - The index of the saved cart item (for unauthenticated users).
   */
  const moveToCart = useCallback(
    async (item) => {
      if (token) {
        try {
          const response = await api.put(
            `/api/cart/${item.id}`,
            { isSavedForLater: false },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setSavedItems((prevSaved) => prevSaved.filter((savedItem) => savedItem.id !== item.id));
          setCartItems((prevCart) => [...prevCart, response.data.data]);
          logger.info(`Moved saved cart item ID ${item.id} back to cart for user ID ${user?.id || 'unknown'}`);

          // Dispatch notification to Redux store
          const notification = {
            id: uuidv4(),
            message: 'Item moved back to cart.',
            timestamp: new Date().toISOString(),
          };
          dispatch(addNotification(notification));

          toast.info('Item moved back to cart.');
        } catch (error) {
          logger.error(`Failed to move saved cart item ID ${item.id} back to cart:`, error);
          toast.error('Failed to move item back to cart. Please try again.');
        }
      } else {
        // Handle unauthenticated users by moving items in local storage
        try {
          const localCart = JSON.parse(localStorage.getItem('cart')) || [];
          const localSaved = JSON.parse(localStorage.getItem('savedItems')) || [];

          const itemToMove = localSaved.find((ci) => ci.id === item.id);
          if (!itemToMove) {
            toast.error('Item not found in saved items.');
            return;
          }

          // Remove from saved and add to cart
          const updatedSaved = localSaved.filter((ci) => ci.id !== item.id);
          const updatedCart = [...localCart, { ...itemToMove, isSavedForLater: false }];

          localStorage.setItem('cart', JSON.stringify(updatedCart));
          localStorage.setItem('savedItems', JSON.stringify(updatedSaved));
          setCartItems(updatedCart);
          setSavedItems(updatedSaved);
          logger.info(`Moved saved cart item ID ${item.id} back to cart in local storage`);

          // Dispatch notification to Redux store
          const notification = {
            id: uuidv4(),
            message: 'Item moved back to cart.',
            timestamp: new Date().toISOString(),
          };
          dispatch(addNotification(notification));

          toast.info('Item moved back to cart.');
        } catch (error) {
          logger.error(`Failed to move saved cart item ID ${item.id} back to cart in local storage:`, error);
          toast.error('Failed to move item back to cart. Please try again.');
        }
      }
    },
    [token, user?.id, dispatch]
  );

  /**
   * Clears all items from the cart.
   * For authenticated users, sends a DELETE request to the backend.
   * For unauthenticated users, clears local storage.
   */
  const clearCart = useCallback(async () => {
    if (token) {
      try {
        await api.delete(`/api/cart`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCartItems([]);
        logger.info(`Cleared cart for user ID ${user?.id || 'unknown'}`);

        // Dispatch notification to Redux store
        const notification = {
          id: uuidv4(),
          message: 'Cart cleared.',
          timestamp: new Date().toISOString(),
        };
        dispatch(addNotification(notification));

        toast.info('Cart cleared.');
      } catch (error) {
        logger.error('Failed to clear cart:', error);
        toast.error('Failed to clear cart. Please try again.');
      }
    } else {
      // Handle unauthenticated users by clearing local storage
      try {
        localStorage.removeItem('cart');
        setCartItems([]);
        logger.info('Cleared local cart for unauthenticated user');

        // Dispatch notification to Redux store
        const notification = {
          id: uuidv4(),
          message: 'Cart cleared.',
          timestamp: new Date().toISOString(),
        };
        dispatch(addNotification(notification));

        toast.info('Cart cleared.');
      } catch (error) {
        logger.error('Failed to clear local cart:', error);
        toast.error('Failed to clear cart. Please try again.');
      }
    }
  }, [token, user?.id, dispatch]);

  /**
   * Fetches cart items from the backend for authenticated users.
   * For unauthenticated users, fetches from local storage.
   */
  const fetchCartItems = useCallback(async () => {
    if (token) {
      try {
        const response = await api.get(`/api/cart`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCartItems(response.data.data);
        logger.info('Fetched cart items from backend');
      } catch (error) {
        logger.error('Failed to fetch cart items:', error);
        toast.error('Failed to fetch cart items. Please try again.');
      }
    } else {
      // Handle unauthenticated users by fetching from local storage
      const localCart = JSON.parse(localStorage.getItem('cart')) || [];
      setCartItems(localCart);
      logger.info('Fetched cart items from local storage for unauthenticated user');
    }
  }, [token]);

  /**
   * Fetches saved-for-later items from the backend for authenticated users.
   * For unauthenticated users, fetches from local storage.
   */
  const fetchSavedItems = useCallback(async () => {
    if (token) {
      try {
        const response = await api.get(`/api/cart/saved`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSavedItems(response.data.data);
        logger.info('Fetched saved items from backend');
      } catch (error) {
        logger.error('Failed to fetch saved items:', error);
        toast.error('Failed to fetch saved items. Please try again.');
      }
    } else {
      // Handle unauthenticated users by fetching from local storage
      const localSaved = JSON.parse(localStorage.getItem('savedItems')) || [];
      setSavedItems(localSaved);
      logger.info('Fetched saved items from local storage for unauthenticated user');
    }
  }, [token]);

  /**
   * Sets the order type and persists it.
   * For authenticated users, you might want to send it to the backend.
   * For simplicity, we'll handle it in the frontend context.
   * @param {string} type - 'pickup' or 'delivery'
   */
  const updateOrderType = useCallback(
    async (type) => {
      if (!['pickup', 'delivery'].includes(type)) {
        toast.error('Invalid order type selected.');
        return;
      }

      setOrderType(type);
      logger.info(`Order type set to: ${type}`);

      // Optionally, notify the backend about the order type
      // This depends on your backend implementation
      // For now, we'll handle it during checkout

      // Dispatch notification to Redux store
      const notification = {
        id: uuidv4(),
        message: `Order type set to ${type}.`,
        timestamp: new Date().toISOString(),
      };
      dispatch(addNotification(notification));

      toast.success(`Order type set to ${type}.`);
    },
    [dispatch]
  );

  /**
   * Fetches both cart and saved items when the user or token changes.
   */
  useEffect(() => {
    if (token && user) {
      fetchCartItems();
      fetchSavedItems();
    } else {
      // For unauthenticated users, also fetch saved items
      fetchCartItems();
      fetchSavedItems();
    }
  }, [token, user, fetchCartItems, fetchSavedItems]);

  /**
   * **Polling Mechanism to Fetch Cart Items Periodically**
   * Optional: You can set up polling if cart data needs to be refreshed regularly.
   */
  useEffect(() => {
    // Define the polling function
    const pollCart = async () => {
      if (token) {
        try {
          console.log('Polling: Fetching latest cart items...');
          await fetchCartItems();
          console.log('Polling: Cart items updated.');
        } catch (error) {
          console.error('Polling: Failed to fetch cart items.', error);
          // Optionally, handle specific errors or retry logic here
        }
      }
    };

    // Set up the interval to poll every minute (60000 ms)
    const intervalId = setInterval(pollCart, 60000); // 1 minute

    // Optionally, perform an immediate poll when the component mounts
    pollCart();

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [token, fetchCartItems]);

  // ---------------------------
  // ======= Context Value ======
  // ---------------------------

  return (
    <CartContext.Provider
      value={{
        cartItems,
        savedItems,
        orderType,
        setOrderType: updateOrderType,
        fetchCart: fetchCartItems,
        fetchSavedItems,
        addItemToCart,
        removeItemFromCart,
        increaseItemQuantity,
        decreaseItemQuantity,
        increaseIngredientQuantity,
        decreaseIngredientQuantity,
        moveToSaved,
        moveToCart,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

// Define PropTypes for type checking
CartProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CartContext;
