// frontend/src/components/AddProductForm.js 

import React, { useState, useRef, useEffect } from 'react';
import { useStore } from './contexts/StoreContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaArrowDown, FaTimes } from 'react-icons/fa';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import logger from '../utils/logger';
import CreateIngredientModal from './CreateIngredientModal'; // Import the modal

const animatedComponents = makeAnimated();

const AddProductForm = ({ onClose, onProductAdded }) => {
  const {
    categories = [],
    ingredients = [],
    stores = [],
    optionGroups = [],
    createCategory,
    createIngredient,
    createProduct,
    createOptionGroup,
    createOptionChoice,
    fetchStores,
    loading,
    error,
    setError,
  } = useStore();

  // Form State
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [flavor, setFlavor] = useState('');
  const [category, setCategory] = useState(null);
  const [image, setImage] = useState(null);
  
  // **Updated Store State to Support Multiple Selections**
  const [selectedStores, setSelectedStores] = useState([]);

  // SEO Fields
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [metaKeywords, setMetaKeywords] = useState('');

  // Active Status
  const [isActive, setIsActive] = useState(true);

  // Stock Options (optional)
  const [stockOptions, setStockOptions] = useState([
    {
      size: '',
      selectedOptionGroups: [],
      selectedOptionChoices: [],
      additionalAddOns: [],
      color: '',
      stock: 0
    },
  ]);

  // Ingredients
  const [productIngredients, setProductIngredients] = useState([
    { ingredient: null, quantity: '', unit: '' },
  ]);

  // Recipe
  const [recipe, setRecipe] = useState({
    instructions: '',
    prepTime: '',
    cookTime: '',
    servings: '',
  });

  const contentRef = useRef(null);
  const prevScrollTop = useRef(0);
  const [showArrow, setShowArrow] = useState(true);

  // Ingredient Modal
  const [isIngredientModalOpen, setIsIngredientModalOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = contentRef.current.scrollTop;
      if (currentScrollTop > prevScrollTop.current) {
        setShowArrow(false);
      } else {
        setShowArrow(true);
      }
      prevScrollTop.current = currentScrollTop;
    };

    const contentElement = contentRef.current;
    if (contentElement) {
      contentElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (contentElement) {
        contentElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  // Scroll to bottom
  const scrollToBottom = () => {
    if (contentRef.current) {
      contentRef.current.scrollTo({
        top: contentRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  // Stock Options Handlers
  const handleAddStockOption = () => {
    setStockOptions([
      ...stockOptions,
      { size: '', selectedOptionGroups: [], selectedOptionChoices: [], additionalAddOns: [], color: '', stock: 0 },
    ]);
  };

  const handleRemoveStockOption = (index) => {
    setStockOptions(stockOptions.filter((_, i) => i !== index));
  };

  const handleStockOptionChange = (index, key, value) => {
    const newStockOptions = [...stockOptions];
    if (key === 'stock') {
      // Ensure stock is a non-negative integer
      const stockValue = parseInt(value, 10);
      newStockOptions[index][key] = isNaN(stockValue) ? 0 : stockValue;
    } else {
      newStockOptions[index][key] = value;
    }
    setStockOptions(newStockOptions);
  };

  // Handle creating new option groups on the fly
  const handleCreateOptionGroupOnTheFly = async (inputValue) => {
    try {
      const createdGroup = await createOptionGroup(inputValue);
      return { label: createdGroup.name, value: createdGroup.id, optionChoices: [] };
    } catch (error) {
      toast.error(`Failed to create option group: ${error.message}`);
      return null;
    }
  };

  // Handle creating new option choices on the fly
  const handleCreateOptionChoiceOnTheFly = async (inputValue, groupId) => {
    try {
      // Call createOptionChoice with separate arguments: name, additionalPrice, optionGroupId
      const createdChoice = await createOptionChoice(inputValue, 0, groupId);

      return {
        label: `${createdChoice.name} (+£${(createdChoice.additionalPrice || 0).toFixed(2)})`,
        value: createdChoice.id,
        groupId,
        additionalPrice: createdChoice.additionalPrice || 0
      };
    } catch (error) {
      toast.error(`Failed to create option choice: ${error.message}`);
      return null;
    }
  };

  const handleOptionGroupChange = (stockIndex, selectedOptionGroups) => {
    const newStockOptions = [...stockOptions];
    newStockOptions[stockIndex].selectedOptionGroups = selectedOptionGroups || [];
    // Reset choices if groups change
    newStockOptions[stockIndex].selectedOptionChoices = [];
    setStockOptions(newStockOptions);
  };

  const handleOptionChoiceChange = (stockIndex, selectedOptionChoices) => {
    const newStockOptions = [...stockOptions];
    newStockOptions[stockIndex].selectedOptionChoices = selectedOptionChoices || [];
    setStockOptions(newStockOptions);
  };

  const handleCreateOptionGroup = async (inputValue, stockIndex) => {
    const created = await handleCreateOptionGroupOnTheFly(inputValue);
    if (created) {
      const newStockOptions = [...stockOptions];
      newStockOptions[stockIndex].selectedOptionGroups = [...(newStockOptions[stockIndex].selectedOptionGroups || []), created];
      setStockOptions(newStockOptions);
    }
  };

  const handleCreateOptionChoice = async (inputValue, stockIndex) => {
    const groups = stockOptions[stockIndex].selectedOptionGroups;
    if (!groups || groups.length === 0) {
      toast.error('Please select or create an option group first before creating a choice.');
      return;
    }
    // Assume first selected group for now
    const groupId = groups[0].value;
    const createdChoice = await handleCreateOptionChoiceOnTheFly(inputValue, groupId);
    if (createdChoice) {
      const newStockOptions = [...stockOptions];
      newStockOptions[stockIndex].selectedOptionChoices = [...newStockOptions[stockIndex].selectedOptionChoices, createdChoice];
      setStockOptions(newStockOptions);
    }
  };

  const handleAddAdditionalAddOn = (stockIndex) => {
    const newStockOptions = [...stockOptions];
    newStockOptions[stockIndex].additionalAddOns.push({
      name: '',
      price: '',
    });
    setStockOptions(newStockOptions);
  };

  const handleRemoveAdditionalAddOn = (stockIndex, addOnIndex) => {
    const newStockOptions = [...stockOptions];
    newStockOptions[stockIndex].additionalAddOns =
      newStockOptions[stockIndex].additionalAddOns.filter((_, i) => i !== addOnIndex);
    setStockOptions(newStockOptions);
  };

  const handleAdditionalAddOnChange = (stockIndex, addOnIndex, key, value) => {
    const newStockOptions = [...stockOptions];
    newStockOptions[stockIndex].additionalAddOns[addOnIndex][key] = value;
    setStockOptions(newStockOptions);
  };

  // Ingredients
  const handleAddIngredient = () => {
    setProductIngredients([
      ...productIngredients,
      { ingredient: null, quantity: '', unit: '' },
    ]);
  };

  const handleRemoveIngredient = (index) => {
    setProductIngredients(productIngredients.filter((_, i) => i !== index));
  };

  const handleIngredientChange = (index, key, value) => {
    const newIngredients = [...productIngredients];
    newIngredients[index][key] = value;
    setProductIngredients(newIngredients);
  };

  // Recipe
  const handleRecipeChange = (e) => {
    const { name, value } = e.target;
    setRecipe((prev) => ({ ...prev, [name]: value }));
  };

  // Image
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  // Fetch stores on mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchStores();
      } catch (err) {
        logger.error('Error fetching stores in AddProductForm:', err);
        toast.error('Failed to fetch stores.');
      }
    };
    fetchData();
  }, [fetchStores]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic required fields checks
    if (!name || !price || !category || !image || selectedStores.length === 0 || !flavor || !metaTitle || !metaDescription || !metaKeywords) {
      toast.error('Please fill out all required fields.');
      return;
    }

    if (parseFloat(price) < 0) {
      toast.error('Price must be a positive number.');
      return;
    }

    // Ingredients must be filled out
    for (let ing of productIngredients) {
      if (!ing.ingredient || !ing.quantity || !ing.unit) {
        toast.error('Please fill out all ingredient fields.');
        return;
      }
    }

    // Recipe fields required
    if (!recipe.instructions || !recipe.prepTime || !recipe.cookTime || !recipe.servings) {
      toast.error('Please fill out all recipe fields.');
      return;
    }

    // Validate stockOptions
    for (let opt of stockOptions) {
      if (opt.color.trim() === '') {
        toast.error('Each stock option must include a color.');
        return;
      }
      if (opt.stock < 0 || isNaN(opt.stock)) {
        toast.error('Each stock option stock must be a non-negative integer.');
        return;
      }
    }

    // Prepare stockOptions data with selected groups and choices
    const preparedStockOptions = stockOptions.map((opt) => ({
      size: opt.size,
      optionGroups: (opt.selectedOptionGroups || []).map((g) => ({ groupId: g.value })),
      optionChoices: (opt.selectedOptionChoices || []).map((c) => ({ optionChoiceId: c.value })), // Updated key
      additionalAddOns: opt.additionalAddOns,
      color: opt.color.trim(),
      stock: opt.stock >= 0 ? opt.stock : 0,
    }));

    // **Prepare sizeOptions by extracting unique sizes from stockOptions**
    const sizeOptions = [
      ...new Set(
        preparedStockOptions
          .map((opt) => opt.size)
          .filter((size) => size && size.trim() !== '')
      ),
    ];

    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('price', price);
    formData.append('flavor', flavor);
    formData.append('categoryId', category.value);
    
    // **Handle Multiple Stores**
    const storeIds = selectedStores
      .filter(store => store.value !== 'all') // Exclude 'All Stores' value
      .map(store => store.value);
    
    // If 'All Stores' is selected, send all store IDs
    if (selectedStores.some(store => store.value === 'all')) {
      const allStoreIds = stores.map(store => store.id);
      formData.append('storeIds', JSON.stringify(allStoreIds));
    } else {
      formData.append('storeIds', JSON.stringify(storeIds));
    }
    
    formData.append('image', image);
    formData.append('isActive', isActive);
    formData.append('metaTitle', metaTitle);
    formData.append('metaDescription', metaDescription);
    formData.append('metaKeywords', metaKeywords);

    // **Append sizeOptions to formData**
    formData.append('sizeOptions', JSON.stringify(sizeOptions));

    // Stock options optional
    formData.append('stockOptions', JSON.stringify(preparedStockOptions));

    // Ingredients
    productIngredients.forEach((ing, index) => {
      formData.append(`ingredients[${index}][ingredientId]`, ing.ingredient.value);
      formData.append(`ingredients[${index}][quantity]`, ing.quantity);
      formData.append(`ingredients[${index}][unit]`, ing.unit);
    });

    // Recipe
    formData.append('recipe', JSON.stringify(recipe));

    try {
      const newProduct = await createProduct(formData);
      if (newProduct) {
        toast.success('Product added successfully!');
        onProductAdded(newProduct);
        onClose();
      }
    } catch (err) {
      logger.error('Error adding product:', err);
      toast.error('Failed to add product.');
    }
  };

  const handleCreateCategory = async (inputValue) => {
    try {
      const created = await createCategory(inputValue);
      return {
        label: created.name,
        value: created.id,
      };
    } catch (err) {
      toast.error('Failed to create category.');
      return null;
    }
  };

  const handleCreateIngredient = async (data) => {
    try {
      const created = await createIngredient(data);
      if (created) {
        toast.success('Ingredient created successfully!');
      }
    } catch (err) {
      logger.error('Error creating ingredient:', err);
    }
  };

  const categoryOptions = Array.isArray(categories)
    ? categories.map((cat) => ({
        label: cat.name,
        value: cat.id,
      }))
    : [];

  const ingredientOptions = Array.isArray(ingredients)
    ? ingredients.map((ing) => ({
        label: ing.name,
        value: ing.id,
      }))
    : [];

  // **Add "All Stores" Option**
  const allStoresOption = { label: 'All Stores', value: 'all' };

  const storeOptions = Array.isArray(stores)
    ? [allStoresOption, ...stores.map((store) => ({
        label: store.name,
        value: store.id,
      }))]
    : [];

  const optionGroupOptions = Array.isArray(optionGroups)
    ? optionGroups.map((group) => ({
        label: group.name,
        value: group.id,
        optionChoices: group.optionChoices || [],
      }))
    : [];

  // **Handle Store Selection with "All Stores"**
  const handleStoreChange = (selectedOptions) => {
    if (selectedOptions) {
      const isAllSelected = selectedOptions.some(option => option.value === 'all');
      if (isAllSelected) {
        // If "All Stores" is selected, select all stores
        setSelectedStores(storeOptions);
      } else {
        // If any store is deselected, remove "All Stores" if it was selected
        setSelectedStores(selectedOptions);
      }
    } else {
      setSelectedStores([]);
    }
  };

  // **Determine if "All Stores" should be selected**
  const isAllSelected = selectedStores.length === storeOptions.length;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div
        className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-4xl mx-4 max-h-[90vh] overflow-y-auto relative"
        ref={contentRef}
      >
        {/* Close Button */}
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="text-gray-300 hover:text-white text-2xl font-bold focus:outline-none"
            aria-label="Close"
          >
            &times;
          </button>
        </div>

        <h2 className="text-2xl font-bold mb-6 text-white">Add New Product</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Product Name */}
          <div>
            <label className="block text-white font-semibold">
              Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2 mt-1 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          {/* Description */}
          <div>
            <label className="block text-white font-semibold">Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full p-2 mt-1 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="4"
            />
          </div>

          {/* Base Price */}
          <div>
            <label className="block text-white font-semibold">
              Base Price (£) <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              step="0.01"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="w-full p-2 mt-1 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          {/* Flavor */}
          <div>
            <label className="block text-white font-semibold">
              Flavor <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={flavor}
              onChange={(e) => setFlavor(e.target.value)}
              className="w-full p-2 mt-1 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              placeholder="e.g., Chocolate, Vanilla"
            />
          </div>

          {/* Category */}
          <div>
            <label className="block text-white font-semibold">
              Category <span className="text-red-500">*</span>
            </label>
            <CreatableSelect
              isClearable
              onChange={(newValue) => setCategory(newValue)}
              onCreateOption={async (inputValue) => {
                const created = await handleCreateCategory(inputValue);
                if (created) {
                  setCategory(created);
                }
              }}
              options={categoryOptions}
              value={category}
              components={animatedComponents}
              className="mt-1"
              styles={{
                control: (base) => ({
                  ...base,
                  backgroundColor: '#4A5568',
                  color: '#FFFFFF',
                }),
                menu: (base) => ({
                  ...base,
                  backgroundColor: '#4A5568',
                }),
                singleValue: (base) => ({
                  ...base,
                  color: '#FFFFFF',
                }),
                multiValue: (base) => ({
                  ...base,
                  backgroundColor: '#2D3748',
                }),
                input: (base) => ({
                  ...base,
                  color: '#FFFFFF',
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isFocused ? '#2D3748' : '#4A5568',
                  color: '#FFFFFF',
                }),
              }}
              placeholder="Select or create a category"
              aria-label="Category Select"
              required
            />
          </div>

          {/* Store */}
          <div>
            <label className="block text-white font-semibold">
              Store <span className="text-red-500">*</span>
            </label>
            {loading && stores.length === 0 ? (
              <p className="text-white">Loading stores...</p>
            ) : (
              <Select
                isClearable
                isMulti
                onChange={handleStoreChange}
                options={storeOptions}
                value={selectedStores}
                components={animatedComponents}
                className="mt-1"
                styles={{
                  control: (base) => ({
                    ...base,
                    backgroundColor: '#4A5568',
                    color: '#FFFFFF',
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor: '#4A5568',
                  }),
                  multiValue: (base) => ({
                    ...base,
                    backgroundColor: '#2D3748',
                  }),
                  multiValueLabel: (base) => ({
                    ...base,
                    color: '#FFFFFF',
                  }),
                  multiValueRemove: (base) => ({
                    ...base,
                    color: '#FFFFFF',
                    ':hover': {
                      backgroundColor: '#E53E3E',
                      color: 'white',
                    },
                  }),
                  input: (base) => ({
                    ...base,
                    color: '#FFFFFF',
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused ? '#2D3748' : '#4A5568',
                    color: '#FFFFFF',
                  }),
                }}
                placeholder="Select stores"
                aria-label="Store Select"
                required
                isDisabled={stores.length === 0}
              />
            )}
          </div>

          {/* Image */}
          <div>
            <label className="block text-white font-semibold">
              Image <span className="text-red-500">*</span>
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="w-full p-2 mt-1 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              aria-label="Product Image Upload"
            />
            {image && (
              <img
                src={URL.createObjectURL(image)}
                alt="New Product"
                className="mt-4 w-32 h-32 object-cover rounded"
              />
            )}
          </div>

          {/* Stock Options (Optional) */}
          <div>
            <label className="block text-white font-semibold">
              Stock Options (Optional)
            </label>
            {stockOptions.map((option, stockIndex) => (
              <div key={stockIndex} className="border border-gray-600 p-4 rounded-lg mt-4">
                {/* Size (Optional now) */}
                <div>
                  <label className="block text-white font-semibold">Size (Optional)</label>
                  <input
                    type="text"
                    placeholder="Size"
                    value={option.size}
                    onChange={(e) =>
                      handleStockOptionChange(stockIndex, 'size', e.target.value)
                    }
                    className="w-full p-2 mt-1 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    aria-label={`Size Option ${stockIndex + 1}`}
                  />
                </div>

                {/* Color */}
                <div className="mt-4">
                  <label className="block text-white font-semibold">Color <span className="text-red-500">*</span></label>
                  <input
                    type="text"
                    placeholder="Color"
                    value={option.color}
                    onChange={(e) =>
                      handleStockOptionChange(stockIndex, 'color', e.target.value)
                    }
                    className="w-full p-2 mt-1 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    aria-label={`Color Option ${stockIndex + 1}`}
                    required
                  />
                </div>

                {/* Stock */}
                <div className="mt-4">
                  <label className="block text-white font-semibold">Stock <span className="text-red-500">*</span></label>
                  <input
                    type="number"
                    min="0"
                    placeholder="Stock Quantity"
                    value={option.stock}
                    onChange={(e) =>
                      handleStockOptionChange(stockIndex, 'stock', e.target.value)
                    }
                    className="w-full p-2 mt-1 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    aria-label={`Stock Quantity Option ${stockIndex + 1}`}
                    required
                  />
                </div>

                {/* Option Groups (creatable) */}
                <div className="mt-4">
                  <label className="block text-white font-semibold">
                    Option Groups (Optional)
                  </label>
                  <CreatableSelect
                    isMulti
                    onChange={(selected) => handleOptionGroupChange(stockIndex, selected)}
                    onCreateOption={async (inputValue) => {
                      await handleCreateOptionGroup(inputValue, stockIndex);
                    }}
                    options={optionGroupOptions}
                    value={option.selectedOptionGroups}
                    components={animatedComponents}
                    className="mt-1"
                    styles={{
                      control: (base) => ({
                        ...base,
                        backgroundColor: '#4A5568',
                        color: '#FFFFFF',
                      }),
                      menu: (base) => ({
                        ...base,
                        backgroundColor: '#4A5568',
                      }),
                      multiValue: (base) => ({
                        ...base,
                        backgroundColor: '#2D3748',
                      }),
                      multiValueLabel: (base) => ({
                        ...base,
                        color: '#FFFFFF',
                      }),
                      multiValueRemove: (base) => ({
                        ...base,
                        color: '#FFFFFF',
                        ':hover': {
                          backgroundColor: '#E53E3E',
                          color: 'white',
                        },
                      }),
                      input: (base) => ({
                        ...base,
                        color: '#FFFFFF',
                      }),
                      option: (base, state) => ({
                        ...base,
                        backgroundColor: state.isFocused ? '#2D3748' : '#4A5568',
                        color: '#FFFFFF',
                      }),
                    }}
                    placeholder="Select or create option groups"
                    aria-label={`Option Groups ${stockIndex + 1}`}
                  />
                </div>

                {/* Option Choices (creatable) */}
                <div className="mt-4">
                  <label className="block text-white font-semibold">
                    Option Choices (Optional)
                  </label>
                  <CreatableSelect
                    isMulti
                    onChange={(selected) => handleOptionChoiceChange(stockIndex, selected)}
                    onCreateOption={async (inputValue) => {
                      await handleCreateOptionChoice(inputValue, stockIndex);
                    }}
                    options={
                      (option.selectedOptionGroups || []).flatMap((g) => {
                        const groupObj = optionGroupOptions.find((grp) => grp.value === g.value);
                        return groupObj && Array.isArray(groupObj.optionChoices)
                          ? groupObj.optionChoices.map((choice) => {
                              const price = Number(choice.additionalPrice) || 0;
                              return {
                                label: `${choice.name} (+£${price.toFixed(2)})`,
                                value: choice.id,
                                groupId: g.value,
                                additionalPrice: price,
                              };
                            })
                          : [];
                      })
                    }
                    value={option.selectedOptionChoices}
                    components={animatedComponents}
                    className="mt-1"
                    styles={{
                      control: (base) => ({
                        ...base,
                        backgroundColor: '#4A5568',
                        color: '#FFFFFF',
                      }),
                      menu: (base) => ({
                        ...base,
                        backgroundColor: '#4A5568',
                      }),
                      multiValue: (base) => ({
                        ...base,
                        backgroundColor: '#2D3748',
                      }),
                      multiValueLabel: (base) => ({
                        ...base,
                        color: '#FFFFFF',
                      }),
                      multiValueRemove: (base) => ({
                        ...base,
                        color: '#FFFFFF',
                        ':hover': {
                          backgroundColor: '#E53E3E',
                          color: 'white',
                        },
                      }),
                      input: (base) => ({
                        ...base,
                        color: '#FFFFFF',
                      }),
                      option: (base, state) => ({
                        ...base,
                        backgroundColor: state.isFocused ? '#2D3748' : '#4A5568',
                        color: '#FFFFFF',
                      }),
                    }}
                    placeholder="Select or create option choices"
                    aria-label={`Option Choices ${stockIndex + 1}`}
                  />
                </div>

                {/* Additional Add-Ons (Optional) */}
                <div className="mt-4">
                  <label className="block text-white font-semibold">
                    Additional Add-Ons (Optional)
                  </label>
                  {option.additionalAddOns.map((addOn, addOnIndex) => (
                    <div
                      key={addOnIndex}
                      className="flex items-center space-x-2 mt-2"
                    >
                      <input
                        type="text"
                        placeholder="Add-On Name"
                        value={addOn.name}
                        onChange={(e) =>
                          handleAdditionalAddOnChange(
                            stockIndex,
                            addOnIndex,
                            'name',
                            e.target.value
                          )
                        }
                        className="w-1/2 p-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        aria-label={`Add-On Name ${addOnIndex + 1} for Stock Option ${stockIndex + 1}`}
                      />
                      <input
                        type="number"
                        step="0.01"
                        placeholder="Price (£)"
                        value={addOn.price}
                        onChange={(e) =>
                          handleAdditionalAddOnChange(
                            stockIndex,
                            addOnIndex,
                            'price',
                            e.target.value
                          )
                        }
                        className="w-1/2 p-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        aria-label={`Add-On Price ${addOnIndex + 1} for Stock Option ${stockIndex + 1}`}
                      />
                      <button
                        type="button"
                        onClick={() =>
                          handleRemoveAdditionalAddOn(stockIndex, addOnIndex)
                        }
                        className="bg-red-500 hover:bg-red-700 text-white p-2 rounded focus:outline-none focus:ring-2 focus:ring-red-400"
                        aria-label={`Remove Add-On ${addOnIndex + 1} for Stock Option ${stockIndex + 1}`}
                      >
                        <FaTimes />
                      </button>
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={() => handleAddAdditionalAddOn(stockIndex)}
                    className="mt-2 bg-green-500 hover:bg-green-700 text-white p-2 rounded focus:outline-none focus:ring-2 focus:ring-green-400"
                    aria-label={`Add Add-On for Stock Option ${stockIndex + 1}`}
                  >
                    Add Add-On
                  </button>
                </div>

                {/* Remove Stock Option Button */}
                {stockOptions.length > 1 && (
                  <button
                    type="button"
                    onClick={() => handleRemoveStockOption(stockIndex)}
                    className="mt-4 bg-red-500 hover:bg-red-700 text-white p-2 rounded focus:outline-none focus:ring-2 focus:ring-red-400"
                    aria-label={`Remove Stock Option ${stockIndex + 1}`}
                  >
                    <FaTimes /> Remove Stock Option
                  </button>
                )}
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddStockOption}
              className="mt-4 bg-green-500 hover:bg-green-700 text-white p-2 rounded focus:outline-none focus:ring-2 focus:ring-green-400"
              aria-label="Add Stock Option"
            >
              Add Stock Option
            </button>
          </div>

          {/* Ingredients */}
          <div>
            <label className="block text-white font-semibold">
              Ingredients <span className="text-red-500">*</span>
            </label>
            {productIngredients.map((ing, index) => (
              <div key={index} className="flex items-center space-x-2 mt-2">
                <CreatableSelect
                  isClearable
                  onChange={(newValue) =>
                    handleIngredientChange(index, 'ingredient', newValue)
                  }
                  onCreateOption={() => {
                    // Open the Create Ingredient Modal
                    setIsIngredientModalOpen(true);
                  }}
                  options={ingredientOptions}
                  value={ing.ingredient}
                  components={animatedComponents}
                  className="flex-1"
                  styles={{
                    control: (base) => ({
                      ...base,
                      backgroundColor: '#4A5568',
                      color: '#FFFFFF',
                    }),
                    menu: (base) => ({
                      ...base,
                      backgroundColor: '#4A5568',
                    }),
                    singleValue: (base) => ({
                      ...base,
                      color: '#FFFFFF',
                    }),
                    multiValue: (base) => ({
                      ...base,
                      backgroundColor: '#2D3748',
                    }),
                    input: (base) => ({
                      ...base,
                      color: '#FFFFFF',
                    }),
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: state.isFocused ? '#2D3748' : '#4A5568',
                      color: '#FFFFFF',
                    }),
                  }}
                  placeholder="Select or create an ingredient"
                  aria-label={`Ingredient ${index + 1}`}
                  required
                />
                <input
                  type="number"
                  placeholder="Quantity"
                  value={ing.quantity}
                  onChange={(e) =>
                    handleIngredientChange(index, 'quantity', e.target.value)
                  }
                  className="w-1/4 p-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  aria-label={`Ingredient Quantity ${index + 1}`}
                  required
                />
                <input
                  type="text"
                  placeholder="Unit"
                  value={ing.unit}
                  onChange={(e) =>
                    handleIngredientChange(index, 'unit', e.target.value)
                  }
                  className="w-1/4 p-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  aria-label={`Ingredient Unit ${index + 1}`}
                  required
                />
                {productIngredients.length > 1 && (
                  <button
                    type="button"
                    onClick={() => handleRemoveIngredient(index)}
                    className="bg-red-500 hover:bg-red-700 text-white p-2 rounded focus:outline-none focus:ring-2 focus:ring-red-400"
                    aria-label={`Remove Ingredient ${index + 1}`}
                  >
                    <FaTimes />
                  </button>
                )}
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddIngredient}
              className="mt-4 bg-green-500 hover:bg-green-700 text-white p-2 rounded focus:outline-none focus:ring-2 focus:ring-green-400"
              aria-label="Add Ingredient"
            >
              Add Ingredient
            </button>
          </div>

          {/* Recipe Details */}
          <div>
            <label className="block text-white font-semibold">
              Recipe Details <span className="text-red-500">*</span>
            </label>
            <div className="space-y-4 mt-2">
              <div>
                <label className="block text-white">Instructions</label>
                <textarea
                  name="instructions"
                  value={recipe.instructions}
                  onChange={handleRecipeChange}
                  className="w-full p-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows="4"
                  required
                />
              </div>
              <div>
                <label className="block text-white">
                  Preparation Time (minutes)
                </label>
                <input
                  type="number"
                  name="prepTime"
                  value={recipe.prepTime}
                  onChange={handleRecipeChange}
                  className="w-full p-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div>
                <label className="block text-white">Cooking Time (minutes)</label>
                <input
                  type="number"
                  name="cookTime"
                  value={recipe.cookTime}
                  onChange={handleRecipeChange}
                  className="w-full p-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div>
                <label className="block text-white">Servings</label>
                <input
                  type="number"
                  name="servings"
                  value={recipe.servings}
                  onChange={handleRecipeChange}
                  className="w-full p-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
            </div>
          </div>

          {/* SEO Fields */}
          <div>
            <label className="block text-white font-semibold">
              Meta Title <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={metaTitle}
              onChange={(e) => setMetaTitle(e.target.value)}
              className="w-full p-2 mt-1 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              placeholder="SEO Meta Title"
            />
          </div>

          <div>
            <label className="block text-white font-semibold">
              Meta Description <span className="text-red-500">*</span>
            </label>
            <textarea
              value={metaDescription}
              onChange={(e) => setMetaDescription(e.target.value)}
              className="w-full p-2 mt-1 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="3"
              required
              placeholder="SEO Meta Description"
            />
          </div>

          <div>
            <label className="block text-white font-semibold">
              Meta Keywords <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={metaKeywords}
              onChange={(e) => setMetaKeywords(e.target.value)}
              className="w-full p-2 mt-1 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              placeholder="SEO Meta Keywords (comma separated)"
            />
          </div>

          {/* Active Status */}
          <div className="flex items-center">
            <input
              type="checkbox"
              id="isActive"
              checked={isActive}
              onChange={(e) => setIsActive(e.target.checked)}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              aria-label="Active Status"
            />
            <label htmlFor="isActive" className="ml-2 block text-white">
              Active
            </label>
          </div>

          {/* Submit and Cancel Buttons */}
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-gray-400"
              aria-label="Cancel"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
              disabled={loading}
              aria-label="Add Product"
            >
              {loading ? 'Adding...' : 'Add Product'}
            </button>
          </div>

          {error && <p className="text-red-500 mt-4">{error}</p>}
        </form>

        {showArrow && (
          <button
            onClick={scrollToBottom}
            className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-blue-500 hover:bg-blue-700 text-white p-2 rounded-full shadow-lg transition-opacity opacity-75 hover:opacity-100 animate-bounce"
            aria-label="Scroll to bottom"
          >
            <FaArrowDown />
          </button>
        )}

        {/* Create Ingredient Modal */}
        <CreateIngredientModal
          isOpen={isIngredientModalOpen}
          onClose={() => setIsIngredientModalOpen(false)}
          onCreate={handleCreateIngredient}
        />
      </div>
    </div>
  );
};

export default AddProductForm;
