// src/components/Profile.js

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from './contexts/UserContext';
import { useCart } from './contexts/CartContext';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Avatar from 'react-avatar';
import { FiEye, FiEyeOff, FiLogOut, FiTrash2, FiMoon, FiSun } from 'react-icons/fi';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { avatars } from './avatarList'; // Import the avatar list
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import zxcvbn from 'zxcvbn';
import { useDropzone } from 'react-dropzone';

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// Loading Spinner Component
const LoadingSpinner = () => (
  <motion.div
    className="flex items-center justify-center min-h-screen bg-white dark:bg-gray-900"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
  >
    <AiOutlineLoading3Quarters className="animate-spin text-6xl text-gray-500 dark:text-gray-300" />
  </motion.div>
);

const ProfilePageContent = () => {
  const { user, logout, disconnectWeb3, updateUser, isWeb3User } = useUserContext();
  const { clearCart } = useCart();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  // State Management
  const [isBillingSameAsShipping, setIsBillingSameAsShipping] = useState(true);
  const [selectedAvatar, setSelectedAvatar] = useState(user?.avatar || avatars[0]);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [uploadedAvatar, setUploadedAvatar] = useState(null); // For profile picture upload
  const [passwordVisible, setPasswordVisible] = useState(false); // Toggle password visibility
  const [theme, setTheme] = useState('light'); // Light or Dark theme

  // Form Validation Schema using Yup
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    phoneNumber: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(10, 'Must be at least 10 digits')
      .required('Phone Number is required'),
    birthday: Yup.date().required('Birthday is required'),
    emailNotifications: Yup.boolean(),
    notificationPreferences: Yup.object().shape({
      email: Yup.boolean(),
      sms: Yup.boolean(),
      push: Yup.boolean(),
    }),
    shippingAddressLine1: Yup.string().required('Shipping Address Line 1 is required'),
    shippingAddressLine2: Yup.string(),
    shippingCity: Yup.string().required('Shipping City is required'),
    shippingCounty: Yup.string().required('Shipping County is required'),
    shippingPostcode: Yup.string().required('Shipping Postcode is required'),
    billingAddress: Yup.string().when('isBillingSameAsShipping', {
      is: false,
      then: Yup.string().required('Billing Address is required'),
    }),
    billingCity: Yup.string().when('isBillingSameAsShipping', {
      is: false,
      then: Yup.string().required('Billing City is required'),
    }),
    billingCounty: Yup.string().when('isBillingSameAsShipping', {
      is: false,
      then: Yup.string().required('Billing County is required'),
    }),
    billingPostcode: Yup.string().when('isBillingSameAsShipping', {
      is: false,
      then: Yup.string().required('Billing Postcode is required'),
    }),
    newPassword: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('New Password is required'),
    uploadedAvatar: Yup.mixed().nullable(),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      phoneNumber: user?.contactNumber || '',
      birthday: user?.birthday ? new Date(user.birthday).toISOString().split('T')[0] : '',
      emailNotifications: user?.emailNotifications || false,
      notificationPreferences: {
        email: user?.notificationPreferences?.email || false,
        sms: user?.notificationPreferences?.sms || false,
        push: user?.notificationPreferences?.push || false,
      },
      shippingAddressLine1: user?.shippingAddress?.split(',')[0] || '',
      shippingAddressLine2: user?.shippingAddress?.split(',')[1] || '',
      shippingCity: user?.shippingCity || '',
      shippingCounty: user?.shippingCounty || '',
      shippingPostcode: user?.shippingPostcode || '',
      billingAddress: user?.billingAddress || '',
      billingCity: user?.billingCity || '',
      billingCounty: user?.billingCounty || '',
      billingPostcode: user?.billingPostcode || '',
      newPassword: '',
      isBillingSameAsShipping: isBillingSameAsShipping,
      uploadedAvatar: null,
    },
  });

  // Extract watched values
  const newPassword = watch('newPassword');

  // Password Strength
  const passwordStrength = useMemo(() => zxcvbn(newPassword), [newPassword]);

  const passwordStrengthLabel = () => {
    switch (passwordStrength.score) {
      case 0:
        return 'Very Weak';
      case 1:
        return 'Weak';
      case 2:
        return 'Fair';
      case 3:
        return 'Good';
      case 4:
        return 'Strong';
      default:
        return '';
    }
  };

  // Fetch necessary data on Component Mount
  useEffect(() => {
    if (user) {
      // Removed fetchWishlist call
      setLoading(false);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // Handle Logout
  const handleLogout = async () => {
    if (isWeb3User) {
      await disconnectWeb3();
    } else {
      logout();
    }
    clearCart();
    navigate('/login');
  };

  // Handle Account Info Update
  const onSubmitAccountInfo = async (data) => {
    const fullShippingAddress = `${data.shippingAddressLine1},${data.shippingAddressLine2}`;

    const updateData = {
      firstName: data.firstName,
      lastName: data.lastName,
      emailNotifications: data.emailNotifications,
      notificationPreferences: data.notificationPreferences,
      contactNumber: data.phoneNumber,
      birthday: data.birthday,
      avatar: selectedAvatar,
      billingAddress: data.billingAddress,
      billingCity: data.billingCity,
      billingCounty: data.billingCounty,
      billingPostcode: data.billingPostcode,
      shippingAddress: fullShippingAddress,
      shippingCity: data.shippingCity,
      shippingCounty: data.shippingCounty,
      shippingPostcode: data.shippingPostcode,
    };

    if (data.isBillingSameAsShipping) {
      updateData.billingAddress = fullShippingAddress;
      updateData.billingCity = data.shippingCity;
      updateData.billingCounty = data.shippingCounty;
      updateData.billingPostcode = data.shippingPostcode;
    }

    try {
      // If a new avatar is uploaded, handle the upload process
      if (data.uploadedAvatar && data.uploadedAvatar.length > 0) {
        const formData = new FormData();
        formData.append('avatar', data.uploadedAvatar[0]);

        const uploadResponse = await axios.post(`${process.env.REACT_APP_API_URL}/users/upload-avatar`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        updateData.avatar = uploadResponse.data.avatarUrl; // Assuming the API returns the URL of the uploaded avatar
      }

      await updateUser(updateData);
      toast.success('Account info updated successfully');
      reset({ ...data, uploadedAvatar: null }); // Reset the form, keeping other fields intact
    } catch (error) {
      console.error('Failed to update account info:', error);
      toast.error('Failed to update account info');
    }
  };

  // Handle Payment Details Update
  const handleUpdatePaymentDetails = async () => {
    try {
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        throw new Error('CardElement not found');
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          address: {
            line1: watch('billingAddress'),
            city: watch('billingCity'),
            state: watch('billingCounty'),
            postal_code: watch('billingPostcode'),
            country: 'GB',
          },
        },
      });

      if (error) {
        toast.error(error.message);
        return;
      }

      const updatedDetails = {
        billingAddress: watch('billingAddress'),
        billingCity: watch('billingCity'),
        billingCounty: watch('billingCounty'),
        billingPostcode: watch('billingPostcode'),
        paymentDetails: paymentMethod.id,
      };
      await updateUser(updatedDetails);
      toast.success('Payment details updated successfully');
    } catch (error) {
      console.error('Failed to update payment details:', error);
      toast.error('Failed to update payment details');
    }
  };

  // Handle Password Change
  const handleChangePassword = async (data) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/users/change-password`,
        { newPassword: data.newPassword },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      );
      toast.success('Password changed successfully');
      reset({ ...watch(), newPassword: '' });
      setShowPasswordModal(false);
    } catch (error) {
      console.error('Failed to change password:', error);
      toast.error('Failed to change password');
    }
  };

  // Handle Delete Account
  const handleDeleteAccount = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/users/delete-account`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      logout();
      navigate('/register');
      toast.success('Account deleted successfully');
    } catch (error) {
      console.error('Failed to delete account:', error);
      toast.error('Failed to delete account');
    }
  };

  // Handle Avatar Selection
  const handleAvatarSelect = (avatar) => {
    setSelectedAvatar(avatar);
    updateUser({ avatar });
  };

  // Handle Theme Toggle
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    document.documentElement.classList.toggle('dark', newTheme === 'dark');
    toast.success(`Switched to ${newTheme} mode`);
  };

  // Handle Profile Picture Upload via Drag-and-Drop
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setValue('uploadedAvatar', acceptedFiles);
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedAvatar(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }, [setValue]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false,
  });

  // Accessibility: Keyboard Navigation for Modals
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        if (showPasswordModal) setShowPasswordModal(false);
        if (showDeleteModal) setShowDeleteModal(false);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [showPasswordModal, showDeleteModal]);

  // Optimize performance by memoizing components that do not need to re-render frequently
  // Removed RenderActivityLog component

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <motion.div
      className="container mx-auto px-4 py-8 min-h-screen bg-white dark:bg-gray-900 text-black dark:text-white rounded-lg shadow-lg"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {/* Toast Notifications */}
      <ToastContainer />

      {/* Theme Toggle */}
      <div className="flex justify-end mb-4">
        <button
          onClick={toggleTheme}
          className="flex items-center px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded hover:bg-gray-300 dark:hover:bg-gray-600 transition duration-200"
          aria-label="Toggle Dark Mode"
        >
          {theme === 'light' ? <FiMoon className="mr-2" /> : <FiSun className="mr-2" />}
          {theme === 'light' ? 'Dark Mode' : 'Light Mode'}
        </button>
      </div>

      {/* Profile Form */}
      <form onSubmit={handleSubmit(onSubmitAccountInfo)}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Account Info */}
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md border border-gray-200 dark:border-gray-700">
            <h2 className="text-2xl font-semibold mb-4 text-center">Account Info</h2>
            {/* First Name */}
            <div className="mt-4">
              <label htmlFor="firstName" className="block text-gray-700 dark:text-gray-300">First Name</label>
              <input
                id="firstName"
                type="text"
                {...register('firstName')}
                className={`w-full p-2 border ${errors.firstName ? 'border-red-500' : 'border-gray-300'} rounded mt-1 text-black dark:text-white bg-gray-100 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                placeholder="Enter your first name"
                aria-invalid={errors.firstName ? 'true' : 'false'}
              />
              {errors.firstName && <p className="text-red-500 text-sm mt-1">{errors.firstName.message}</p>}
            </div>

            {/* Last Name */}
            <div className="mt-4">
              <label htmlFor="lastName" className="block text-gray-700 dark:text-gray-300">Last Name</label>
              <input
                id="lastName"
                type="text"
                {...register('lastName')}
                className={`w-full p-2 border ${errors.lastName ? 'border-red-500' : 'border-gray-300'} rounded mt-1 text-black dark:text-white bg-gray-100 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                placeholder="Enter your last name"
                aria-invalid={errors.lastName ? 'true' : 'false'}
              />
              {errors.lastName && <p className="text-red-500 text-sm mt-1">{errors.lastName.message}</p>}
            </div>

            {/* Email (Read Only) */}
            {!user?.web3Address && (
              <div className="mt-4">
                <label htmlFor="email" className="block text-gray-700 dark:text-gray-300">Email</label>
                <input
                  id="email"
                  type="email"
                  value={user?.email || ''}
                  readOnly
                  className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded mt-1 text-black dark:text-white bg-gray-100 dark:bg-gray-700 focus:outline-none"
                  aria-label="User Email"
                />
              </div>
            )}

            {/* Phone Number */}
            <div className="mt-4">
              <label htmlFor="phoneNumber" className="block text-gray-700 dark:text-gray-300">Phone Number</label>
              <input
                id="phoneNumber"
                type="text"
                {...register('phoneNumber')}
                className={`w-full p-2 border ${errors.phoneNumber ? 'border-red-500' : 'border-gray-300'} rounded mt-1 text-black dark:text-white bg-gray-100 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                placeholder="Enter your phone number"
                disabled={!!user?.web3Address}
                aria-invalid={errors.phoneNumber ? 'true' : 'false'}
              />
              {errors.phoneNumber && <p className="text-red-500 text-sm mt-1">{errors.phoneNumber.message}</p>}
            </div>

            {/* Birthday */}
            <div className="mt-4">
              <label htmlFor="birthday" className="block text-gray-700 dark:text-gray-300">Birthday</label>
              <input
                id="birthday"
                type="date"
                {...register('birthday')}
                className={`w-full p-2 border ${errors.birthday ? 'border-red-500' : 'border-gray-300'} rounded mt-1 text-black dark:text-white bg-gray-100 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                aria-invalid={errors.birthday ? 'true' : 'false'}
              />
              {errors.birthday && <p className="text-red-500 text-sm mt-1">{errors.birthday.message}</p>}
            </div>

            {/* Email Notifications */}
            <div className="mt-4">
              <label htmlFor="emailNotifications" className="block text-gray-700 dark:text-gray-300">Email Notifications</label>
              <div className="flex items-center mt-1">
                <input
                  id="emailNotifications"
                  type="checkbox"
                  {...register('emailNotifications')}
                  className="form-checkbox h-5 w-5 text-blue-600"
                  aria-label="Toggle Email Notifications"
                />
                <span className="ml-2 text-gray-600 dark:text-gray-400">Receive email notifications</span>
              </div>
            </div>

            {/* Notification Preferences */}
            <div className="mt-4">
              <label className="block text-gray-700 dark:text-gray-300">Notification Preferences</label>
              <div className="flex items-center mt-1">
                <input
                  id="notifyEmail"
                  type="checkbox"
                  {...register('notificationPreferences.email')}
                  className="form-checkbox h-5 w-5 text-blue-600"
                  aria-label="Toggle Email Notifications Preference"
                />
                <label htmlFor="notifyEmail" className="ml-2 text-gray-600 dark:text-gray-400">Email</label>
              </div>
              <div className="flex items-center mt-1">
                <input
                  id="notifySMS"
                  type="checkbox"
                  {...register('notificationPreferences.sms')}
                  className="form-checkbox h-5 w-5 text-blue-600"
                  aria-label="Toggle SMS Notifications Preference"
                />
                <label htmlFor="notifySMS" className="ml-2 text-gray-600 dark:text-gray-400">SMS</label>
              </div>
              <div className="flex items-center mt-1">
                <input
                  id="notifyPush"
                  type="checkbox"
                  {...register('notificationPreferences.push')}
                  className="form-checkbox h-5 w-5 text-blue-600"
                  aria-label="Toggle Push Notifications Preference"
                />
                <label htmlFor="notifyPush" className="ml-2 text-gray-600 dark:text-gray-400">Push Notifications</label>
              </div>
            </div>

            {/* Profile Picture Upload */}
            <div className="mt-4">
              <label htmlFor="uploadedAvatar" className="block text-gray-700 dark:text-gray-300">Upload Profile Picture</label>
              <div
                {...getRootProps()}
                className={`w-full p-4 border-2 border-dashed ${isDragActive ? 'border-blue-500' : 'border-gray-300 dark:border-gray-600'} rounded mt-1 text-center text-gray-600 dark:text-gray-400 bg-gray-100 dark:bg-gray-700 cursor-pointer`}
                aria-label="Profile Picture Upload"
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the image here ...</p>
                ) : (
                  <p>Drag 'n' drop an image here, or click to select one</p>
                )}
              </div>
              {uploadedAvatar && (
                <img src={uploadedAvatar} alt="Avatar Preview" className="mt-4 w-24 h-24 rounded-full object-cover mx-auto" />
              )}
              {errors.uploadedAvatar && <p className="text-red-500 text-sm mt-1">{errors.uploadedAvatar.message}</p>}
            </div>

            {/* Password Change */}
            {!user?.web3Address && (
              <div className="mt-4">
                <label htmlFor="newPassword" className="block text-gray-700 dark:text-gray-300">Change Password</label>
                <div className="relative">
                  <input
                    id="newPassword"
                    type={passwordVisible ? 'text' : 'password'}
                    {...register('newPassword')}
                    className={`w-full p-2 border ${errors.newPassword ? 'border-red-500' : 'border-gray-300'} rounded mt-1 text-black dark:text-white bg-gray-100 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                    placeholder="Enter new password"
                    aria-invalid={errors.newPassword ? 'true' : 'false'}
                  />
                  <button
                    type="button"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    className="absolute right-2 top-2 text-gray-600 dark:text-gray-400"
                    aria-label={passwordVisible ? 'Hide Password' : 'Show Password'}
                  >
                    {passwordVisible ? <FiEyeOff /> : <FiEye />}
                  </button>
                </div>
                {errors.newPassword && <p className="text-red-500 text-sm mt-1">{errors.newPassword.message}</p>}

                {/* Password Strength Indicator */}
                {newPassword && (
                  <div className="mt-2">
                    <label className="block text-gray-700 dark:text-gray-300">Password Strength: {passwordStrengthLabel()}</label>
                    <div className="w-full bg-gray-300 dark:bg-gray-600 h-2 rounded">
                      <div
                        className={`h-2 rounded ${passwordStrength.score < 2
                          ? 'bg-red-500'
                          : passwordStrength.score === 2
                          ? 'bg-yellow-500'
                          : passwordStrength.score === 3
                          ? 'bg-blue-500'
                          : 'bg-green-500'
                          }`}
                        style={{ width: `${(passwordStrength.score + 1) * 20}%` }}
                      ></div>
                    </div>
                  </div>
                )}

                <button
                  type="button"
                  onClick={() => setShowPasswordModal(true)}
                  className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200 w-full"
                  aria-label="Open Change Password Modal"
                >
                  Change Password
                </button>
              </div>
            )}

            {/* Submit Button */}
            <button
              type="submit"
              disabled={isSubmitting}
              className="mt-6 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-200 w-full"
              aria-label="Update Account Info"
            >
              {isSubmitting ? 'Updating...' : 'Update Account Info'}
            </button>

            {/* Logout Button */}
            <button
              type="button"
              onClick={handleLogout}
              className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-200 w-full flex items-center justify-center"
              aria-label="Logout"
            >
              <FiLogOut className="mr-2" />
              Logout
            </button>

            {/* Delete Account Button */}
            <button
              type="button"
              onClick={() => setShowDeleteModal(true)}
              className="mt-4 px-4 py-2 bg-red-700 text-white rounded hover:bg-red-800 transition duration-200 w-full flex items-center justify-center"
              aria-label="Delete Account"
            >
              <FiTrash2 className="mr-2" />
              Delete Account
            </button>
          </div>

          {/* Shipping and Billing Details */}
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md border border-gray-200 dark:border-gray-700">
            <h2 className="text-2xl font-semibold mb-4 text-center">Shipping Details</h2>
            {/* Shipping Address Line 1 */}
            <div className="mt-4">
              <label htmlFor="shippingAddressLine1" className="block text-gray-700 dark:text-gray-300">Shipping Address Line 1</label>
              <input
                id="shippingAddressLine1"
                type="text"
                {...register('shippingAddressLine1')}
                className={`w-full p-2 border ${errors.shippingAddressLine1 ? 'border-red-500' : 'border-gray-300'} rounded mt-1 text-black dark:text-white bg-gray-100 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                placeholder="Enter your address"
                aria-invalid={errors.shippingAddressLine1 ? 'true' : 'false'}
              />
              {errors.shippingAddressLine1 && <p className="text-red-500 text-sm mt-1">{errors.shippingAddressLine1.message}</p>}
            </div>

            {/* Shipping Address Line 2 */}
            <div className="mt-4">
              <label htmlFor="shippingAddressLine2" className="block text-gray-700 dark:text-gray-300">Shipping Address Line 2</label>
              <input
                id="shippingAddressLine2"
                type="text"
                {...register('shippingAddressLine2')}
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded mt-1 text-black dark:text-white bg-gray-100 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your address"
                aria-label="Shipping Address Line 2"
              />
            </div>

            {/* Shipping City */}
            <div className="mt-4">
              <label htmlFor="shippingCity" className="block text-gray-700 dark:text-gray-300">Shipping City</label>
              <input
                id="shippingCity"
                type="text"
                {...register('shippingCity')}
                className={`w-full p-2 border ${errors.shippingCity ? 'border-red-500' : 'border-gray-300'} rounded mt-1 text-black dark:text-white bg-gray-100 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                placeholder="Enter your city"
                aria-invalid={errors.shippingCity ? 'true' : 'false'}
              />
              {errors.shippingCity && <p className="text-red-500 text-sm mt-1">{errors.shippingCity.message}</p>}
            </div>

            {/* Shipping County */}
            <div className="mt-4">
              <label htmlFor="shippingCounty" className="block text-gray-700 dark:text-gray-300">Shipping County</label>
              <input
                id="shippingCounty"
                type="text"
                {...register('shippingCounty')}
                className={`w-full p-2 border ${errors.shippingCounty ? 'border-red-500' : 'border-gray-300'} rounded mt-1 text-black dark:text-white bg-gray-100 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                placeholder="Enter your county"
                aria-invalid={errors.shippingCounty ? 'true' : 'false'}
              />
              {errors.shippingCounty && <p className="text-red-500 text-sm mt-1">{errors.shippingCounty.message}</p>}
            </div>

            {/* Shipping Postcode */}
            <div className="mt-4">
              <label htmlFor="shippingPostcode" className="block text-gray-700 dark:text-gray-300">Shipping Postcode</label>
              <input
                id="shippingPostcode"
                type="text"
                {...register('shippingPostcode')}
                className={`w-full p-2 border ${errors.shippingPostcode ? 'border-red-500' : 'border-gray-300'} rounded mt-1 text-black dark:text-white bg-gray-100 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                placeholder="Enter your postcode"
                aria-invalid={errors.shippingPostcode ? 'true' : 'false'}
              />
              {errors.shippingPostcode && <p className="text-red-500 text-sm mt-1">{errors.shippingPostcode.message}</p>}
            </div>

            {/* Billing Same as Shipping */}
            <div className="mt-4 flex items-center">
              <input
                id="isBillingSameAsShipping"
                type="checkbox"
                {...register('isBillingSameAsShipping')}
                onChange={(e) => setIsBillingSameAsShipping(e.target.checked)}
                className="form-checkbox h-5 w-5 text-blue-600"
                aria-label="Toggle Billing Address Same as Shipping"
              />
              <label htmlFor="isBillingSameAsShipping" className="ml-2 text-gray-600 dark:text-gray-400">
                Billing address same as shipping?
              </label>
            </div>
          </div>

          {/* Billing Details */}
          {!isBillingSameAsShipping && (
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md border border-gray-200 dark:border-gray-700 mt-8">
              <h2 className="text-2xl font-semibold mb-4 text-center">Billing Details</h2>
              {/* Billing Address */}
              <div className="mt-4">
                <label htmlFor="billingAddress" className="block text-gray-700 dark:text-gray-300">Billing Address</label>
                <input
                  id="billingAddress"
                  type="text"
                  {...register('billingAddress')}
                  className={`w-full p-2 border ${errors.billingAddress ? 'border-red-500' : 'border-gray-300'} rounded mt-1 text-black dark:text-white bg-gray-100 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  placeholder="Enter your address"
                  aria-invalid={errors.billingAddress ? 'true' : 'false'}
                />
                {errors.billingAddress && <p className="text-red-500 text-sm mt-1">{errors.billingAddress.message}</p>}
              </div>

              {/* Billing City */}
              <div className="mt-4">
                <label htmlFor="billingCity" className="block text-gray-700 dark:text-gray-300">Billing City</label>
                <input
                  id="billingCity"
                  type="text"
                  {...register('billingCity')}
                  className={`w-full p-2 border ${errors.billingCity ? 'border-red-500' : 'border-gray-300'} rounded mt-1 text-black dark:text-white bg-gray-100 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  placeholder="Enter your city"
                  aria-invalid={errors.billingCity ? 'true' : 'false'}
                />
                {errors.billingCity && <p className="text-red-500 text-sm mt-1">{errors.billingCity.message}</p>}
              </div>

              {/* Billing County */}
              <div className="mt-4">
                <label htmlFor="billingCounty" className="block text-gray-700 dark:text-gray-300">Billing County</label>
                <input
                  id="billingCounty"
                  type="text"
                  {...register('billingCounty')}
                  className={`w-full p-2 border ${errors.billingCounty ? 'border-red-500' : 'border-gray-300'} rounded mt-1 text-black dark:text-white bg-gray-100 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  placeholder="Enter your county"
                  aria-invalid={errors.billingCounty ? 'true' : 'false'}
                />
                {errors.billingCounty && <p className="text-red-500 text-sm mt-1">{errors.billingCounty.message}</p>}
              </div>

              {/* Billing Postcode */}
              <div className="mt-4">
                <label htmlFor="billingPostcode" className="block text-gray-700 dark:text-gray-300">Billing Postcode</label>
                <input
                  id="billingPostcode"
                  type="text"
                  {...register('billingPostcode')}
                  className={`w-full p-2 border ${errors.billingPostcode ? 'border-red-500' : 'border-gray-300'} rounded mt-1 text-black dark:text-white bg-gray-100 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  placeholder="Enter your postcode"
                  aria-invalid={errors.billingPostcode ? 'true' : 'false'}
                />
                {errors.billingPostcode && <p className="text-red-500 text-sm mt-1">{errors.billingPostcode.message}</p>}
              </div>
            </div>
          )}

          {/* Avatar Selection */}
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md border border-gray-200 dark:border-gray-700 mt-8">
            <h2 className="text-2xl font-semibold mb-4 text-center">Select Avatar</h2>
            <div className="grid grid-cols-3 gap-4">
              {avatars.map((avatar, index) => (
                <div
                  key={index}
                  className={`cursor-pointer p-2 rounded-full ${selectedAvatar === avatar ? 'border-4 border-blue-500' : 'border border-gray-300 dark:border-gray-600'}`}
                  onClick={() => handleAvatarSelect(avatar)}
                  aria-label={`Select Avatar ${index + 1}`}
                >
                  <Avatar name={`Avatar ${index + 1}`} src={avatar} size="50" round />
                </div>
              ))}
            </div>
          </div>

          {/* Removed Wishlist Section */}
          {/*
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md border border-gray-200 dark:border-gray-700 mt-8">
            <h2 className="text-2xl font-semibold mb-4 text-center">Wishlist</h2>
            {wishlist.length > 0 ? (
              <ul>
                {wishlist.map((item) => (
                  <li key={item.id} className="flex justify-between items-center mb-4">
                    <a href={`/product/${item.Product.id}`} className="text-black dark:text-white flex items-center space-x-4">
                      <img
                        src={`${process.env.REACT_APP_API_URL}/${item.Product.imageUrl}`}
                        alt={item.Product.name}
                        className="w-16 h-16 object-cover rounded"
                      />
                      <span>{item.Product.name}</span>
                    </a>
                    <button
                      onClick={() => handleRemoveFromWishlist(item.productId)}
                      className="text-red-500 hover:text-red-700 transition duration-200"
                      aria-label={`Remove ${item.Product.name} from wishlist`}
                    >
                      <FiTrash2 />
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-600 dark:text-gray-400">No items in wishlist</p>
            )}
            <button
              type="button"
              onClick={handleShareWishlist}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200 flex items-center space-x-2 w-full"
              aria-label="Share Wishlist"
            >
              <FiShare2 />
              <span>Share Wishlist</span>
            </button>
            {shareLink && (
              <div className="mt-4">
                <input
                  type="text"
                  value={shareLink}
                  readOnly
                  className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded text-black dark:text-white bg-gray-100 dark:bg-gray-700"
                  aria-label="Shareable Wishlist Link"
                />
                <button
                  type="button"
                  onClick={handleCopyLink}
                  className="mt-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-200 w-full"
                  aria-label="Copy Wishlist Link"
                >
                  Copy Link
                </button>
              </div>
            )}
          </div>
          */}
        </div>
      </form>

      {/* Payment Details Section */}
      <div className="container mx-auto px-4 py-8 bg-white dark:bg-gray-900 text-black dark:text-white rounded-lg shadow-lg mt-8">
        <h2 className="text-3xl font-bold mb-6 text-center">Payment Details</h2>
        <div className="max-w-md mx-auto">
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: '#000',
                  '::placeholder': {
                    color: '#a0aec0',
                  },
                },
                invalid: {
                  color: '#e53e3e',
                },
              },
            }}
          />
          <button
            type="button"
            onClick={handleUpdatePaymentDetails}
            className="mt-6 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200 w-full"
            aria-label="Update Payment Details"
          >
            Update Payment Details
          </button>
        </div>
      </div>

      {/* Password Modal */}
      <AnimatePresence>
        {showPasswordModal && (
          <motion.div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md"
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <h2 className="text-2xl font-semibold mb-6 text-center text-gray-800 dark:text-gray-200">Change Password</h2>
              <form onSubmit={handleSubmit(handleChangePassword)} noValidate>
                {/* New Password */}
                <div className="mt-4">
                  <label htmlFor="newPassword" className="block text-gray-700 dark:text-gray-300">New Password</label>
                  <div className="relative">
                    <input
                      id="newPassword"
                      type={passwordVisible ? 'text' : 'password'}
                      {...register('newPassword')}
                      className={`w-full p-2 border ${errors.newPassword ? 'border-red-500' : 'border-gray-300'} rounded mt-1 text-black dark:text-white bg-gray-100 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                      placeholder="Enter new password"
                      aria-invalid={errors.newPassword ? 'true' : 'false'}
                    />
                    <button
                      type="button"
                      onClick={() => setPasswordVisible(!passwordVisible)}
                      className="absolute right-2 top-2 text-gray-600 dark:text-gray-400"
                      aria-label={passwordVisible ? 'Hide Password' : 'Show Password'}
                    >
                      {passwordVisible ? <FiEyeOff /> : <FiEye />}
                    </button>
                  </div>
                  {errors.newPassword && <p className="text-red-500 text-sm mt-1">{errors.newPassword.message}</p>}
                </div>

                {/* Password Strength Indicator */}
                {newPassword && (
                  <div className="mt-2">
                    <label className="block text-gray-700 dark:text-gray-300">Password Strength: {passwordStrengthLabel()}</label>
                    <div className="w-full bg-gray-300 dark:bg-gray-600 h-2 rounded">
                      <div
                        className={`h-2 rounded ${passwordStrength.score < 2
                          ? 'bg-red-500'
                          : passwordStrength.score === 2
                          ? 'bg-yellow-500'
                          : passwordStrength.score === 3
                          ? 'bg-blue-500'
                          : 'bg-green-500'
                          }`}
                        style={{ width: `${(passwordStrength.score + 1) * 20}%` }}
                      ></div>
                    </div>
                  </div>
                )}

                {/* Modal Buttons */}
                <div className="flex justify-between mt-6">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-200 w-full mr-2"
                    aria-label="Save New Password"
                  >
                    {isSubmitting ? 'Saving...' : 'Save'}
                  </button>
                  <button
                    type="button"
                    onClick={() => setShowPasswordModal(false)}
                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-200 w-full ml-2"
                    aria-label="Cancel Password Change"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Delete Confirmation Modal */}
      <AnimatePresence>
        {showDeleteModal && (
          <motion.div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md"
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <h2 className="text-2xl font-semibold mb-6 text-center text-red-600">Confirm Account Deletion</h2>
              <p className="text-gray-700 dark:text-gray-300 mb-6 text-center">
                Are you sure you want to delete your account? This action cannot be undone.
              </p>
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={handleDeleteAccount}
                  className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition duration-200 w-full mr-2 flex items-center justify-center"
                  aria-label="Confirm Account Deletion"
                >
                  <FiTrash2 className="mr-2" />
                  Delete
                </button>
                <button
                  type="button"
                  onClick={() => setShowDeleteModal(false)}
                  className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition duration-200 w-full flex items-center justify-center"
                  aria-label="Cancel Account Deletion"
                >
                  Cancel
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

// ProfilePage Component with Stripe Elements
const ProfilePage = () => {
  return (
    <Elements stripe={stripePromise}>
      <ProfilePageContent />
    </Elements>
  );
};

export default ProfilePage;
