// src/components/CustomAutocomplete.js 

import React, { useState, useEffect, useRef } from 'react';
import { FaSearch } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import PropTypes from 'prop-types';

const CustomAutocomplete = ({ address, setAddress, onSelect }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const debounceTimeout = useRef(null);

  const fetchSuggestions = async (query) => {
    if (!query) {
      setSuggestions([]);
      return;
    }

    setLoadingSuggestions(true);

    try {
      const response = await axios.get(
        `https://api.opencagedata.com/geocode/v1/json`,
        {
          params: {
            q: query,
            key: process.env.REACT_APP_OPENCAGE_API_KEY, // Ensure this is correctly set
            limit: 5, // Limit to 5 suggestions
            language: 'en',
          },
        }
      );

      if (response.data && response.data.results) {
        setSuggestions(response.data.results);
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      // Optionally, handle errors (e.g., show a toast notification)
    } finally {
      setLoadingSuggestions(false);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setAddress(value);
    setShowSuggestions(true);

    // Debounce the API call
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      fetchSuggestions(value);
    }, 500);
  };

  const handleSelect = (suggestion) => {
    setAddress(suggestion.formatted);
    setShowSuggestions(false);
    onSelect(suggestion);
  };

  // Close suggestions when clicking outside
  const wrapperRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        setShowSuggestions(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full" ref={wrapperRef}>
      <input
        type="text"
        value={address}
        onChange={handleChange}
        placeholder="Enter your postcode or address"
        className="w-full px-4 py-2 border border-gray-300 rounded-full text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
        aria-label="Search for stores by address or postcode"
        onFocus={() => setShowSuggestions(true)}
      />
      <motion.button
        onClick={() => handleSelect({ formatted: address })}
        className="absolute right-0 top-0 mt-2 mr-2 bg-blue-600 hover:bg-blue-700 text-black p-2 rounded-full"
        whileHover={{ scale: 1.2 }}
        aria-label="Execute search"
      >
        <FaSearch />
      </motion.button>

      <AnimatePresence>
        {showSuggestions && suggestions.length > 0 && (
          <motion.ul
            className="absolute left-0 right-0 bg-white border border-gray-300 mt-2 rounded-md shadow-lg z-10 max-h-60 overflow-y-auto"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.3 }}
          >
            {suggestions.map((suggestion) => (
              <li
                key={suggestion.annotations.geohash}
                onClick={() => handleSelect(suggestion)}
                className="px-4 py-2 cursor-pointer hover:bg-gray-100 text-black" // Added text-black
                role="option"
                aria-selected="false"
              >
                {suggestion.formatted}
              </li>
            ))}
          </motion.ul>
        )}
        {showSuggestions && loadingSuggestions && (
          <motion.div
            className="absolute left-0 right-0 bg-white border border-gray-300 mt-2 rounded-md shadow-lg z-10 p-4 text-black" // Added text-black
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.3 }}
          >
            Loading...
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

CustomAutocomplete.propTypes = {
  address: PropTypes.string.isRequired,
  setAddress: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default CustomAutocomplete;
