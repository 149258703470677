// frontend/src/Pages/Admin.js

import React, { useState, Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUserContext } from '../components/contexts/UserContext';
import Sidebar from '../components/Sidebar';
import TopBar from '../components/TopBar';
import Overview from '../components/Overview';
import OrdersManagement from '../components/OrdersManagment/OrdersManagment';
import UsersManagement from '../components/UsersManagement';
import ProductsManagement from '../components/ProductManagement';
// import InventoryManagement from '../components/InventoryManagement'; // Disabled
import PaymentsManagement from '../components/PaymentsManagement';
import StoreManagement from '../components/StoresManagement'; // Import StoreManagement

const Admin = () => {
  const { user } = useUserContext();
  const [selectedTab, setSelectedTab] = useState('overview');
  const [darkMode, setDarkMode] = useState(false);

  // Unauthorized Access Handling
  if (!user || user.role !== 'admin') {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <p className="text-red-500 text-2xl">Unauthorized Access</p>
      </div>
    );
  }

  // Toggle Dark Mode
  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    document.documentElement.classList.toggle('dark');
  };

  // Render Content Based on Selected Tab
  const renderContent = () => {
    switch (selectedTab) {
      case 'overview':
        return <Overview />;
      case 'orders':
        return <OrdersManagement />;
      case 'users':
        return <UsersManagement />;
      case 'products':
        return <ProductsManagement />;
      // case 'inventory':
      //   return <InventoryManagement />; // Disabled
      case 'payments':
        return <PaymentsManagement />;
      case 'stores': // New case for stores
        return <StoreManagement />;
      default:
        return <Overview />;
    }
  };

  return (
    <div
      className={`flex flex-col min-h-screen ${
        darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-black'
      } transition-all duration-300`}
    >
      <ToastContainer />
      <TopBar toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
      <div className="flex flex-grow">
        <Sidebar selectedTab={selectedTab} setSelectedTab={setSelectedTab} darkMode={darkMode} />
        <main className="flex-grow p-6 overflow-auto">
          <Suspense fallback={<div className="text-center">Loading...</div>}>{renderContent()}</Suspense>
        </main>
      </div>
    </div>
  );
};

export default Admin;
