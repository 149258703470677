// frontend/src/components/AddToCartModal.js

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useCart } from './contexts/CartContext';
import { FaTimes, FaShoppingCart } from 'react-icons/fa';
import { toast } from 'react-toastify';
import IngredientsSelection from './IngredientsSelection';
import { motion, AnimatePresence } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { addNotification } from '../features/notifcation/notificationsSlice';
import { v4 as uuidv4 } from 'uuid';

Modal.setAppElement('#root');

const AddToCartModal = ({ isOpen, onRequestClose, product }) => {
  const { addItemToCart } = useCart();
  const dispatch = useDispatch();

  const [selectedSize, setSelectedSize] = useState('');
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});

  const [priceBreakdown, setPriceBreakdown] = useState({
    basePrice: 0,
    sizePrice: 0,
    ingredientsPrice: 0,
    optionsPrice: 0,
    total: 0,
  });

  // Memoized sizeOptions with fallback to stockOptions
  const sizeOptions = useMemo(() => {
    let options = [];
    if (product) {
      if (product.sizeOptions && product.sizeOptions.length > 0) {
        if (typeof product.sizeOptions === 'string') {
          try {
            const parsed = JSON.parse(product.sizeOptions);
            if (Array.isArray(parsed)) {
              options = parsed.filter(
                (size) => typeof size === 'string' && size.trim().length > 0
              );
            } else {
              console.warn('sizeOptions is not an array:', parsed);
            }
          } catch (error) {
            console.error('Error parsing sizeOptions:', error);
          }
        } else if (Array.isArray(product.sizeOptions)) {
          options = product.sizeOptions.filter(
            (size) => typeof size === 'string' && size.trim().length > 0
          );
        } else {
          console.warn('sizeOptions is neither a string nor an array:', product.sizeOptions);
        }
      }

      // Fallback: Extract sizes from stockOptions if sizeOptions is empty
      if (options.length === 0 && product.stockOptions && Array.isArray(product.stockOptions)) {
        // Extract unique sizes from stockOptions
        options = [...new Set(product.stockOptions.map((option) => option.size))].filter(
          (size) => typeof size === 'string' && size.trim().length > 0
        );
        console.log('Extracted sizeOptions from stockOptions:', options);
      }

      console.log('Final sizeOptions:', options);
    }
    return options;
  }, [product]);

  // Memoized ingredients list
  const ingredientsList = useMemo(() => {
    return product && Array.isArray(product.ingredients) ? product.ingredients : [];
  }, [product]);

  // Memoized optionGroups directly from product.optionGroups
  const optionGroupsList = useMemo(() => {
    if (product && Array.isArray(product.optionGroups)) {
      return product.optionGroups.map((group) => ({
        id: group.id,
        name: group.name,
        optionChoices: Array.isArray(group.optionChoices) ? group.optionChoices : [],
      }));
    }
    return [];
  }, [product]);

  // Effect to set selected size, ingredients, and options
  useEffect(() => {
    if (product && isOpen) {
      const savedSize = localStorage.getItem(`product-${product.id}-size`);
      const savedIngredients = JSON.parse(localStorage.getItem(`product-${product.id}-ingredients`)) || {};
      const savedOptions = JSON.parse(localStorage.getItem(`product-${product.id}-options`)) || {};

      if (sizeOptions.length === 1) {
        setSelectedSize(sizeOptions[0]);
        console.log(`Single size option detected. Auto-selecting size: ${sizeOptions[0]}`);
      } else if (sizeOptions.length > 1) {
        setSelectedSize(savedSize || '');
        console.log(`Multiple size options detected. Selected size: ${savedSize || 'None'}`);
      } else {
        setSelectedSize('');
        console.warn('No valid size options available, not setting any size.');
      }

      setSelectedIngredients(savedIngredients.selectedIngredients || []);
      console.log('Selected ingredients loaded from localStorage:', savedIngredients.selectedIngredients || []);

      setSelectedOptions(savedOptions.selectedOptions || {});
      console.log('Selected options loaded from localStorage:', savedOptions.selectedOptions || {});
    }
  }, [product, isOpen, sizeOptions]);

  // Effect to save selected size, ingredients, and options
  useEffect(() => {
    if (product) {
      localStorage.setItem(`product-${product.id}-size`, selectedSize);
      localStorage.setItem(`product-${product.id}-ingredients`, JSON.stringify({ selectedIngredients }));
      localStorage.setItem(`product-${product.id}-options`, JSON.stringify({ selectedOptions }));
      console.log(`Saved selected size: ${selectedSize}, ingredients, and options to localStorage.`);
    }
  }, [selectedSize, selectedIngredients, selectedOptions, product]);

  const handleSizeChange = useCallback((e) => {
    setSelectedSize(e.target.value);
    console.log(`Size changed to: ${e.target.value}`);
  }, []);

  const handleOptionGroupChange = useCallback((groupId, choiceId) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [groupId]: choiceId,
    }));
    console.log(`Selected choice ${choiceId} for group ${groupId}`);
  }, []);

  const getSizePrice = useCallback((size) => {
    const sizePrices = {
      Small: 0,
      Medium: 1.5,
      Large: 3,
      Standard: 0,
      L: 2,
    };
    const price = sizePrices[size] || 0;
    console.log(`Price for size "${size}": £${price}`);
    return price;
  }, []);

  // Effect to calculate price breakdown
  useEffect(() => {
    if (!product) {
      setPriceBreakdown({
        basePrice: 0,
        sizePrice: 0,
        ingredientsPrice: 0,
        optionsPrice: 0,
        total: 0,
      });
      return;
    }

    const basePrice = parseFloat(product.price) || 0;
    const sizePrice = selectedSize && sizeOptions.length > 0 ? getSizePrice(selectedSize) : 0;
    const ingredientsPrice = selectedIngredients.reduce((acc, ing) => {
      const ingredient = ingredientsList.find((item) => item.id === ing.ingredientId);
      const ingredientPrice = ingredient?.price ? parseFloat(ingredient.price) : 0;
      return acc + (ingredientPrice * ing.quantity);
    }, 0);
    const optionsPrice = Object.values(selectedOptions).reduce((acc, choiceId) => {
      const choice = product.optionChoices.find((c) => c.id === parseInt(choiceId, 10));
      return acc + (choice?.additionalPrice ? parseFloat(choice.additionalPrice) : 0);
    }, 0);
    const total = basePrice + sizePrice + ingredientsPrice + optionsPrice;

    setPriceBreakdown({
      basePrice,
      sizePrice,
      ingredientsPrice,
      optionsPrice,
      total,
    });

    console.log(`Price Breakdown - Base: £${basePrice}, Size: £${sizePrice}, Ingredients: £${ingredientsPrice}, Options: £${optionsPrice}, Total: £${total}`);
  }, [product, selectedSize, selectedIngredients, selectedOptions, getSizePrice, sizeOptions, ingredientsList]);

  const handleAddToCart = useCallback(async () => {
    try {
      let finalSize = selectedSize;

      // Validate size selection
      if (sizeOptions.length > 1 && !finalSize) {
        toast.error('Please select a size.');
        console.warn('Attempted to add to cart without selecting a size.');
        return;
      }

      // Validate ingredients
      for (let i = 0; i < selectedIngredients.length; i++) {
        const ing = selectedIngredients[i];
        if (!ing.ingredientId || typeof ing.ingredientId !== 'number' || ing.ingredientId <= 0) {
          toast.error(`Invalid ingredient selection at position ${i + 1}.`);
          console.warn(`Invalid ingredient selection at position ${i + 1}:`, ing);
          return;
        }
      }

      // Validate option choices
      for (let group of optionGroupsList) {
        if (group.id && !selectedOptions[group.id]) {
          toast.error(`Please select an option for "${group.name}".`);
          console.warn(`Option group "${group.name}" has no selected choice.`);
          return;
        }
      }

      const finalIngredients = selectedIngredients
        .filter((ing) => ing.quantity > 0)
        .map((ing) => ({
          ingredientId: ing.ingredientId,
          quantity: ing.quantity,
        }));

      // Transform selectedOptions into the format expected by the backend
      const formattedSelectedOptions = Object.entries(selectedOptions).map(([groupId, choiceId]) => ({
        optionGroupId: parseInt(groupId, 10),
        optionChoiceId: parseInt(choiceId, 10),
      }));

      // Prepare attributes
      const attributes = {
        size: finalSize,
        ingredients: finalIngredients,
      };

      // Updated cart item payload
      const cartItem = {
        productId: product.id,
        selectedOptions: formattedSelectedOptions, // Matches what addItemToCart expects
        additionalAddOns: [], // Populate if you have additional add-ons
        quantity: 1,
        attributes, // Include attributes
        price: priceBreakdown.total.toFixed(2),
        imageUrl: product.imageUrl,
      };

      console.log('Adding to cart with payload:', cartItem);

      await addItemToCart(cartItem);

      toast.success('Product added to cart!');
      const notification = {
        id: uuidv4(),
        message: 'Product added to cart!',
        timestamp: new Date().toISOString(),
      };
      dispatch(addNotification(notification));
      onRequestClose();
      console.log('Successfully added product to cart.');
    } catch (error) {
      if (error.response) {
        toast.error(`Failed to add to cart: ${error.response.data.message || 'Unknown error'}`);
        console.error('Server error:', error.response.data);
      } else if (error.request) {
        toast.error('No response from server.');
        console.error('No response:', error.request);
      } else {
        toast.error(`Error: ${error.message}`);
        console.error('Error:', error.message);
      }
    }
  }, [selectedSize, selectedIngredients, selectedOptions, product, addItemToCart, dispatch, onRequestClose, optionGroupsList, sizeOptions, priceBreakdown.total]);

  return (
    <AnimatePresence>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          contentLabel="Add to Cart"
          className="max-w-5xl mx-auto mt-10 bg-white rounded-2xl shadow-lg p-8 relative overflow-hidden transition-colors duration-300"
          overlayClassName="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50 overflow-auto"
          closeTimeoutMS={300}
          role="dialog"
          aria-modal="true"
          aria-labelledby="add-to-cart-modal-title"
        >
          <button
            type="button"
            onClick={onRequestClose}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
            aria-label="Close Modal"
          >
            <FaTimes size={24} />
          </button>

          {product ? (
            <motion.div
              className="flex flex-col lg:flex-row"
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.3 }}
            >
              <div className="lg:w-1/3 flex justify-center items-center">
                <img
                  src={
                    /^https?:\/\//i.test(product.imageUrl)
                      ? product.imageUrl
                      : `${process.env.REACT_APP_API_URL}${product.imageUrl}`
                  }
                  alt={product.name}
                  className="w-64 h-64 object-contain rounded-xl shadow-md"
                  loading="lazy"
                />
              </div>

              <div className="lg:w-2/3 lg:ml-12 mt-8 lg:mt-0">
                <h2 id="add-to-cart-modal-title" className="text-3xl font-extrabold text-gray-800 mb-6">
                  {product.name}
                </h2>

                {sizeOptions.length === 1 && (
                  <div className="mb-6">
                    <label className="block text-lg font-medium text-gray-700 mb-2">
                      Size:
                    </label>
                    <p className="bg-gray-100 text-gray-800 px-4 py-2 rounded-lg">
                      {sizeOptions[0]}
                    </p>
                  </div>
                )}

                {sizeOptions.length > 1 && (
                  <div className="mb-6">
                    <label htmlFor="size-select" className="block text-lg font-medium text-gray-700 mb-2">
                      Select Size:
                    </label>
                    <div className="relative">
                      <select
                        id="size-select"
                        value={selectedSize}
                        onChange={handleSizeChange}
                        className="block appearance-none w-full bg-gray-50 border border-gray-300 hover:border-pink-500 px-4 py-2 pr-10 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-pink-500 transition-colors duration-200 text-black"
                        aria-label="Select Size"
                      >
                        <option value="">Choose a size</option>
                        {sizeOptions.map((size, idx) => (
                          <option key={idx} value={size}>
                            {size} {getSizePrice(size) > 0 && `(+£${getSizePrice(size).toFixed(2)})`}
                          </option>
                        ))}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-5 w-5" viewBox="0 0 20 20">
                          <path d="M5.516 7.548L10 12.04l4.484-4.492L15.516 9l-5 5-5-5z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                )}

                {optionGroupsList.length > 0 && (
                  <div className="mb-6">
                    <label className="block text-lg font-medium text-gray-700 mb-2">
                      Select Options:
                    </label>
                    {optionGroupsList.map((group) => (
                      <div key={group.id} className="mb-4">
                        <label className="block text-gray-600 font-semibold mb-1">
                          {group.name}
                        </label>
                        <select
                          value={selectedOptions[group.id] || ''}
                          onChange={(e) => handleOptionGroupChange(group.id, e.target.value)}
                          className="block w-full bg-gray-50 border border-gray-300 hover:border-pink-500 px-4 py-2 pr-10 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-pink-500 transition-colors duration-200 text-black"
                          aria-label={`Select ${group.name}`}
                        >
                          <option value="">Choose an option</option>
                          {group.optionChoices.map((choice) => (
                            <option key={choice.id} value={choice.id}>
                              {choice.name} {choice.additionalPrice > 0 && `(+£${choice.additionalPrice.toFixed(2)})`}
                            </option>
                          ))}
                        </select>
                      </div>
                    ))}
                  </div>
                )}

                <IngredientsSelection
                  ingredients={ingredientsList}
                  selectedIngredients={selectedIngredients}
                  setSelectedIngredients={setSelectedIngredients}
                />

                <div className="mb-6">
                  <h3 className="text-xl font-semibold text-gray-700 mb-2">Price Breakdown:</h3>
                  <ul className="space-y-1 text-gray-700">
                    <li className="flex justify-between">
                      <span>Base Price:</span>
                      <span>£{priceBreakdown.basePrice.toFixed(2)}</span>
                    </li>
                    {priceBreakdown.sizePrice > 0 && selectedSize && (
                      <li className="flex justify-between">
                        <span>Size ({selectedSize}):</span>
                        <span>£{priceBreakdown.sizePrice.toFixed(2)}</span>
                      </li>
                    )}
                    {priceBreakdown.optionsPrice > 0 && (
                      <li className="flex justify-between">
                        <span>Options:</span>
                        <span>£{priceBreakdown.optionsPrice.toFixed(2)}</span>
                      </li>
                    )}
                    {priceBreakdown.ingredientsPrice > 0 && (
                      <li className="flex justify-between">
                        <span>Ingredients:</span>
                        <span>£{priceBreakdown.ingredientsPrice.toFixed(2)}</span>
                      </li>
                    )}
                    <li className="flex justify-between font-bold text-gray-800">
                      <span>Total:</span>
                      <span>£{priceBreakdown.total.toFixed(2)}</span>
                    </li>
                  </ul>
                </div>

                <div className="flex items-center space-x-4">
                  <button
                    type="button"
                    onClick={onRequestClose}
                    className="flex items-center px-4 py-2 bg-gray-200 text-gray-700 rounded-full hover:bg-gray-300 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-gray-400"
                    aria-label="Cancel"
                  >
                    <FaTimes size={20} />
                  </button>

                  <button
                    type="button"
                    onClick={handleAddToCart}
                    className="flex items-center px-4 py-2 bg-pink-600 text-white rounded-full hover:bg-pink-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-pink-500"
                    aria-label="Add to Cart"
                  >
                    <FaShoppingCart size={20} />
                  </button>
                </div>
              </div>
            </motion.div>
          ) : (
            <motion.p
              className="text-gray-700 text-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              Loading product details...
            </motion.p>
          )}
        </Modal>
      )}
    </AnimatePresence>
  );
};

AddToCartModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  product: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    sizeOptions: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    stockOptions: PropTypes.arrayOf(
      PropTypes.shape({
        size: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        stock: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        optionGroups: PropTypes.arrayOf(
          PropTypes.shape({
            groupId: PropTypes.number.isRequired,
          })
        ),
        optionChoices: PropTypes.arrayOf(
          PropTypes.shape({
            choiceId: PropTypes.number.isRequired,
          })
        ),
      })
    ),
    optionGroups: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    optionChoices: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        groupId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        additionalPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      })
    ),
    ingredients: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        imageUrl: PropTypes.string,
        category: PropTypes.string.isRequired,
        ProductIngredient: PropTypes.shape({
          quantity: PropTypes.number.isRequired,
        }),
      })
    ),
    recipe: PropTypes.shape({
      id: PropTypes.number.isRequired,
      productId: PropTypes.number.isRequired,
      instructions: PropTypes.string.isRequired,
      prepTime: PropTypes.number.isRequired,
      cookTime: PropTypes.number.isRequired,
      servings: PropTypes.number.isRequired,
      createdAt: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
      recipeIngredients: PropTypes.array,
    }),
  }).isRequired,
};

export default AddToCartModal;
