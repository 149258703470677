// frontend/src/components/contexts/config.js

const config = {
  frontend: {
    url: process.env.REACT_APP_FRONTEND_URL || 'http://localhost:3000', // Default to localhost if not set
  },
  api: {
    url: process.env.REACT_APP_API_URL || 'http://localhost:5003', // Default API URL
  },
  api2: {
    url: process.env.REACT_APP_API2_URL || 'http://localhost:5003', // Default API URL
  },

  IMAGE_BASE_URL: 'https://cookies.quantaum.co.uk',

  stripe: {
    publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  },
  // Add other configurations as needed
};

export default config;
