// frontend/src/components/contexts/StoreContext.js

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useUserContext } from './UserContext';
import api from '../../api'; // Adjust the import based on your project structure
import logger from '../../utils/logger';
import { toast } from 'react-toastify';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import { milesToKilometers } from '../../utils/convertUnits'; // Correct import path
import { io } from 'socket.io-client';
import config from './config'; // Adjust the path based on your project structure
import { useDispatch } from 'react-redux';
import { addItem, removeItem, fetchCart } from '../../features/cart/cartSlice';

// Create StoreContext
const StoreContext = createContext();

// Export useStore hook for easy consumption
export const useStore = () => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error('useStore must be used within a StoreProvider');
  }
  return context;
};

// StoreProvider component that wraps around your application
export const StoreProvider = ({ children }) => {
  const { token, user } = useUserContext();
  const dispatch = useDispatch(); // Initialize Redux dispatch

  // State Variables
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [productDetails, setProductDetails] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    currentPage: 1,
    totalPages: 1,
  });
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [userReview, setUserReview] = useState({ rating: 0, comment: '' });
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Modal States
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [isAddToCartModalOpen, setIsAddToCartModalOpen] = useState(false);
  const [selectedProductForCart, setSelectedProductForCart] = useState(null);

  // Toggle State
  const [toggle, setToggle] = useState(false);

  // Store State Variables
  const [stores, setStores] = useState([]);
  const [nearestStore, setNearestStore] = useState(null);
  const [loadingStores, setLoadingStores] = useState(false);
  const [storeError, setStoreError] = useState(null);

  // Payment Methods
  const [paymentMethods, setPaymentMethods] = useState([]);

  // Payments
  const [payments, setPayments] = useState([]);

  // Flag to ensure initialization runs only once
  const [initialized, setInitialized] = useState(false);

  // Pagination State
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page

  // Toggle function if needed
  const toggleFunction = useCallback(() => {
    setToggle((prevToggle) => !prevToggle);
  }, []); // No dependencies since it only uses setToggle

  // userHasPurchased state
  const [userHasPurchased, setUserHasPurchased] = useState(false);

  // Selected Store State
  const [selectedStore, setSelectedStore] = useState(null);

  // ---------------------------
  // ===== Option Groups =======
  // ---------------------------
  const [optionGroups, setOptionGroups] = useState([]);
  const [optionChoices, setOptionChoices] = useState([]);

  /**
   * Fetches all option groups along with their option choices.
   * Now uses the unified '/option-groups' endpoint.
   * @returns {Promise<void>}
   */
  const fetchOptionGroups = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      console.log('Fetching option groups...');
      const response = await api.get('/option-groups', {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
        params: { include: 'optionChoices' }, // Ensure optionChoices are included
      });
      setOptionGroups(response.data.data);
      logger.info('Fetched option groups successfully.');
      console.log('Fetched option groups:', response.data.data);
    } catch (err) {
      logger.error('Failed to fetch option groups:', err);
      setError('Failed to fetch option groups');
      toast.error('Failed to fetch option groups.');
    } finally {
      setLoading(false);
    }
  }, [token]);

  /**
   * Creates a new option group.
   * @param {string} name - The name of the option group.
   * @param {boolean} isRequired - Whether the option group is required.
   * @returns {Promise<Object|null>} - The newly created option group or null if failed.
   */
  const createOptionGroup = useCallback(
    async (name, isRequired = false) => {
      if (!name || name.trim() === '') {
        setError('Option group name is required.');
        toast.error('Option group name is required.');
        return null;
      }

      if (!token) {
        logger.warn('createOptionGroup called without a valid token.');
        setError('Authentication required to create option group.');
        toast.error('You must be logged in to create an option group.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log('Creating option group:', { name, isRequired });
        const response = await api.post(
          '/option-groups',
          { name, isRequired },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const newOptionGroup = response.data.data;

        setOptionGroups((prevGroups) => [...prevGroups, newOptionGroup]);
        logger.info('Option group created successfully.');
        toast.success('Option group created successfully!');
        console.log('Created option group:', newOptionGroup);
        return newOptionGroup;
      } catch (err) {
        logger.error('Failed to create option group:', err);
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error('Failed to create option group.');
        }
        setError('Failed to create option group');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Updates an existing option group.
   * @param {number} groupId - The ID of the option group to update.
   * @param {Object} updates - The updates to apply (e.g., { name, isRequired }).
   * @returns {Promise<Object|null>} - The updated option group or null if failed.
   */
  const updateOptionGroup = useCallback(
    async (groupId, updates) => {
      if (!groupId || !updates) {
        setError('Option group ID and updates are required.');
        toast.error('Option group ID and updates are required.');
        return null;
      }

      if (!token) {
        logger.warn('updateOptionGroup called without a valid token.');
        setError('Authentication required to update option group.');
        toast.error('You must be logged in to update an option group.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Updating option group ID ${groupId} with:`, updates);
        const response = await api.put(`/option-groups/${groupId}`, updates, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const updatedGroup = response.data.data;
        setOptionGroups((prevGroups) =>
          prevGroups.map((group) =>
            group.id === groupId ? updatedGroup : group
          )
        );
        logger.info(`Option group ID ${groupId} updated successfully.`);
        toast.success('Option group updated successfully!');
        console.log('Updated option group:', updatedGroup);
        return updatedGroup;
      } catch (err) {
        logger.error(`Failed to update option group ID ${groupId}:`, err);
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error('Failed to update option group.');
        }
        setError('Failed to update option group');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Deletes an option group.
   * @param {number} groupId - The ID of the option group to delete.
   * @returns {Promise<void>}
   */
  const deleteOptionGroup = useCallback(
    async (groupId) => {
      if (!groupId) {
        setError('Option group ID is required.');
        toast.error('Option group ID is required.');
        return;
      }

      if (!token) {
        logger.warn('deleteOptionGroup called without a valid token.');
        setError('Authentication required to delete option group.');
        toast.error('You must be logged in to delete an option group.');
        return;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Deleting option group ID ${groupId}`);
        await api.delete(`/option-groups/${groupId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOptionGroups((prevGroups) =>
          prevGroups.filter((group) => group.id !== groupId)
        );
        logger.info(`Option group ID ${groupId} deleted successfully.`);
        toast.success('Option group deleted successfully!');
      } catch (err) {
        logger.error(`Failed to delete option group ID ${groupId}:`, err);
        setError('Failed to delete option group');
        toast.error('Failed to delete option group.');
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  // ---------------------------
  // ===== Option Choices ======
  // ---------------------------
  /**
   * Fetches all option choices.
   * Now uses the unified '/option-choices' endpoint.
   * @returns {Promise<void>}
   */
  const fetchOptionChoices = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      console.log('Fetching option choices...');
      const response = await api.get('/option-choices', {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
        params: { include: 'optionGroup' }, // Include related option group
      });
      setOptionChoices(response.data.data);
      logger.info('Fetched option choices successfully.');
      console.log('Fetched option choices:', response.data.data);
    } catch (err) {
      logger.error('Failed to fetch option choices:', err);
      setError('Failed to fetch option choices');
      toast.error('Failed to fetch option choices.');
    } finally {
      setLoading(false);
    }
  }, [token]);

  /**
   * Creates a new option choice and associates it with an option group.
   * @param {string} name - The name of the option choice.
   * @param {number} additionalPrice - The additional price for the option choice.
   * @param {number} optionGroupId - The ID of the associated option group.
   * @returns {Promise<Object|null>} - The newly created option choice or null if failed.
   */
  const createOptionChoice = useCallback(
    async (name, additionalPrice, optionGroupId) => {
      if (!name || name.trim() === '') {
        setError('Option choice name is required.');
        toast.error('Option choice name is required.');
        return null;
      }

      if (
        additionalPrice === undefined ||
        additionalPrice === null ||
        isNaN(additionalPrice)
      ) {
        setError('Valid additional price is required.');
        toast.error('Valid additional price is required.');
        return null;
      }

      if (!optionGroupId) {
        setError('Option group ID is required.');
        toast.error('Option group ID is required.');
        return null;
      }

      if (!token) {
        logger.warn('createOptionChoice called without a valid token.');
        setError('Authentication required to create option choice.');
        toast.error('You must be logged in to create an option choice.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log('Creating option choice:', { name, additionalPrice, optionGroupId });
        const response = await api.post(
          '/option-choices',
          { name, additionalPrice, optionGroupId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const newOptionChoice = response.data.data;

        // Update optionChoices state
        setOptionChoices((prevChoices) => [...prevChoices, newOptionChoice]);

        // Also update the relevant optionGroup's optionChoices
        setOptionGroups((prevGroups) =>
          prevGroups.map((group) =>
            group.id === optionGroupId
              ? {
                  ...group,
                  optionChoices: [...(group.optionChoices || []), newOptionChoice],
                }
              : group
          )
        );

        logger.info('Option choice created and associated with option group successfully.');
        toast.success('Option choice created successfully!');
        console.log('Created option choice:', newOptionChoice);
        return newOptionChoice;
      } catch (err) {
        logger.error('Failed to create option choice:', err);
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error('Failed to create option choice.');
        }
        setError('Failed to create option choice');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Updates an existing option choice.
   * @param {number} choiceId - The ID of the option choice to update.
   * @param {Object} updates - The updates to apply (e.g., { name, additionalPrice }).
   * @returns {Promise<Object|null>} - The updated option choice or null if failed.
   */
  const updateOptionChoice = useCallback(
    async (choiceId, updates) => {
      if (!choiceId || !updates) {
        setError('Option choice ID and updates are required.');
        toast.error('Option choice ID and updates are required.');
        return null;
      }

      if (!token) {
        logger.warn('updateOptionChoice called without a valid token.');
        setError('Authentication required to update option choice.');
        toast.error('You must be logged in to update an option choice.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Updating option choice ID ${choiceId} with:`, updates);
        const response = await api.put(`/option-choices/${choiceId}`, updates, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const updatedChoice = response.data.data;
        setOptionChoices((prevChoices) =>
          prevChoices.map((choice) =>
            choice.id === choiceId ? updatedChoice : choice
          )
        );
        // Also update the relevant optionGroup's optionChoices
        setOptionGroups((prevGroups) =>
          prevGroups.map((group) => {
            if (group.optionChoices) {
              return {
                ...group,
                optionChoices: group.optionChoices.map((choice) =>
                  choice.id === choiceId ? updatedChoice : choice
                ),
              };
            }
            return group;
          })
        );

        logger.info(`Option choice ID ${choiceId} updated successfully.`);
        toast.success('Option choice updated successfully!');
        console.log('Updated option choice:', updatedChoice);
        return updatedChoice;
      } catch (err) {
        logger.error(`Failed to update option choice ID ${choiceId}:`, err);
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error('Failed to update option choice.');
        }
        setError('Failed to update option choice');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Deletes an option choice.
   * @param {number} choiceId - The ID of the option choice to delete.
   * @returns {Promise<void>}
   */
  const deleteOptionChoice = useCallback(
    async (choiceId) => {
      if (!choiceId) {
        setError('Option choice ID is required.');
        toast.error('Option choice ID is required.');
        return;
      }

      if (!token) {
        logger.warn('deleteOptionChoice called without a valid token.');
        setError('Authentication required to delete option choice.');
        toast.error('You must be logged in to delete an option choice.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Deleting option choice ID ${choiceId}`);
        await api.delete(`/option-choices/${choiceId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOptionChoices((prevChoices) =>
          prevChoices.filter((choice) => choice.id !== choiceId)
        );
        // Also remove from the relevant optionGroup's optionChoices
        setOptionGroups((prevGroups) =>
          prevGroups.map((group) => {
            if (group.optionChoices) {
              return {
                ...group,
                optionChoices: group.optionChoices.filter(
                  (choice) => choice.id !== choiceId
                ),
              };
            }
            return group;
          })
        );

        logger.info(`Option choice ID ${choiceId} deleted successfully.`);
        toast.success('Option choice deleted successfully!');
      } catch (err) {
        logger.error(`Failed to delete option choice ID ${choiceId}:`, err);
        setError('Failed to delete option choice');
        toast.error('Failed to delete option choice.');
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  // ---------------------------
  // ======== Fetch Functions ===
  // ---------------------------

  /**
   * Fetches all product categories.
   * Now uses the unified '/categories' endpoint.
   * @returns {Promise<void>} - Resolves when fetching is complete.
   */
  const fetchCategories = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      console.log('Fetching categories...');
      const response = await api.get('/categories', {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
        params: { include: 'optionGroups,optionChoices' },
      });
      setCategories(response.data.data);
      logger.info('Fetched categories successfully.');
      console.log('Fetched categories:', response.data.data);
    } catch (err) {
      logger.error('Failed to fetch categories:', err);
      setError('Failed to fetch categories');
      toast.error('Failed to fetch categories.');
    } finally {
      setLoading(false);
    }
  }, [token]);

  /**
   * Fetches all ingredients.
   * Now uses the unified '/ingredients' endpoint.
   * @returns {Promise<void>} - Resolves when fetching is complete.
   */
  const fetchIngredients = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      console.log('Fetching ingredients...');
      const response = await api.get('/ingredients', {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
        params: { include: 'optionGroups,optionChoices' },
      });
      setIngredients(response.data.data);
      logger.info('Fetched ingredients successfully.');
      console.log('Fetched ingredients:', response.data.data);
    } catch (err) {
      logger.error('Failed to fetch ingredients:', err);
      setError('Failed to fetch ingredients');
      toast.error('Failed to fetch ingredients.');
    } finally {
      setLoading(false);
    }
  }, [token]);

  /**
   * Fetches all stores.
   * Accessible by both authenticated and unauthenticated users.
   * Now uses the unified '/stores' endpoint.
   * @returns {Promise<void>} - Resolves when fetching is complete.
   */
  const fetchStores = useCallback(async () => {
    setLoadingStores(true);
    setStoreError(null);
    try {
      console.log('Fetching all stores...');
      const response = await api.get('/stores', {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
        params: { include: 'optionGroups,optionChoices' }, // Include options
      });
      setStores(response.data.data);
      logger.info('Fetched stores successfully.');
      console.log('Fetched stores:', response.data.data);
    } catch (err) {
      logger.error('Failed to fetch stores:', err);
      setStoreError('Failed to fetch stores');
      toast.error('Failed to fetch stores.');
    } finally {
      setLoadingStores(false);
    }
  }, [token]);

  /**
   * Fetches all products with optional parameters.
   * Now uses the unified '/products' endpoint.
   * @param {Object} params - The query parameters for fetching products.
   * @returns {Promise<void>} - Resolves when fetching is complete.
   */
  const fetchProducts = useCallback(
    async (
      params = {
        page: 1,
        limit: 10,
        storeId: null,
        categoryId: null,
        sortBy: 'createdAt',
        order: 'desc',
      }
    ) => {
      setLoading(true);
      setError(null);
      try {
        // Destructure params and set default values
        const {
          storeId,
          categoryId,
          sortBy = 'createdAt', // Default sortBy
          order = 'desc', // Default order
          page = 1,
          limit = 10,
        } = params;

        // Build the query object conditionally
        const queryParams = {
          page,
          limit,
          sortBy,
          order,
          include: 'ingredients,optionGroups,optionChoices', // Include options
        };

        if (storeId) {
          queryParams.storeId = storeId;
        }

        if (categoryId && Number.isInteger(Number(categoryId))) {
          queryParams.categoryId = Number(categoryId);
        }

        console.log('Fetching products with params:', queryParams);

        let response;
        if (token) {
          // Authenticated fetch
          response = await api.get('/products', {
            params: queryParams,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        } else {
          // Unauthenticated fetch
          response = await api.get('/products', {
            params: queryParams,
          });
        }

        const fetchedProducts = response.data.data;

        setProducts(fetchedProducts); // Products now include optionGroups and optionChoices
        setPagination(response.data.pagination);
        logger.info(`Fetched products successfully. Page: ${page}`);
        console.log(`Fetched ${fetchedProducts.length} products for page ${page}`);
      } catch (err) {
        logger.error('Failed to fetch products:', err);
        setError('Failed to fetch products');
        toast.error('Failed to fetch products.');
      } finally {
        setLoading(false);
      }
    },
    [token] // Dependency: token
  );

  /**
   * Fetches store details by store ID.
   * Now uses the unified '/stores/:id' endpoint.
   * @param {number|string} storeId - The ID of the store to fetch details for.
   * @returns {Promise<Object|null>} - The store details or null if failed.
   */
  const fetchStoreDetails = useCallback(
    async (storeId) => {
      if (!storeId) {
        setStoreError('Store ID is required to fetch store details.');
        toast.error('Store ID is required to fetch store details.');
        return null;
      }

      setLoadingStores(true);
      setStoreError(null);
      try {
        let response;
        if (token) {
          // Authenticated fetch
          console.log(`Fetching store details for store ID ${storeId} (authenticated)`);
          response = await api.get(`/stores/${storeId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: { include: 'products,optionGroups,optionChoices' }, // Include options
          });
        } else {
          // Unauthenticated fetch
          console.log(`Fetching store details for store ID ${storeId} (unauthenticated)`);
          response = await api.get(`/stores/${storeId}`, {
            params: { include: 'products,optionGroups,optionChoices' }, // Include options
          });
        }

        const storeDetails = response.data.data;

        setStores((prevStores) =>
          prevStores.map((store) =>
            store.id === storeId ? storeDetails : store
          )
        );
        logger.info(`Fetched details for Store ID ${storeId}.`);
        console.log('Fetched store details:', storeDetails);
        return storeDetails;
      } catch (err) {
        logger.error(`Failed to fetch store details for Store ID ${storeId}:`, err);
        setStoreError('Failed to fetch store details.');
        toast.error('Failed to fetch store details.');
        return null;
      } finally {
        setLoadingStores(false);
      }
    },
    [token]
  );

  /**
   * Fetches products for a specific store.
   * Now uses the unified '/stores/:storeId/products' endpoint.
   * @param {number|string} storeId - The ID of the store to fetch products for.
   * @param {Object} params - Optional query parameters (e.g., pagination, sorting).
   * @returns {Promise<Array|null>} - Array of products or null if failed.
   */
  const fetchStoreProducts = useCallback(
    async (storeId, params = {}) => {
      if (!storeId) {
        setStoreError('Store ID is required to fetch store products.');
        toast.error('Store ID is required to fetch store products.');
        return null;
      }

      setLoadingStores(true);
      setStoreError(null);
      try {
        let response;
        if (token) {
          // Authenticated fetch
          console.log(`Fetching products for store ID ${storeId} (authenticated) with params:`, params);
          response = await api.get(`/stores/${storeId}/products`, {
            params: { ...params, include: 'optionGroups,optionChoices' }, // Include options
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        } else {
          // Unauthenticated fetch
          console.log(`Fetching products for store ID ${storeId} (unauthenticated) with params:`, params);
          response = await api.get(`/stores/${storeId}/products`, {
            params: { ...params, include: 'optionGroups,optionChoices' }, // Include options
          });
        }

        const storeProducts = response.data.data;

        setProducts(storeProducts);
        logger.info('Fetched store products successfully.');
        console.log('Fetched store products:', storeProducts);
        return storeProducts;
      } catch (err) {
        logger.error(`Failed to fetch products for Store ID ${storeId}:`, err);
        setStoreError('Failed to fetch store products.');
        toast.error('Failed to fetch store products.');
        return null;
      } finally {
        setLoadingStores(false);
      }
    },
    [token]
  );

  /**
   * Fetches a single product by its ID, including ingredients and options.
   * Now uses the unified '/products/:id' endpoint.
   * @param {number|string} id - The ID of the product.
   * @returns {Promise<Object|null>} - The product data or null if failed.
   */
  const fetchProductWithIngredientsById = useCallback(
    async (id) => {
      console.log('fetchProductWithIngredientsById called with id:', id);
      if (!id) {
        setError('Product ID is required');
        toast.error('Product ID is required.');
        return null;
      }

      // Add type check and extract id if necessary
      if (typeof id === 'object' && id !== null && 'id' in id) {
        console.log('Received object, extracting id:', id.id);
        id = id.id;
      } else if (typeof id !== 'string' && typeof id !== 'number') {
        setError('Invalid Product ID');
        toast.error('Invalid Product ID.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        let response;
        if (token) {
          // Authenticated fetch
          logger.info(`Fetching product details for ID: ${id} (authenticated)`);
          console.log(`Making API call to fetch product with ID: ${id} (authenticated)`);
          response = await api.get(`/products/${id}`, {
            params: { include: 'ingredients,optionGroups,optionChoices' }, // Include options
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        } else {
          // Unauthenticated fetch
          logger.info(`Fetching product details for ID: ${id} (unauthenticated)`);
          console.log(`Making API call to fetch product with ID: ${id} (unauthenticated)`);
          response = await api.get(`/products/${id}`, {
            params: { include: 'ingredients,optionGroups,optionChoices' }, // Include options
          });
        }

        const product = response.data.data;

        setProductDetails(product);
        logger.info(`Fetched product details for ID: ${id}`);
        console.log('Fetched product details:', product);
        return product;
      } catch (err) {
        logger.error(`Failed to fetch product with ID ${id}:`, err);
        setError('Failed to fetch product details');
        toast.error('Failed to fetch product details.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token] // Added token to dependencies
  );

  /**
   * Alias fetchProductWithIngredientsById to getProductWithIngredients
   */
  const getProductWithIngredients = fetchProductWithIngredientsById;

  /**
   * Creates a new category.
   * Now uses the unified '/categories' endpoint.
   * @param {string} name - The name of the new category.
   * @returns {Promise<Object|null>} - The newly created category or null if failed.
   */
  const createCategory = useCallback(
    async (name) => {
      if (!name || name.trim() === '') {
        setError('Category name is required');
        toast.error('Category name is required.');
        return null;
      }

      if (!token) {
        logger.warn('createCategory called without a valid token.');
        setError('Authentication required to create category.');
        toast.error('You must be logged in to create a category.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log('Creating category with name:', name);
        const response = await api.post(
          '/categories',
          { name },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const newCategory = response.data.data;

        setCategories((prevCategories) => [...prevCategories, newCategory]);
        logger.info('Category created successfully.');
        toast.success('Category created successfully!');
        console.log('Created category:', newCategory);
        return {
          label: newCategory.name,
          value: newCategory.id,
        }; // Return in react-select format
      } catch (err) {
        logger.error('Failed to create category:', err);
        setError('Failed to create category');
        toast.error('Failed to create category.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Creates a new ingredient.
   * Now uses the unified '/ingredients' endpoint.
   * @param {Object} data - The data for the new ingredient { name: string, price: string }.
   * @returns {Promise<Object|null>} - The newly created ingredient or null if failed.
   */
  const createIngredient = useCallback(
    async (data) => {
      const { name, price } = data;

      if (!name || name.trim() === '') {
        setError('Ingredient name is required');
        toast.error('Ingredient name is required.');
        return null;
      }

      if (!price || parseFloat(price) <= 0) {
        setError('Ingredient price must be a positive number');
        toast.error('Ingredient price must be a positive number.');
        return null;
      }

      if (!token) {
        logger.warn('createIngredient called without a valid token.');
        setError('Authentication required to create ingredient.');
        toast.error('You must be logged in to create an ingredient.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log('Creating ingredient with data:', data);
        const response = await api.post(
          '/ingredients',
          { name, price },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const newIngredient = response.data.data;
        setIngredients((prevIngredients) => [...prevIngredients, newIngredient]);
        logger.info('Ingredient created successfully.');
        toast.success('Ingredient created successfully!');
        console.log('Created ingredient:', newIngredient);
        return {
          label: newIngredient.name,
          value: newIngredient.id,
        }; // Return in react-select format
      } catch (err) {
        logger.error('Failed to create ingredient:', err);
        // Handle Sequelize Unique Constraint Error for name
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        } else if (
          err.response &&
          err.response.data &&
          err.response.data.errors
        ) {
          // Handle validation errors
          err.response.data.errors.forEach((error) => {
            toast.error(`${error.path}: ${error.msg}`);
          });
        } else {
          toast.error('Failed to create ingredient.');
        }
        setError('Failed to create ingredient');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Updates an existing ingredient.
   * Now uses the unified '/ingredients/:id' endpoint.
   * @param {number} ingredientId - The ID of the ingredient to update.
   * @param {Object} updates - The updates to apply.
   * @returns {Promise<Object|null>} - The updated ingredient or null if failed.
   */
  const updateIngredient = useCallback(
    async (ingredientId, updates) => {
      if (!ingredientId || !updates) {
        setError('Ingredient ID and updates are required.');
        toast.error('Ingredient ID and updates are required.');
        return null;
      }

      if (!token) {
        logger.warn('updateIngredient called without a valid token.');
        setError('Authentication required to update ingredient.');
        toast.error('You must be logged in to update an ingredient.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Updating ingredient ID ${ingredientId} with updates:`, updates);
        const response = await api.put(`/ingredients/${ingredientId}`, updates, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const updatedIngredient = response.data.data;

        setIngredients((prevIngredients) =>
          prevIngredients.map((ingredient) =>
            ingredient.id === ingredientId ? updatedIngredient : ingredient
          )
        );
        logger.info(`Ingredient ID ${ingredientId} updated successfully.`);
        toast.success('Ingredient updated successfully!');
        console.log('Updated ingredient:', updatedIngredient);
        return updatedIngredient; // Return updated ingredient
      } catch (err) {
        logger.error(`Failed to update ingredient ID ${ingredientId}:`, err);
        setError('Failed to update ingredient');
        toast.error('Failed to update ingredient.');
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Deletes an ingredient.
   * Now uses the unified '/ingredients/:id' endpoint.
   * @param {number} ingredientId - The ID of the ingredient to delete.
   * @returns {Promise<void>} - Resolves when deletion is complete.
   */
  const deleteIngredient = useCallback(
    async (ingredientId) => {
      if (!ingredientId) {
        setError('Ingredient ID is required.');
        toast.error('Ingredient ID is required.');
        return;
      }

      if (!token) {
        logger.warn('deleteIngredient called without a valid token.');
        setError('Authentication required to delete ingredient.');
        toast.error('You must be logged in to delete an ingredient.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Deleting ingredient ID ${ingredientId}`);
        await api.delete(`/ingredients/${ingredientId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setIngredients((prevIngredients) =>
          prevIngredients.filter((ingredient) => ingredient.id !== ingredientId)
        );
        logger.info(`Ingredient ID ${ingredientId} deleted successfully.`);
        toast.success('Ingredient deleted successfully!');
      } catch (err) {
        logger.error(`Failed to delete ingredient ID ${ingredientId}:`, err);
        setError('Failed to delete ingredient');
        toast.error('Failed to delete ingredient.');
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Creates a new product.
   * Now uses the unified '/products' endpoint.
   * @param {FormData} productData - The form data of the product to create.
   * @returns {Promise<Object|null>} - The newly created product or null if failed.
   */
  const createProduct = useCallback(
    async (productData) => {
      if (!productData) {
        setError('Product data is required');
        toast.error('Product data is required.');
        return null;
      }

      if (!token) {
        logger.warn('createProduct called without a valid token.');
        setError('Authentication required to create product.');
        toast.error('You must be logged in to create a product.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log('Creating product with FormData:', productData);
        const response = await api.post('/products', productData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        });
        const newProduct = response.data.data;

        setProducts((prevProducts) => [newProduct, ...prevProducts]);
        logger.info('Product created successfully.');
        toast.success('Product created successfully!');
        console.log('Created product:', newProduct);
        return newProduct;
      } catch (err) {
        logger.error('Failed to create product:', err);
        setError('Failed to create product');
        toast.error('Failed to create product.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Updates an existing product.
   * Now uses the unified '/products/:id' endpoint.
   * @param {number} productId - The ID of the product to update.
   * @param {FormData} updates - The form data containing updates.
   * @returns {Promise<Object|null>} - The updated product or null if failed.
   */
  const updateProduct = useCallback(
    async (productId, updates) => {
      if (!productId || !updates) {
        setError('Product ID and updates are required.');
        toast.error('Product ID and updates are required.');
        return null;
      }

      if (!token) {
        logger.warn('updateProduct called without a valid token.');
        setError('Authentication required to update product.');
        toast.error('You must be logged in to update a product.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Updating product ID ${productId} with FormData:`, updates);
        const response = await api.put(`/products/${productId}`, updates, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        });
        const updatedProduct = response.data.data;

        setProducts((prevProducts) =>
          prevProducts.map((product) =>
            product.id === productId ? updatedProduct : product
          )
        );
        logger.info(`Product ID ${productId} updated successfully.`);
        toast.success('Product updated successfully!');
        console.log('Updated product:', updatedProduct);
        return updatedProduct; // Return updated product
      } catch (err) {
        logger.error(`Failed to update product ID ${productId}:`, err);
        setError('Failed to update product');
        toast.error('Failed to update product.');
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Deletes a product.
   * Now uses the unified '/products/:id' endpoint.
   * @param {number} productId - The ID of the product to delete.
   * @returns {Promise<void>} - Resolves when deletion is complete.
   */
  const deleteProduct = useCallback(
    async (productId) => {
      if (!productId) {
        setError('Product ID is required.');
        toast.error('Product ID is required.');
        return;
      }

      if (!token) {
        logger.warn('deleteProduct called without a valid token.');
        setError('Authentication required to delete product.');
        toast.error('You must be logged in to delete a product.');
        return;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Deleting product ID ${productId}`);
        await api.delete(`/products/${productId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProducts((prevProducts) =>
          prevProducts.filter((product) => product.id !== productId)
        );
        logger.info(`Product ID ${productId} deleted successfully.`);
        toast.success('Product deleted successfully!');
      } catch (err) {
        logger.error(`Failed to delete product ID ${productId}:`, err);
        setError('Failed to delete product');
        toast.error('Failed to delete product.');
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Fetches reviews for a specific product.
   * Now uses the unified '/products/:id/reviews' endpoint.
   * @param {number} productId - The ID of the product to find related products for.
   * @returns {Promise<void>} - Resolves when fetching is complete.
   */
  const fetchProductReviews = useCallback(async (productId) => {
    if (!productId) {
      setError('Product ID is required to fetch reviews.');
      toast.error('Product ID is required to fetch reviews.');
      return null;
    }

    if (!token) {
      logger.warn('fetchProductReviews called without a valid token.');
      setError('Authentication required to fetch reviews.');
      toast.error('You must be logged in to fetch reviews.');
      return null;
    }

    setLoading(true);
    setError(null);
    try {
      console.log(`Fetching reviews for product ID ${productId}`);
      const response = await api.get(`/products/${productId}/reviews`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setReviews(response.data.data);
      logger.info(`Fetched reviews for Product ID ${productId}.`);
      console.log('Fetched reviews:', response.data.data);
    } catch (err) {
      logger.error(`Failed to fetch reviews for Product ID ${productId}:`, err);
      setError('Failed to fetch reviews');
      toast.error('Failed to fetch reviews.');
    } finally {
      setLoading(false);
    }
  }, [token]);

  /**
   * Submits a review for a product.
   * Now uses the unified '/products/:id/reviews' endpoint.
   * @param {number} productId - The ID of the product.
   * @param {number} userId - The ID of the user submitting the review.
   * @param {Object} reviewData - The review data { rating: number, comment: string }.
   * @returns {Promise<Object|null>} - The submitted review data or null if failed.
   */
  const submitReview = useCallback(
    async (productId, userId, reviewData) => {
      if (!productId || !userId || !reviewData) {
        setError('Product ID, User ID, and review data are required.');
        toast.error('Product ID, User ID, and review data are required.');
        return null;
      }

      if (!token) {
        logger.warn('submitReview called without a valid token.');
        setError('Authentication required to submit a review.');
        toast.error('You must be logged in to submit a review.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(
          `Submitting review for product ID ${productId} by user ID ${userId}`,
          reviewData
        );
        const response = await api.post(`/products/${productId}/reviews`, reviewData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // Optionally, refresh the reviews
        await fetchProductReviews(productId);
        logger.info(
          `Review submitted for Product ID ${productId} by User ID ${userId}.`
        );
        toast.success('Review submitted successfully!');
        console.log('Submitted review:', response.data.data);
        return response.data.data;
      } catch (err) {
        logger.error(`Failed to submit review for Product ID ${productId}:`, err);
        setError('Failed to submit review');
        toast.error('Failed to submit review.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token, fetchProductReviews]
  );

  /**
   * Checks if a user has purchased a specific product with Throttle
   * Now uses the unified '/users/:userId/purchases/:productId' endpoint.
   * @param {number} productId - The ID of the product.
   * @param {number} userId - The ID of the user.
   * @returns {Promise<boolean>} - True if the user has purchased the product, else false.
   */
  const checkUserPurchase = useMemo(
    () =>
      throttle(async (productId, userId) => {
        if (!productId || !userId) {
          setError('Product ID and User ID are required to check purchase.');
          toast.error('Product ID and User ID are required to check purchase.');
          return false;
        }

        if (!token) {
          logger.warn('checkUserPurchase called without a valid token.');
          setError('Authentication required to check purchase.');
          toast.error('You must be logged in to check purchase.');
          return false;
        }

        setLoading(true);
        setError(null);
        try {
          console.log(`Checking if user ID ${userId} has purchased product ID ${productId}`);
          const response = await api.get(`/users/${userId}/purchases/${productId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const hasPurchased = response.data.data.hasPurchased;
          logger.info(
            `User ID ${userId} hasPurchased for Product ID ${productId}: ${hasPurchased}`
          );
          console.log(`User has purchased: ${hasPurchased}`);
          return hasPurchased;
        } catch (err) {
          logger.error(
            `Failed to check purchase for User ID ${userId} and Product ID ${productId}:`,
            err
          );
          setError('Failed to check purchase status');
          toast.error('Failed to check purchase status.');
          return false;
        } finally {
          setLoading(false);
        }
      }, 1000), // 1-second throttle delay
    [token]
  );

  /**
   * Fetches a user's review for a specific product.
   * Now uses the unified '/products/:productId/reviews/user/:userId' endpoint.
   * @param {number} productId - The ID of the product.
   * @param {number} userId - The ID of the user.
   * @returns {Promise<Object|null>} - The user's review or null if none exists.
   */
  const fetchUserReview = useCallback(
    async (productId, userId) => {
      if (!productId || !userId) {
        setError('Product ID and User ID are required to fetch user review.');
        toast.error('Product ID and User ID are required to fetch user review.');
        return null;
      }

      if (!token) {
        logger.warn('fetchUserReview called without a valid token.');
        setError('Authentication required to fetch user review.');
        toast.error('You must be logged in to fetch your review.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(
          `Fetching user review for user ID ${userId} and product ID ${productId}`
        );
        const response = await api.get(
          `/products/${productId}/reviews/user/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        logger.info(
          `Fetched user review for User ID ${userId} and Product ID ${productId}.`
        );
        console.log('Fetched user review:', response.data.data);
        return response.data.data;
      } catch (err) {
        logger.error(
          `Failed to fetch user review for User ID ${userId} and Product ID ${productId}:`,
          err
        );
        setError('Failed to fetch user review');
        toast.error('Failed to fetch your review.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Adds an item to the user's wishlist.
   * Now uses the unified '/users/:userId/wishlist' endpoint.
   * @param {number} productId - The ID of the product to add.
   * @returns {Promise<void>} - Resolves when the item is added.
   */
  const addItemToWishlist = useCallback(
    async (productId) => {
      if (!productId) {
        setError('Product ID is required to add to wishlist.');
        toast.error('Product ID is required to add to wishlist.');
        return;
      }

      if (!token) {
        logger.warn('addItemToWishlist called without a valid token.');
        setError('Authentication required to add to wishlist.');
        toast.error('You must be logged in to add items to wishlist.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(
          `Adding product ID ${productId} to wishlist for user ID ${user.id}`
        );
        await api.post(
          `/users/${user.id}/wishlist`,
          { productId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        logger.info(
          `Product ID ${productId} added to User ID ${user.id}'s wishlist.`
        );
        toast.success('Product added to wishlist!');
        console.log('Added to wishlist successfully');
      } catch (err) {
        logger.error(`Failed to add Product ID ${productId} to wishlist:`, err);
        setError('Failed to add to wishlist');
        toast.error('Failed to add to wishlist.');
      } finally {
        setLoading(false);
      }
    },
    [token, user]
  );

  /**
   * Fetches detailed information about a single order.
   * Now uses the unified '/orders/:orderId' endpoint.
   * @param {number} orderId - The ID of the order.
   * @returns {Promise<Object|null>} - The detailed information of the order.
   */
  const fetchOrderDetails = useCallback(
    async (orderId) => {
      if (!orderId) {
        setError('Order ID is required to fetch details');
        toast.error('Order ID is required to fetch details.');
        return null;
      }

      if (!token) {
        logger.warn('fetchOrderDetails called without a valid token.');
        setError('Authentication required to fetch order details.');
        toast.error('You must be logged in to fetch order details.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Fetching details for order ID ${orderId}`);
        const response = await api.get(`/orders/${orderId}`, {
          params: { include: 'optionGroups,optionChoices' }, // Include options
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const detailedOrder = response.data.data;

        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.id === orderId ? detailedOrder : order
          )
        );
        logger.info(`Fetched details for Order ID ${orderId}:`, detailedOrder);
        console.log('Fetched detailed order:', detailedOrder);
        return detailedOrder;
      } catch (err) {
        logger.error(`Failed to fetch details for Order ID ${orderId}:`, err);
        setError('Failed to fetch order details');
        toast.error('Failed to fetch order details.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Fetches stores near a given location (latitude, longitude).
   * Now uses the unified '/stores/nearby' endpoint.
   * @param {Object} location - { latitude, longitude }
   * @param {number} radiusInMiles - Radius in miles (default: 5 miles)
   * @returns {Promise<Array|null>} - Array of stores or null if failed
   */
  const fetchStoresByLocation = useCallback(
    async (location, radiusInMiles = 5) => {
      if (
        !location ||
        typeof location.latitude !== 'number' ||
        typeof location.longitude !== 'number'
      ) {
        setStoreError('Valid latitude and longitude are required.');
        toast.error('Valid latitude and longitude are required.');
        return null;
      }

      // **Convert radius from miles to kilometers**
      const radiusInKm = milesToKilometers(radiusInMiles);

      setLoadingStores(true);
      setStoreError(null);
      try {
        let response;
        if (token) {
          // Authenticated fetch
          console.log(
            `Fetching stores near location:`,
            location,
            `within ${radiusInMiles} miles (${radiusInKm.toFixed(2)} km) (authenticated)`
          );
          response = await api.get('/stores/nearby', {
            params: { ...location, radius: radiusInKm, include: 'optionGroups,optionChoices' }, // Include options
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        } else {
          // Unauthenticated fetch
          console.log(
            `Fetching stores near location:`,
            location,
            `within ${radiusInMiles} miles (${radiusInKm.toFixed(2)} km) (unauthenticated)`
          );
          response = await api.get('/stores/nearby', {
            params: { ...location, radius: radiusInKm, include: 'optionGroups,optionChoices' }, // Include options
          });
        }

        const fetchedStores = response.data.data;

        if (fetchedStores.length === 0) {
          toast.info(`No stores found within a ${radiusInMiles}-mile radius.`);
          setNearestStore(null);
          setStores([]);
          console.log(`No stores found within a ${radiusInMiles}-mile radius.`);
          return null;
        }

        setStores(fetchedStores);
        logger.info('Fetched nearby stores successfully.', fetchedStores);
        console.log('Fetched nearby stores:', fetchedStores);
        return fetchedStores;
      } catch (err) {
        logger.error('Failed to fetch nearby stores:', err);
        setStoreError('Failed to fetch nearby stores');
        toast.error('Failed to fetch nearby stores.');
        return null;
      } finally {
        setLoadingStores(false);
      }
    },
    [token]
  );

  /**
   * Fetches the nearest store to a given location within a specified radius.
   * Now uses the unified '/stores/nearby' endpoint.
   * @param {Object} location - { latitude, longitude }
   * @param {number} radiusInMiles - Radius in miles (default: 5 miles)
   * @returns {Promise<Object|null>} - The nearest store or null if none found within radius
   */
  const fetchNearestStore = useCallback(
    async (location, radiusInMiles = 5) => {
      if (
        !location ||
        typeof location.latitude !== 'number' ||
        typeof location.longitude !== 'number'
      ) {
        setStoreError('Valid latitude and longitude are required.');
        toast.error('Valid latitude and longitude are required.');
        return null;
      }

      // **Convert radius from miles to kilometers**
      const radiusInKm = milesToKilometers(radiusInMiles);

      setLoadingStores(true);
      setStoreError(null);
      try {
        let response;
        if (token) {
          // Authenticated fetch
          console.log(
            `Fetching nearest store near location:`,
            location,
            `within ${radiusInMiles} miles (${radiusInKm.toFixed(2)} km) (authenticated)`
          );
          response = await api.get('/stores/nearby', {
            params: { ...location, radius: radiusInKm, include: 'optionGroups,optionChoices' }, // Include options
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        } else {
          // Unauthenticated fetch
          console.log(
            `Fetching nearest store near location:`,
            location,
            `within ${radiusInMiles} miles (${radiusInKm.toFixed(2)} km) (unauthenticated)`
          );
          response = await api.get('/stores/nearby', {
            params: { ...location, radius: radiusInKm, include: 'optionGroups,optionChoices' }, // Include options
          });
        }

        const fetchedStores = response.data.data;

        if (fetchedStores.length === 0) {
          toast.info(`No stores found within a ${radiusInMiles}-mile radius.`);
          setNearestStore(null);
          setStores([]);
          console.log(`No stores found within a ${radiusInMiles}-mile radius.`);
          return null;
        }

        // Assuming the backend orders stores by distance ascending
        const nearest = fetchedStores[0];
        setNearestStore(nearest);
        setStores([nearest]); // Only keep the nearest store
        logger.info('Fetched nearest store successfully.', nearest);
        console.log('Fetched nearest store:', nearest);
        return nearest;
      } catch (err) {
        logger.error('Failed to fetch nearest store:', err);
        setStoreError('Failed to fetch nearest store');
        toast.error('Failed to fetch nearest store.');
        return null;
      } finally {
        setLoadingStores(false);
      }
    },
    [token]
  );

  // Removed fetchNearestStorePublic as it's no longer needed with unified endpoints

  /**
   * Fetches related products based on a specific product ID.
   * Now uses the unified '/products/related/:productId' endpoint.
   * @param {number} productId - The ID of the product to find related products for.
   * @returns {Promise<void>} - Resolves when fetching is complete.
   */
  const fetchRelatedProducts = useCallback(
    async (productId) => {
      if (!productId) {
        setError('Product ID is required to fetch related products');
        toast.error('Product ID is required to fetch related products.');
        return;
      }

      if (!token) {
        logger.warn('fetchRelatedProducts called without a valid token.');
        setError('Authentication required to fetch related products.');
        toast.error('You must be logged in to fetch related products.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Fetching related products for product ID ${productId}`);
        const response = await api.get(`/products/related/${productId}`, {
          params: { include: 'optionGroups,optionChoices' }, // Include options
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const fetchedRelatedProducts = response.data.data;

        setRelatedProducts(fetchedRelatedProducts); // Products now include optionGroups and optionChoices
        logger.info(`Fetched related products for product ID: ${productId}`);
        console.log('Fetched related products:', fetchedRelatedProducts);
      } catch (err) {
        logger.error(
          `Failed to fetch related products for product ID ${productId}:`,
          err
        );
        setError('Failed to fetch related products');
        toast.error('Failed to fetch related products.');
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Fetches payment methods.
   * Now uses the unified '/payment-methods' endpoint.
   * @returns {Promise<void>} - Resolves when fetching is complete.
   */
  const fetchPaymentMethods = useCallback(async () => {
    if (!token) {
      logger.warn('fetchPaymentMethods called without a valid token.');
      setError('Authentication required to fetch payment methods.');
      toast.error('You must be logged in to fetch payment methods.');
      return;
    }

    setLoading(true);
    setError(null);
    try {
      console.log('Fetching payment methods...');
      const response = await api.get('/payment-methods', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPaymentMethods(response.data.data);
      logger.info('Fetched payment methods successfully.');
      console.log('Fetched payment methods:', response.data.data);
    } catch (err) {
      logger.error('Failed to fetch payment methods:', err);
      setError('Failed to fetch payment methods');
      toast.error('Failed to fetch payment methods.');
    } finally {
      setLoading(false);
    }
  }, [token]);

  /**
   * Fetches payments.
   * Now uses the unified '/payments' endpoint.
   * @returns {Promise<void>} - Resolves when fetching is complete.
   */
  const fetchPayments = useCallback(async () => {
    if (!token) {
      logger.warn('fetchPayments called without a valid token.');
      setError('Authentication required to fetch payments.');
      toast.error('You must be logged in to fetch payments.');
      return;
    }

    setLoading(true);
    setError(null);
    try {
      console.log('Fetching payments...');
      const response = await api.get('/payments', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPayments(response.data.data);
      logger.info('Fetched payments successfully.');
      console.log('Fetched payments:', response.data.data);
    } catch (err) {
      logger.error('Failed to fetch payments:', err);
      setError('Failed to fetch payments');
      toast.error('Failed to fetch payments.');
    } finally {
      setLoading(false);
    }
  }, [token]);

  /**
   * Places an order.
   * Now uses the unified '/orders' endpoint.
   * @param {Object} orderData - The order data containing cart items, user info, addresses, etc.
   * @returns {Promise<Object|null>} - The placed order or null if failed.
   */
  const placeOrder = useCallback(
    async (orderData) => {
      if (!orderData) {
        setError('Order data is required');
        toast.error('Order data is required.');
        return null;
      }

      if (!token) {
        logger.warn('placeOrder called without a valid token.');
        setError('Authentication required to place order.');
        toast.error('You must be logged in to place an order.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log('Placing order with data:', orderData);
        const response = await api.post('/orders', orderData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        const placedOrder = response.data.data;

        setOrders((prevOrders) => [placedOrder, ...prevOrders]);
        logger.info(`Order placed successfully: Order ID ${placedOrder.id}`);
        toast.success('Order placed successfully!');
        console.log('Placed order:', placedOrder);
        return placedOrder;
      } catch (err) {
        logger.error('Failed to place order:', err);
        setError('Failed to place order');
        toast.error('Failed to place order.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Fetches all orders for the authenticated user or admin.
   * Now accepts query parameters for flexible data fetching.
   * Uses the unified '/orders' endpoint.
   * @param {Object} params - Query parameters for fetching orders.
   * @returns {Promise<Object|null>} - The fetched orders data or null if failed.
   */
  const fetchOrders = useCallback(
    async (params = {}) => {
      if (!token) {
        logger.warn('fetchOrders called without a valid token.');
        setError('Authentication required to fetch orders.');
        toast.error('You must be logged in to view orders.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log('Fetching orders with params:', params);

        // If admin, maybe pass a flag or adjust params
        if (user && user.role === 'admin') {
          params.admin = true; // Example parameter; adjust based on backend
        }

        const response = await api.get('/orders', {
          params: { ...params, include: 'optionGroups,optionChoices' }, // Include options
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOrders(response.data.data);
        setPagination(response.data.pagination);
        logger.info(`Fetched orders: Page ${response.data.pagination.currentPage}`);
        console.log('Fetched orders:', response.data.data);

        // **Return the fetched data**
        return response.data;
      } catch (err) {
        logger.error('Failed to fetch orders:', err);
        setError('Failed to fetch orders.');
        toast.error('Failed to fetch orders.');
        return null; // Explicitly return null on failure
      } finally {
        setLoading(false);
      }
    },
    [token, user]
  );

  /**
   * Updates an existing order (e.g., update status).
   * Now uses the unified '/orders/:orderId' endpoint.
   * @param {number} orderId - The ID of the order to update.
   * @param {Object} updates - The updates to apply.
   * @returns {Promise<Object|null>} - The updated order or null if failed.
   */
  const updateOrder = useCallback(
    async (orderId, updates) => {
      if (!orderId || !updates) {
        setError('Order ID and updates are required.');
        toast.error('Order ID and updates are required.');
        return null;
      }

      if (!token) {
        logger.warn('updateOrder called without a valid token.');
        setError('Authentication required to update order.');
        toast.error('You must be logged in to update an order.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Updating order ID ${orderId} with updates:`, updates);
        const response = await api.put(`/orders/${orderId}`, updates, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const updatedOrder = response.data.data;

        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.id === orderId ? updatedOrder : order
          )
        );
        logger.info(`Order ID ${orderId} updated successfully.`);
        toast.success('Order updated successfully!');
        console.log('Updated order:', updatedOrder);
        return updatedOrder; // Return updated order
      } catch (err) {
        logger.error(`Failed to update order ID ${orderId}:`, err);
        setError('Failed to update order');
        toast.error('Failed to update order.');
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Deletes an existing order.
   * Now uses the unified '/orders/:orderId' endpoint.
   * @param {number} orderId - The ID of the order to delete.
   * @returns {Promise<void>} - Resolves when deletion is complete.
   */
  const deleteOrder = useCallback(
    async (orderId) => {
      if (!orderId) {
        setError('Order ID is required.');
        toast.error('Order ID is required.');
        return;
      }

      if (!token) {
        logger.warn('deleteOrder called without a valid token.');
        setError('Authentication required to delete order.');
        toast.error('You must be logged in to delete an order.');
        return;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Deleting order ID ${orderId}`);
        await api.delete(`/orders/${orderId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOrders((prevOrders) =>
          prevOrders.filter((order) => order.id !== orderId)
        );
        logger.info(`Order deleted: Order ID ${orderId}`);
        toast.success('Order deleted successfully!');
      } catch (err) {
        logger.error(`Failed to delete order ID ${orderId}:`, err);
        setError('Failed to delete order');
        toast.error('Failed to delete order.');
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Bulk updates orders' statuses.
   * Now uses the unified '/orders/bulk-update' endpoint.
   * @param {Array<number>} orderIds - Array of order IDs to update.
   * @param {string} status - The new status to apply.
   * @returns {Promise<Object|null>} - The result of the bulk update or null if failed.
   */
  const bulkUpdateOrders = useCallback(
    async (orderIds, status) => {
      if (!orderIds || !Array.isArray(orderIds) || orderIds.length === 0) {
        setError('Order IDs are required for bulk update');
        toast.error('Order IDs are required for bulk update.');
        return;
      }

      // Updated to match ORDER_STATUSES
      const validStatuses = [
        'pending',
        'processing',
        'baking',
        'ready for pickup',
        'collected',
        'completed',
        'refunded',
        'cancelled',
        'deleted',
      ];

      if (!validStatuses.includes(status)) {
        setError('Invalid status');
        toast.error('Invalid status');
        return;
      }

      if (!token) {
        logger.warn('bulkUpdateOrders called without a valid token.');
        setError('Authentication required to bulk update orders.');
        toast.error('You must be logged in to bulk update orders.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log('Sending to bulkUpdateOrders endpoint:', { orderIds, status });
        console.log(`Bulk updating orders ${orderIds} to status: ${status}`);
        const response = await api.put(
          '/orders/bulk-update',
          { orderIds, status },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success(response.data.message || 'Bulk update successful.');
        logger.info('Bulk update successful:', response.data);
        console.log('Bulk update response:', response.data);
        // Refresh orders
        await fetchOrders({
          page: 1,
          limit: itemsPerPage, // Use itemsPerPage
        });
        return response.data; // Optionally return response data
      } catch (err) {
        logger.error('Failed to bulk update orders:', err);
        setError('Failed to bulk update orders');
        toast.error('Failed to bulk update orders.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token, fetchOrders, itemsPerPage]
  );

  /**
   * Generates and downloads an invoice PDF for an order.
   * Now uses the unified '/orders/:orderId/invoice' endpoint.
   * @param {number} orderId - The ID of the order.
   * @returns {Promise<Object|null>} - The result of the download or null if failed.
   */
  const downloadInvoice = useCallback(
    async (orderId) => {
      if (!orderId) {
        setError('Order ID is required to download invoice');
        toast.error('Order ID is required to download invoice.');
        return null;
      }

      if (!token) {
        logger.warn('downloadInvoice called without a valid token.');
        setError('Authentication required to download invoice.');
        toast.error('You must be logged in to download an invoice.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Downloading invoice for order ID ${orderId}`);
        const response = await api.get(`/orders/${orderId}/invoice`, {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `invoice_${orderId}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        logger.info(`Invoice downloaded for Order ID ${orderId}`);
        toast.success('Invoice downloaded successfully!');
        console.log('Invoice downloaded successfully');
        return response.data; // Optionally return the blob
      } catch (err) {
        logger.error(`Failed to download invoice for Order ID ${orderId}:`, err);
        setError('Failed to download invoice');
        toast.error('Failed to download invoice.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Sends a notification (e.g., email) about order updates.
   * Now uses the unified '/orders/:orderId/notify' endpoint.
   * @param {number} orderId - The ID of the order.
   * @param {string} type - The type of notification (e.g., 'email').
   * @returns {Promise<Object|null>} - The result of the notification or null if failed.
   */
  const sendNotification = useCallback(
    async (orderId, type) => {
      if (!orderId || !type) {
        setError('Order ID and notification type are required');
        toast.error('Order ID and notification type are required.');
        return;
      }

      if (!token) {
        logger.warn('sendNotification called without a valid token.');
        setError('Authentication required to send notifications.');
        toast.error('You must be logged in to send notifications.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Sending ${type} notification for order ID ${orderId}`);
        await api.post(
          `/orders/${orderId}/notify`,
          { type },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        logger.info(`Notification sent via ${type} for Order ID ${orderId}`);
        toast.success(`Notification sent via ${type} successfully!`);
        console.log(`Notification sent via ${type} successfully!`);
      } catch (err) {
        logger.error(
          `Failed to send ${type} notification for Order ID ${orderId}:`,
          err
        );
        setError(`Failed to send ${type} notification`);
        toast.error(`Failed to send ${type} notification.`);
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Tracks an order by fetching its current status and tracking number.
   * Now uses the unified '/orders/:orderId/track' endpoint.
   * @param {number} orderId - The ID of the order to track.
   * @returns {Promise<Object|null>} - The tracking details of the order.
   */
  const trackOrder = useCallback(
    async (orderId) => {
      if (!orderId) {
        setError('Order ID is required to track order');
        toast.error('Order ID is required to track order.');
        return null;
      }

      if (!token) {
        logger.warn('trackOrder called without a valid token.');
        setError('Authentication required to track order.');
        toast.error('You must be logged in to track orders.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Tracking order ID ${orderId}`);
        const response = await api.get(`/orders/${orderId}/track`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const trackingDetails = response.data.data; // { status, trackingNumber }
        logger.info(`Tracked Order ID ${orderId}:`, trackingDetails);
        console.log('Tracking details:', trackingDetails);
        return trackingDetails;
      } catch (err) {
        logger.error(`Failed to track Order ID ${orderId}:`, err);
        setError('Failed to track order');
        toast.error('Failed to track order.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Fetches detailed information about a single order.
   * Now uses the unified '/orders/:orderId' endpoint.
   * @param {number} orderId - The ID of the order.
   * @returns {Promise<Object|null>} - The detailed information of the order.
   */
  // Already defined above as fetchOrderDetails

  /**
   * Search Products by Query with Debounce
   * Now uses the unified '/products/search' endpoint.
   * @param {string} query - The search query string.
   * @returns {Promise<void>} - Resolves when searching is complete.
   */
  const searchProducts = useMemo(
    () =>
      debounce(async (query) => {
        if (!query || query.trim() === '') {
          setSearchResults([]);
          return;
        }

        setLoading(true);
        setError(null);
        try {
          console.log(`Searching products with query: ${query}`);
          logger.info(`Searching products with query: ${query}`);
          let response;
          if (token) {
            // Authenticated search
            response = await api.get('/products/search', {
              params: { query, include: 'optionGroups,optionChoices' }, // Include options
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
          } else {
            // Unauthenticated search
            response = await api.get('/products/search', {
              params: { query, include: 'optionGroups,optionChoices' }, // Include options
            });
          }

          const fetchedProducts = response.data.data;

          setSearchResults(fetchedProducts); // Products now include optionGroups and optionChoices
          logger.info(`Found ${fetchedProducts.length} products matching query: ${query}`);
          console.log(`Found ${fetchedProducts.length} products matching query: ${query}`);
        } catch (err) {
          logger.error('Failed to search products:', err);
          setError('Failed to search products');
          toast.error('Failed to search products.');
        } finally {
          setLoading(false);
        }
      }, 300), // 300ms debounce delay
    [token]
  );

  /**
   * Cleanup debounce and throttle on unmount
   */
  useEffect(() => {
    return () => {
      searchProducts.cancel();
      checkUserPurchase.cancel();
    };
  }, [searchProducts, checkUserPurchase]);

  /**
   * Initialization of Store Data on Component Mount
   * Now includes public data initialization for unauthenticated users
   * Ensures optionGroups and optionChoices are included in the fetched data
   */
  const initializeStore = useCallback(async () => {
    if (initialized) return; // Prevent multiple initializations
    setInitialized(true);
    setLoading(true);
    setError(null);
    try {
      if (token) {
        // Authenticated user initialization
        // Fetch categories
        await fetchCategories();
        logger.info('Fetched categories successfully.');

        // Fetch ingredients
        await fetchIngredients();
        logger.info('Fetched ingredients successfully.');

        // Fetch cart items
        await dispatch(fetchCart());
        logger.info('Fetched cart items successfully.');

        // Fetch products
        await fetchProducts({
          page: 1,
          limit: itemsPerPage, // Use itemsPerPage from state
          sortBy: 'createdAt',
          order: 'desc',
        });
        logger.info('Fetched products successfully.');

        // Fetch recipes
        const recipesResponse = await api.get('/recipes', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setRecipes(recipesResponse.data.data);
        logger.info('Fetched recipes successfully.');
        console.log('Fetched recipes:', recipesResponse.data.data);

        // Fetch orders only if authenticated
        await fetchOrders({
          page: 1,
          limit: itemsPerPage, // Use itemsPerPage
        });
        logger.info('Fetched orders successfully.');

        // Fetch stores
        await fetchStores();
        logger.info('Fetched stores successfully.');

        // Fetch payment methods
        await fetchPaymentMethods();
        logger.info('Fetched payment methods during initialization.');

        // Fetch payments
        await fetchPayments();
        logger.info('Fetched payments during initialization.');

        // Fetch option groups and option choices
        await fetchOptionGroups();
        logger.info('Fetched option groups during initialization.');

        await fetchOptionChoices();
        logger.info('Fetched option choices during initialization.');
      } else {
        // Unauthenticated user initialization
        // Fetch public data such as categories, ingredients, products, and recipes
        // Since the backend now uses unified '/stores' endpoints, fetch without Authorization headers

        // Fetch recipes publicly if available
        try {
          const recipesResponse = await api.get('/recipes', {
            params: { include: 'optionGroups,optionChoices' }, // Include options
          });
          setRecipes(recipesResponse.data.data);
          logger.info('Fetched recipes successfully (public).');
          console.log('Fetched recipes (public):', recipesResponse.data.data);
        } catch (err) {
          logger.warn('Failed to fetch public recipes:', err);
          // Optional: Handle public recipes if applicable
        }

        // Fetch categories publicly
        try {
          const publicCategoriesResponse = await api.get('/categories', {
            params: { include: 'optionGroups,optionChoices' }, // Include options
          });
          setCategories(publicCategoriesResponse.data.data);
          logger.info('Fetched categories successfully (public).');
          console.log('Fetched categories (public):', publicCategoriesResponse.data.data);
        } catch (err) {
          logger.warn('Failed to fetch public categories:', err);
        }

        // Fetch ingredients publicly
        try {
          const publicIngredientsResponse = await api.get('/ingredients', {
            params: { include: 'optionGroups,optionChoices' }, // Include options
          });
          setIngredients(publicIngredientsResponse.data.data);
          logger.info('Fetched ingredients successfully (public).');
          console.log('Fetched ingredients (public):', publicIngredientsResponse.data.data);
        } catch (err) {
          logger.warn('Failed to fetch public ingredients:', err);
        }

        // Fetch products publicly without storeId
        try {
          await fetchProducts({
            page: 1,
            limit: itemsPerPage,
            sortBy: 'createdAt',
            order: 'desc',
            include: 'optionGroups,optionChoices',
          });
          logger.info('Fetched public products successfully.');
        } catch (err) {
          logger.warn('Failed to fetch public products:', err);
        }

        // Fetch option groups and option choices publicly
        try {
          const publicOptionGroupsResponse = await api.get('/option-groups', {
            params: { include: 'optionChoices' },
          });
          setOptionGroups(publicOptionGroupsResponse.data.data);
          logger.info('Fetched option groups successfully (public).');
          console.log('Fetched option groups:', publicOptionGroupsResponse.data.data);
        } catch (err) {
          logger.warn('Failed to fetch public option groups:', err);
        }

        try {
          const publicOptionChoicesResponse = await api.get('/option-choices', {
            params: { include: 'optionGroup' },
          });
          setOptionChoices(publicOptionChoicesResponse.data.data);
          logger.info('Fetched option choices successfully (public).');
          console.log('Fetched option choices:', publicOptionChoicesResponse.data.data);
        } catch (err) {
          logger.warn('Failed to fetch public option choices:', err);
        }
      }
    } catch (err) {
      logger.error('Initialization failed:', err);
      setError('Failed to initialize store data.');
      toast.error('Failed to initialize store data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [
    initialized,
    fetchPayments,
    fetchPaymentMethods,
    fetchCategories,
    fetchIngredients,
    fetchProducts,
    fetchOrders,
    fetchStores,
    fetchOptionGroups,
    fetchOptionChoices,
    itemsPerPage,
    token,
    dispatch,
  ]);

  useEffect(() => {
    initializeStore();
  }, [initializeStore]);

  /**
   * **Polling Mechanism to Fetch Orders Every Minute**
   */
  useEffect(() => {
    // Define the polling function
    const pollOrders = async () => {
      if (token) {
        try {
          console.log('Polling: Fetching latest orders...');
          await fetchOrders({
            page: 1,
            limit: itemsPerPage,
            include: 'optionGroups,optionChoices', // Include options
            // Add any additional parameters if needed
          });
          console.log('Polling: Orders updated.');
        } catch (error) {
          console.error('Polling: Failed to fetch orders.', error);
          // Optionally, you can handle specific errors or retry logic here
        }
      }
    };

    // Set up the interval to poll every minute (60000 ms)
    const intervalId = setInterval(pollOrders, 60000); // 1 minute

    // Optionally, perform an immediate poll when the component mounts
    pollOrders();

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [token, fetchOrders, itemsPerPage]);

  /**
   * **Integrate Socket.IO Connection**
   */
  useEffect(() => {
    if (!token) {
      console.warn('No token available. Socket.IO connection not established.');
      return;
    }

    const socket = io(config.API_URL, {
      transports: ['websocket'],
      auth: { token },
      reconnectionAttempts: 5,
      timeout: 10000,
    });

    socket.on('connect', () => {
      console.log('Connected to Socket.IO server.');
      toast.success('Connected to live updates.');

      // Always join user_{userId} room for personal order updates
      if (user && user.userId) {
        socket.emit('join_user', user.userId);
        console.log(`Emitted join_user for user ID ${user.userId}`);
      }

      // If admin, join admin room
      if (user && user.role === 'admin') {
        socket.emit('join_admin');
        console.log('Emitted join_admin for admin role.');
      } else if (selectedStore) {
        // If store staff, join store_{storeId} room
        socket.emit('join_store', selectedStore.id);
        console.log(`Emitted join_store for store ID ${selectedStore.id}`);
      }

      socket.emit('request_presence');
      console.log('Emitted request_presence.');
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from Socket.IO server.');
      toast.error('Disconnected from live updates.');
    });

    // Listen for order status updates
    socket.on(
      'order_status_update',
      ({
        orderId,
        status,
        processingStartTime,
        bakingStartTime,
        readyForCollectionTime,
      }) => {
        console.log(`Order #${orderId} updated to ${status}`);
        toast.info(`Order #${orderId} updated to ${status}`);
        setOrders((prevOrders) =>
          prevOrders.map((ord) =>
            ord.id === orderId
              ? { ...ord, status, processingStartTime, bakingStartTime, readyForCollectionTime }
              : ord
          )
        );
      }
    );

    // Listen for new orders
    socket.on('new_order', (order) => {
      console.log(`New order received: #${order.id}`);
      toast.info(`New order #${order.id} received`);
      setOrders((prev) => [order, ...prev]);
    });

    // Listen for order deletions
    socket.on('order_deleted', ({ orderId }) => {
      console.log(`Order #${orderId} deleted`);
      toast.info(`Order #${orderId} deleted`);
      setOrders((prev) => prev.filter((o) => o.id !== orderId));
    });

    // Handle connection errors
    socket.on('connect_error', (err) => {
      console.error('Socket connection error:', err.message);
      toast.error('Live updates connection failed.');
    });

    // Optional: Handle reconnection attempts
    socket.io.on('reconnect_attempt', (attempt) => {
      console.log(`Reconnection attempt #${attempt}`);
    });

    socket.io.on('reconnect_failed', () => {
      console.log('Reconnection failed.');
      toast.error('Reconnection failed.');
    });

    // Cleanup on unmount
    return () => {
      socket.disconnect();
      console.log('Socket.IO connection closed.');
    };
  }, [token, user, selectedStore]);

  /**
   * **Modal Control Functions**
   */
  const openProductModal = useCallback((product) => {
    setSelectedProduct(product);
    setIsProductModalOpen(true);
  }, []);

  const closeProductModal = useCallback(() => {
    setSelectedProduct(null);
    setIsProductModalOpen(false);
  }, []);

  const openAddToCartModal = useCallback((product) => {
    setSelectedProductForCart(product);
    setIsAddToCartModalOpen(true);
  }, []);

  const closeAddToCartModal = useCallback(() => {
    setSelectedProductForCart(null);
    setIsAddToCartModalOpen(false);
  }, []);

  // ---------------------------
  // ======= Context Value ======
  // ---------------------------

  /**
   * **StoreContext Provider Value**
   */
  return (
    <StoreContext.Provider
      value={{
        // =============================
        // ======== Categories =========
        // =============================
        categories,
        setCategories,
        fetchCategories,
        createCategory,

        // =============================
        // ========= Products ==========
        // =============================
        products,
        setProducts,
        fetchProducts,
        fetchStoreProducts, // Unified fetching function
        fetchProductWithIngredientsById,
        getProductWithIngredients,
        createProduct,
        updateProduct,
        deleteProduct,

        // =============================
        // ========= Search ============
        // =============================
        searchResults,
        setSearchResults,
        searchProducts,
        pagination,
        setPagination,

        // =============================
        // ==== Related Products =======
        // =============================
        relatedProducts,
        setRelatedProducts,
        fetchRelatedProducts,

        // =============================
        // ========= Ingredients =========
        // =============================
        ingredients,
        setIngredients,
        fetchIngredients,
        createIngredient,
        updateIngredient,
        deleteIngredient,

        // =============================
        // ========= Recipes ============
        // =============================
        recipes,
        setRecipes,
        // Define fetchRecipeById, createRecipe, etc., if needed

        // =============================
        // ===== Product Details ========
        // =============================
        productDetails,
        setProductDetails,

        // =============================
        // ========= Reviews ============
        // =============================
        reviews,
        setReviews,
        fetchProductReviews,
        submitReview,
        userReview,
        setUserReview,
        checkUserPurchase,
        fetchUserReview,

        // =============================
        // ========= Wishlist ===========
        // =============================
        addItemToWishlist,

        // =============================
        // ========= Orders ============
        // =============================
        orders,
        setOrders,
        fetchOrders, // Updated to accept parameters and return data
        placeOrder,
        updateOrder,
        deleteOrder,
        bulkUpdateOrders,
        downloadInvoice,
        sendNotification,
        trackOrder,
        fetchOrderDetails,

        // =============================
        // ========= Stores ============
        // =============================
        stores,
        setStores,
        fetchStores,
        fetchStoresByLocation,
        fetchNearestStore,
        // Removed fetchNearestStorePublic as it's no longer needed with unified endpoints
        fetchStoreDetails, // Unified fetching (authenticated/public)
        nearestStore,
        loadingStores,
        storeError,

        // =============================
        // ===== Payment Methods ========
        // =============================
        paymentMethods,
        fetchPaymentMethods,

        // =============================
        // ========= Payments ===========
        // =============================
        payments,
        fetchPayments,

        // =============================
        // ====== userHasPurchased =======
        // =============================
        userHasPurchased,
        setUserHasPurchased,

        // =============================
        // ====== Selected Store ========
        // =============================
        selectedStore,
        setSelectedStore,

        // =============================
        // ========= Option Groups ======
        // =============================
        optionGroups,
        setOptionGroups,
        fetchOptionGroups,
        createOptionGroup,
        updateOptionGroup,
        deleteOptionGroup,

        // =============================
        // ======== Option Choices ======
        // =============================
        optionChoices,
        setOptionChoices,
        fetchOptionChoices,
        createOptionChoice,
        updateOptionChoice,
        deleteOptionChoice,

        // =============================
        // ========= Modals ============
        // =============================
        isProductModalOpen,
        selectedProduct,
        openProductModal,
        closeProductModal,

        isAddToCartModalOpen,
        selectedProductForCart,
        openAddToCartModal,
        closeAddToCartModal,

        // =============================
        // ======= Loading & Error ======
        // =============================
        loading,
        setLoading,
        error,
        setError,

        // =============================
        // ======= Toggle State =========
        // =============================
        toggle,
        setToggle,
        toggleFunction,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

// Define PropTypes for type checking
StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Export the StoreContext
export default StoreContext;
