// src/Pages/HomePage.js

import React, { useState } from 'react';
import {
  FaMapMarkerAlt,
  FaSearch,
  FaShoppingCart,
  FaHeart,
  FaRegHeart,
  FaArrowRight,
  FaStar,
  FaRegStar,
  FaPhoneAlt,
  FaClock,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
} from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import PartnerLogo from '../components/PartnerLogo';
import BranchesList from '../components/BranchesList';
import { useStore } from '../components/contexts/StoreContext';
import { useUserContext } from '../components/contexts/UserContext';
import CustomAutocomplete from '../components/CustomAutocomplete';
import ViewCart from './ViewCart';
import { useCart } from '../components/contexts/CartContext';
import { milesToKilometers } from '../utils/convertUnits';

// Import logo images
import JustEatLogo from '../assets/logos/justeat.png';
import FoodhubLogo from '../assets/logos/foodhub.png';
import JubereatsLogo from '../assets/logos/jubereats.png';
import DeliverooLogo from '../assets/logos/deliveroo.png';
import OrderYoyoLogo from '../assets/logos/orderYoyo.png';
import cookiesCreamLogo from '../assets/logos/cookiesCreamLogo.png';

// Loader Component
const Loader = () => (
  <div className="flex justify-center items-center">
    <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
  </div>
);

// StarRating Component
const StarRating = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 >= 0.5;
  const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

  return (
    <div className="flex items-center">
      {Array(fullStars)
        .fill(0)
        .map((_, index) => (
          <FaStar key={`full-${index}`} className="text-yellow-400" />
        ))}
      {hasHalfStar && <FaStar key="half" className="text-yellow-400" />} {/* Replace with half-star if desired */}
      {Array(emptyStars)
        .fill(0)
        .map((_, index) => (
          <FaRegStar key={`empty-${index}`} className="text-yellow-400" />
        ))}
    </div>
  );
};

// FavoriteButton Component
const FavoriteButton = ({ isFavorite, toggleFavorite, isAuthenticated }) => {
  const handleClick = () => {
    if (!isAuthenticated) {
      toast.error('Please log in to manage your favorites.');
      return;
    }
    toggleFavorite();
  };

  return (
    <button
      onClick={handleClick}
      className="text-pink-500 hover:text-pink-600 focus:outline-none"
      aria-label={isFavorite ? 'Remove from favorites' : 'Add to favorites'}
    >
      {isFavorite ? <FaHeart /> : <FaRegHeart />}
    </button>
  );
};

// StoreCard Component
const StoreCard = ({ store, onOrder }) => {
  const { user } = useUserContext();
  const [isFavorite, setIsFavorite] = useState(false);

  const toggleFavorite = () => {
    // Implement favorite functionality here (e.g., API call or state management)
    setIsFavorite(!isFavorite);
    toast.success(
      isFavorite
        ? `${store.name} removed from favorites!`
        : `${store.name} added to favorites!`
    );
  };

  return (
    <motion.div
      className="bg-white p-6 rounded-lg shadow-md cursor-pointer hover:shadow-lg transition-shadow duration-300 flex flex-col"
      whileHover={{ scale: 1.02 }}
      onClick={() => onOrder(store)}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter') onOrder(store);
      }}
      aria-label={`View details for ${store.name}`}
    >
      {/* Store Image */}
      <div className="relative">
        <img
          src={store.avatar || '/logo.png'} // Ensure a default image exists in public directory
          alt={`${store.name}`}
          className="w-full h-40 object-cover rounded-md"
        />
        {/* Favorite Button */}
        <div className="absolute top-2 right-2">
          <FavoriteButton
            isFavorite={isFavorite}
            toggleFavorite={toggleFavorite}
            isAuthenticated={!!user}
          />
        </div>
      </div>

      {/* Store Details */}
      <div className="mt-4 flex-1 flex flex-col">
        {/* Store Name and Distance */}
        <div className="flex justify-between items-center">
          <h3 className="text-xl font-semibold text-gray-800">{store.name}</h3>
          <span className="text-sm text-gray-600">
            {store.distance !== undefined
              ? `${store.distance.toFixed(2)} km away`
              : ''}
          </span>
        </div>

        {/* Rating */}
        <div className="mt-2">
          <StarRating rating={store.rating || 4.5} />
        </div>

        {/* Address */}
        {store.address && (
          <p className="mt-2 text-gray-600 flex items-center">
            <FaMapMarkerAlt className="mr-1" />
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                store.address
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              {store.address}
            </a>
          </p>
        )}

        {/* Phone Number */}
        {store.contactNumber && (
          <p className="mt-1 text-gray-600 flex items-center">
            <FaPhoneAlt className="mr-1" />
            <a href={`tel:${store.contactNumber}`} className="underline">
              {store.contactNumber}
            </a>
          </p>
        )}

        {/* Operating Hours */}
        {store.operatingHours && (
          <p className="mt-1 text-gray-600 flex items-center">
            <FaClock className="mr-1" />
            {store.operatingHours}
          </p>
        )}

        {/* Social Media Links */}
        {store.socialMedia && (
          <div className="mt-2 flex space-x-2">
            {store.socialMedia.facebook && (
              <a
                href={store.socialMedia.facebook}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800"
                aria-label={`${store.name} on Facebook`}
              >
                <FaFacebookF />
              </a>
            )}
            {store.socialMedia.instagram && (
              <a
                href={store.socialMedia.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="text-pink-600 hover:text-pink-800"
                aria-label={`${store.name} on Instagram`}
              >
                <FaInstagram />
              </a>
            )}
            {store.socialMedia.twitter && (
              <a
                href={store.socialMedia.twitter}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-600"
                aria-label={`${store.name} on Twitter`}
              >
                <FaTwitter />
              </a>
            )}
          </div>
        )}

        {/* Spacer */}
        <div className="flex-grow"></div>

        {/* Order Now Button */}
        <motion.button
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-full flex items-center space-x-2 transition-transform duration-300"
          whileHover={{ scale: 1.05 }}
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering parent onClick
            onOrder(store);
          }}
          aria-label={`Order from ${store.name}`}
        >
          Order Now <FaArrowRight className="ml-2" />
        </motion.button>
      </div>
    </motion.div>
  );
};

// OrderTracker Component
const OrderTracker = ({ order, onClose }) => (
  <motion.div
    className="fixed bottom-0 right-0 m-6 bg-white shadow-lg rounded-lg p-6 z-50"
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: 50 }}
    transition={{ duration: 0.5 }}
    role="dialog"
    aria-modal="true"
    aria-labelledby="order-tracker-title"
  >
    <div className="flex justify-between items-center mb-4">
      <h3 className="text-xl font-semibold" id="order-tracker-title">
        Order #{order.orderId}
      </h3>
      <button onClick={onClose} className="text-gray-600 hover:text-gray-800" aria-label="Close order tracker">
        &times;
      </button>
    </div>
    <p>
      Status: <strong>{order.status}</strong>
    </p>
    <p>
      Estimated Delivery: <strong>{order.estimatedDelivery}</strong>
    </p>
  </motion.div>
);

// HomePage Component
const HomePage = () => {
  const [userLocation, setUserLocation] = useState(null);
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [locationFetching, setLocationFetching] = useState(false);
  const [error, setError] = useState(null);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [order, setOrder] = useState(null);

  const { fetchNearestStore, nearestStore, loadingStores, storeError } = useStore();
  const { user } = useUserContext(); // Assuming 'user' is null or an object when authenticated
  const { selectStore } = useUserContext();
  const { cartItems, clearCart } = useCart(); // Access cartItems and clearCart from CartContext

  const navigate = useNavigate();

  // Partners Data with URLs
  const partners = [
    {
      src: JustEatLogo,
      alt: 'JUSTEAT',
      location: 'Dunstable',
      href: 'https://www.just-eat.co.uk/restaurants-cookiesandcream-lu1/menu?serviceType=collection&utm_source=google&utm_medium=organic&utm_campaign=foodorder',
    },
    {
      src: FoodhubLogo,
      alt: 'Foodhub',
      location: 'Luton',
      href: 'https://foodhub.co.uk/luton/cookies-and-cream/info',
    },
    {
      src: JubereatsLogo,
      alt: 'Jubereats',
      location: 'Luton',
      href: 'https://www.ubereats.com/gb/store/cookies-%26-cream/KSD8Nqm0TrmiX2niEzdo3Q?diningMode=PICKUP&utm_campaign=CM2508147-search-free-nonbrand-google-pas_e_all_acq_Global&utm_medium=search-free-nonbrand&utm_source=google-pas',
    },
    {
      src: DeliverooLogo,
      alt: 'Deliveroo',
      location: 'Dunstable',
      href: 'https://deliveroo.co.uk/menu/london/dunstable/cookies-and-cream-dunstable?srsltid=AfmBOopbAary7kCdkYMMkFEhYfTxH1OUWIbyLGG5AUUS51aRr5tVZVUb',
    },
    {
      src: OrderYoyoLogo, // New Partner
      alt: 'OrderYoyo',
      location: 'Luton',
      href: 'https://cookies-and-cream.co.uk/cookies-cream/', // Replace with actual URL
    },
  ];

  // Branches Data
  const branches = ['Luton', 'Dunstable', 'South Woodford', 'Mile End', 'Barkingside'];

  // Handle using user's current location
  const handleLocationContinue = async () => {
    if (navigator.geolocation) {
      setLocationFetching(true);
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          setLocationFetching(false);
          const location = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          setUserLocation(location);
          console.log('User location:', location); // Debugging log
          toast.success('Location detected successfully! Searching for the nearest store...');

          try {
            setLoading(true);
            const nearest = await fetchNearestStore(location);
            if (nearest) {
              toast.success(
                `Nearest store: ${nearest.name} (${nearest.distance.toFixed(2)} km away)`
              );
            } else {
              toast.info('No stores found within a 5-mile radius.');
            }
          } catch (error) {
            toast.error('Failed to fetch nearest stores.');
            console.error('Error fetching nearest stores:', error);
          } finally {
            setLoading(false);
          }
        },
        (err) => {
          setLocationFetching(false);
          setError('Unable to retrieve your location.');
          toast.error(
            'Unable to retrieve your location. Please enable location access.'
          );
        }
      );
    } else {
      setError('Geolocation is not supported by your browser.');
      toast.error('Geolocation is not supported by your browser.');
    }
  };

  // Handle address selection from autocomplete
  const handleSelect = async (suggestion) => {
    setAddress(suggestion.formatted);
    try {
      setLoading(true);
      toast.success(`Searching nearest store near "${suggestion.formatted}"...`);

      // Extract latitude and longitude from the suggestion
      const { lat, lng } = suggestion.geometry;

      const location = {
        latitude: lat,
        longitude: lng,
      };

      setUserLocation(location);

      const nearest = await fetchNearestStore(location);
      if (nearest) {
        toast.success(
          `Nearest store: ${nearest.name} (${nearest.distance.toFixed(2)} km away)`
        );
      } else {
        toast.info('No stores found within a 5-mile radius.');
      }
    } catch (error) {
      toast.error('Failed to fetch stores based on address.');
      console.error('Error in handleSelect:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle ordering from a store
  const handleOrder = (store) => {
    selectStore(store.id); // Set the user's preferred store
    toast.success(`You have selected ${store.name} as your store.`);
    navigate(`/store/${store.id}`); // Navigate to the store page
  };

  // Handle viewing the cart
  const handleViewCart = () => {
    setIsCartOpen(true);
  };

  // Handle closing the cart
  const handleCloseCart = () => {
    setIsCartOpen(false);
  };

  // Handle order tracking
  const handleOrderTracking = () => {
    if (cartItems.length === 0) {
      toast.error('Your cart is empty!');
      return;
    }
    // Simulate order placement
    const newOrder = {
      orderId: Math.floor(Math.random() * 100000),
      status: 'Preparing',
      estimatedDelivery: '20 minutes',
    };
    setOrder(newOrder);
    toast.success('Order placed successfully!');
    // Optionally, clear the cart after order placement
    clearCart();
  };

  // Handle closing the order tracker
  const handleCloseOrderTracker = () => {
    setOrder(null);
  };

  return (
    <div className="relative min-h-screen bg-white"> {/* Changed bg-gray-100 to bg-white */}
      {/* Navbar */}
      <Navbar />

      {/* Main Content */}
      <div className="container mx-auto px-6 py-24 flex flex-col justify-center items-center space-y-12">
        {/* **Revised Introductory Section Starts Here** */}
        {/* Centered Heading */}
        <motion.h1
          className="text-5xl md:text-6xl font-extrabold text-gray-800 text-center mb-12"
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          Welcome to Cookies & Cream
        </motion.h1>

        {/* Two-Column Layout Below Heading */}
        <motion.div
          className="flex flex-col md:flex-row items-center max-w-4xl w-full"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          {/* Descriptive Text Column */}
          <motion.div
            className="md:w-1/2 text-center md:text-left"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 0.3 }}
          >
            {/* Descriptive Text */}
            <p className="text-lg md:text-xl text-gray-700 mb-4">
              Discover Luton’s premier dessert lounge at the Galaxy Centre. Indulge in our exquisite selection of sweet treats, crafted to perfection.
            </p>
            <p className="text-lg md:text-xl text-gray-700 mb-4">
              Whether you prefer a cozy sit-in experience or the convenience of takeaways, Cookies & Cream caters to all your dessert cravings.
            </p>
            <p className="text-lg md:text-xl text-gray-700 mb-6">
              Open daily from 12 PM to 12 AM (Sunday–Thursday) and 12 PM to 1 AM (Friday–Saturday), we’re here to sweeten your day anytime.
            </p>
            <p className="text-lg md:text-xl text-gray-700 mb-8">
              Join us today and tantalize your tastebuds with the most delightful and exotic flavors in town!
            </p>
          </motion.div>

          {/* Logo Column */}
          <motion.div
            className="md:w-1/2 flex justify-center mt-8 md:mt-0"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <motion.a
              href="https://restaurantguru.com/Cookies-and-Cream-Luton---//"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block"
              whileHover={{ scale: 1.1 }}
              aria-label="Cookies & Cream on Restaurant Guru"
            >
              <img
                src={cookiesCreamLogo}
                alt="Cookies & Cream Logo"
                className="h-40 w-auto mx-auto"
              />
            </motion.a>
          </motion.div>
        </motion.div>
        {/* **Revised Introductory Section Ends Here** */}

        {/* **Added "Collection Only" Label Starts Here** */}
        {/* "Collection Only" Label */}
        <div className="flex justify-center mb-6">
          <span className="px-4 py-1 bg-green-100 text-green-800 rounded-full text-sm font-semibold">
            Collection Only
          </span>
        </div>
        {/* **Added "Collection Only" Label Ends Here** */}

        {/* Location and Search Section */}
        <div className="flex flex-col md:flex-row items-center justify-center space-y-6 md:space-y-0 md:space-x-6 w-full max-w-md">
          {/* Use My Location Button */}
          <motion.button
            onClick={handleLocationContinue}
            className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-full shadow-lg text-lg flex items-center space-x-2 transition-transform duration-300 focus:outline-none disabled:bg-blue-400 disabled:cursor-not-allowed"
            whileHover={{ scale: 1.05 }}
            disabled={locationFetching}
            aria-label="Use my current location"
          >
            <FaMapMarkerAlt className="text-xl" />
            <span>{locationFetching ? 'Detecting Location...' : 'Use My Location'}</span>
          </motion.button>

          {/* Search Address Input using CustomAutocomplete */}
          <CustomAutocomplete
            address={address}
            setAddress={setAddress}
            onSelect={handleSelect}
          />
        </div>

        {/* Loading Indicator */}
        {loading && <Loader />}

        {/* Error Message */}
        {error && <div className="text-red-500 mt-4">{error}</div>}

        {/* Nearest Store Display */}
        <AnimatePresence>
          {nearestStore && (
            <div className="w-full max-w-7xl">
              <h2 className="text-3xl font-semibold text-gray-800 mb-6 text-center">
                Nearest Store
              </h2>
              <motion.div
                className="flex justify-center"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.5 }}
              >
                <StoreCard
                  key={nearestStore.id}
                  store={nearestStore}
                  onOrder={handleOrder}
                />
              </motion.div>
            </div>
          )}
        </AnimatePresence>

        {/* Message When No Store is Found */}
        {!loadingStores && nearestStore === null && userLocation && (
          <div className="text-center text-gray-600">
            <p>No stores found within a 5-mile radius.</p>
          </div>
        )}
      </div>

      {/* Enhanced Section with Logos and Branches */}
      <div className="bg-gray-50 py-16">
        <div className="container mx-auto px-6">
          <h2 className="text-2xl md:text-3xl font-semibold text-center text-gray-800 mb-12">
            Our Delivery Partners & Branches
          </h2>
          {/* Instructional Text */}
          <p className="text-center text-gray-600 mb-6">
            Click on a partner to place your delivery order.
          </p>
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-8 items-center">
            {partners.map((partner, index) => (
              <PartnerLogo
                key={index}
                src={partner.src}
                alt={partner.alt}
                location={partner.location}
                href={partner.href}
              />
            ))}
          </div>

          {/* Branches List */}
          <BranchesList branches={branches} />
        </div>
      </div>

      {/* Floating Cart Icon */}
      <motion.div
        className="fixed bottom-8 right-8"
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 1, duration: 0.5 }}
      >
        <motion.button
          onClick={handleViewCart}
          className="bg-blue-600 hover:bg-blue-700 text-white p-4 rounded-full shadow-lg relative flex items-center justify-center focus:outline-none"
          whileHover={{ scale: 1.1 }}
          aria-label="View cart"
        >
          <FaShoppingCart className="text-2xl" />
          {cartItems.length > 0 && (
            <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full h-5 w-5 flex items-center justify-center text-xs">
              {cartItems.length}
            </span>
          )}
        </motion.button>
      </motion.div>

      {/* Cart Modal */}
      <AnimatePresence>
        {isCartOpen && (
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-50 flex justify-end"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={handleCloseCart}
          >
            <ViewCart /> {/* Render the ViewCart component */}
          </motion.div>
        )}
      </AnimatePresence>

      {/* Order Tracker */}
      <AnimatePresence>
        {order && (
          <OrderTracker order={order} onClose={handleCloseOrderTracker} />
        )}
      </AnimatePresence>

      {/* Social Media Links */}
      <div className="fixed bottom-8 left-8 flex space-x-4 z-50">
        <motion.a
          href="https://www.facebook.com/cookiesluton/?locale=en_GB"
          className="text-2xl text-gray-600 hover:text-blue-600"
          whileHover={{ scale: 1.2 }}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Facebook"
        >
          <FaFacebookF />
        </motion.a>
        <motion.a
          href="https://www.instagram.com/cookiesandcreamexpress/"
          className="text-2xl text-gray-600 hover:text-pink-600"
          whileHover={{ scale: 1.2 }}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Instagram"
        >
          <FaInstagram />
        </motion.a>
      </div>

      {/* Notifications */}
      <ToastContainer position="bottom-left" />
    </div>
  );
};

export default HomePage;
