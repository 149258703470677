import { io } from 'socket.io-client';
import store from '../store'; // Redux store or your central store
import { addNotification, removeNotification } from '../features/notifcation/notificationsSlice';
import config from '../components/contexts/config'; // Ensure this path is correct
import jwt_decode from 'jwt-decode'; // Install this package if not already installed

let socket = null;

/**
 * Checks if the user is an admin based on the JWT token.
 * @param {string} token - JWT token.
 * @returns {boolean} - True if user is admin, else false.
 */
const isAdmin = (token) => {
  try {
    const decoded = jwt_decode(token);
    return decoded.roles && decoded.roles.includes('admin'); // Adjust based on your token structure
  } catch (error) {
    console.error('Failed to decode token:', error);
    return false;
  }
};

/**
 * Initiates a Socket.IO connection with authentication.
 * @param {string} token - JWT token for authentication.
 */
export const initiateSocket = (token) => {
  if (!token) {
    console.warn('No token provided. Cannot connect to Socket.IO server.');
    return;
  }

  if (!isAdmin(token)) {
    console.log('User is not an admin. Notifications will not be received.');
    return;
  }

  console.log('Connecting to Socket.IO server with token:', token);

  socket = io(config.API2_URL, {
    transports: ['websocket'],
    auth: { token },
    reconnectionAttempts: 5,
    timeout: 10000,
  });

  socket.on('connect', () => {
    console.log('Connected to Socket.IO server.');
    store.dispatch(addNotification({ id: 'connect', message: 'Connected to live updates.' }));
  });

  // To ensure notifications are only shown once, maintain a Set of shown notification IDs
  const shownNotifications = new Set();

  // Handle server-sent notifications
  socket.on('NEW_NOTIFICATION', (data) => {
    console.log('Received NEW_NOTIFICATION:', data);
    if (!shownNotifications.has(data.id)) { // Assuming each notification has a unique 'id'
      store.dispatch(addNotification(data));
      shownNotifications.add(data.id);
    } else {
      console.log(`Notification with ID ${data.id} has already been shown.`);
    }
  });

  socket.on('REMOVE_NOTIFICATION', (data) => {
    console.log('Received REMOVE_NOTIFICATION:', data);
    store.dispatch(removeNotification(data.id));
    shownNotifications.delete(data.id);
  });

  // Handle disconnection
  socket.on('disconnect', () => {
    console.log('Disconnected from Socket.IO server.');
    store.dispatch(addNotification({ id: 'disconnect', message: 'Disconnected from live updates.' }));
  });

  // Handle connection errors
  socket.on('connect_error', (err) => {
    console.error('Socket connection error:', err.message);
    store.dispatch(addNotification({ id: 'connect_error', message: 'Socket connection error.' }));
  });

  // Optional: Handle reconnection attempts
  socket.io.on('reconnect_attempt', (attempt) => {
    console.log(`Reconnection attempt #${attempt}`);
  });

  socket.io.on('reconnect_failed', () => {
    console.log('Reconnection failed.');
    store.dispatch(addNotification({ id: 'reconnect_failed', message: 'Reconnection failed.' }));
  });
};

/**
 * Disconnects the Socket.IO connection.
 */
export const disconnectSocket = () => {
  console.log('Disconnecting socket...');
  if (socket) socket.disconnect();
};

/**
 * Emit a custom event to remove a notification (optional).
 * @param {string} notificationId - The ID of the notification to remove.
 */
export const emitRemoveNotification = (notificationId) => {
  if (socket) {
    socket.emit('REMOVE_NOTIFICATION', { id: notificationId });
    console.log(`Emitted REMOVE_NOTIFICATION for ID: ${notificationId}`);
  }
};
