// frontend/src/components/contexts/ModalContext.js

import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import AddToCartModal from '../AddToCartModal';

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [modalState, setModalState] = useState({
    isOpen: false,
    product: null,
  });

  const openModal = (product) => {
    setModalState({ isOpen: true, product });
  };

  const closeModal = () => {
    setModalState({ isOpen: false, product: null });
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      <AddToCartModal
        isOpen={modalState.isOpen}
        onRequestClose={closeModal}
        product={modalState.product}
      />
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalContext;
