// frontend/src/pages/CheckoutPage.js

import React, { useEffect, useState } from 'react';
import { useCart } from '../components/contexts/CartContext';
import { useUserContext } from '../components/contexts/UserContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';

// Initialize Stripe.js with your publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutPage = () => {
  const { cartItems, clearCart, orderType } = useCart(); // Ensure 'orderType' is part of CartContext
  const { user, token } = useUserContext(); // Access user data and token
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  /**
   * Creates a Stripe Checkout Session and redirects the user.
   */
  const createCheckoutSession = async () => {
    if (cartItems.length === 0) {
      toast.error('Your cart is empty.');
      navigate('/');
      return;
    }

    try {
      if (!token) {
        toast.error('You must be registered to proceed to checkout.');
        navigate('/register'); // Redirect to Register instead of Login
        return;
      }

      if (!user) {
        toast.error('User data not available. Please register again.');
        navigate('/register'); // Redirect to Register instead of Login
        return;
      }

      // Validate addresses based on orderType
      if (orderType === 'delivery') {
        if (
          !user.shippingAddress ||
          !user.shippingCity ||
          !user.shippingPostcode
        ) {
          toast.error('Please update your shipping address in your profile.');
          navigate('/profile');
          return;
        }
      }

      // Prepare cart items data
      const preparedCartItems = cartItems.map((item) => ({
        productId: item.productId,
        quantity: item.quantity,
        attributes: item.attributes,
      }));

      // Define frontend and backend URLs
      const frontendURL = process.env.REACT_APP_FRONTEND_URL || 'http://localhost:3000';
      const apiURL = process.env.REACT_APP_API_URL || 'http://localhost:5003';

      // Create Checkout Session on the backend
      const response = await axios.post(
        `${apiURL}/payments/create-checkout-session`,
        {
          cartItems: preparedCartItems,
          orderType, // Include orderType here
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Checkout Session Response:', response.data);
      const { sessionId } = response.data;
      console.log('Received Stripe Session ID:', sessionId);

      if (!sessionId || typeof sessionId !== 'string') {
        throw new Error('Invalid sessionId returned from backend.');
      }

      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        console.error('Stripe Checkout Error:', error);
        toast.error(error.message);
        setLoading(false);
      } else {
        // Optionally, clear the cart after successful redirection
        clearCart();
      }
    } catch (error) {
      console.error('Error creating Checkout Session:', error);
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(`Checkout Error: ${error.response.data.error}`);
      } else if (error.message) {
        toast.error(`Checkout Error: ${error.message}`);
      } else {
        toast.error('Failed to initiate checkout. Please try again.');
      }

      // Handle specific error responses
      if (error.response && error.response.status === 401) {
        toast.error('Your session has expired. Please register again.');
        navigate('/register'); // Redirect to Register instead of Login
      } else if (
        error.response &&
        (error.response.status === 400 || error.response.status === 404)
      ) {
        toast.error(error.response.data.error || 'Checkout failed.');
        navigate('/cart');
      } else {
        toast.error('Failed to initiate checkout. Please try again.');
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    // Debugging: Log environment variables
    console.log('Frontend URL:', process.env.REACT_APP_FRONTEND_URL);
    console.log('API URL:', process.env.REACT_APP_API_URL);
    console.log('Stripe Publishable Key:', process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    createCheckoutSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run once on component mount

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      {loading ? (
        <motion.div
          className="text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <p className="text-xl">Redirecting to Checkout...</p>
        </motion.div>
      ) : (
        <motion.div
          className="text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <p className="text-xl">Unable to redirect to Checkout.</p>
          <button
            onClick={() => navigate('/checkout')}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
          >
            Retry Checkout
          </button>
        </motion.div>
      )}
    </div>
  );
};

export default CheckoutPage;
