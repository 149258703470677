// frontend/src/components/Navbar.js

import React, { useState, useEffect, useRef, useCallback, forwardRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  FiSearch,
  FiShoppingCart,
  FiUser,
  FiMenu,
  FiX,
  FiChevronDown,
  FiBell,
} from 'react-icons/fi';
import { FaMinus, FaPlus } from 'react-icons/fa';
import Tooltip from '@mui/material/Tooltip';
import { Badge } from '@mui/material';
import { useCart } from './contexts/CartContext';
import { useUserContext } from './contexts/UserContext';
import { useStore } from './contexts/StoreContext';
import { motion, AnimatePresence } from 'framer-motion';
import { Transition } from '@headlessui/react';
import Avatar from 'react-avatar';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { getImageUrl } from '../utils/joinUrl';
import config from './contexts/config';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { removeNotification } from '../features/notifcation/notificationsSlice'; // Corrected import path
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * CustomNavLink Component
 * A reusable component for navigation links to ensure consistency.
 */
const CustomNavLink = ({ to, label, onClick }) => (
  <Link
    to={to}
    onClick={onClick}
    className="block px-3 py-2 rounded-md text-base font-medium text-black hover:bg-gray-200 transition duration-150"
  >
    {label}
  </Link>
);

/**
 * UserProfileMenu Component
 * Dropdown menu for authenticated users.
 */
const UserProfileMenu = forwardRef(({ isProfileOpen, setIsProfileOpen, logout, user }, ref) => {
  return (
    <div className="relative" ref={ref}>
      <button
        onClick={() => setIsProfileOpen(!isProfileOpen)}
        className="flex items-center focus:outline-none"
        aria-label="Open user menu"
        aria-haspopup="true"
        aria-expanded={isProfileOpen}
      >
        {user.avatar ? (
          <Avatar src={user.avatar} size="32" round className="h-8 w-8" />
        ) : (
          <FiUser className="h-8 w-8 text-gray-500" />
        )}
      </button>
      <Transition
        show={isProfileOpen}
        as={React.Fragment}
        enter="transition ease-out duration-200 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-150 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div
          className="origin-top-right absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-50"
          role="menu"
          aria-label="User menu"
        >
          <CustomNavLink to="/profile" label="Profile" onClick={() => setIsProfileOpen(false)} />
          <CustomNavLink to="/orders" label="Orders" onClick={() => setIsProfileOpen(false)} />
          {user.role === 'admin' && (
            <CustomNavLink to="/admin/dashboard" label="Dashboard" onClick={() => setIsProfileOpen(false)} />
          )}
          <button
            onClick={() => {
              logout();
              setIsProfileOpen(false);
            }}
            className="block w-full text-left px-4 py-2 text-sm text-black hover:bg-gray-200 transition duration-150"
            role="menuitem"
          >
            Logout
          </button>
        </div>
      </Transition>
    </div>
  );
});

/**
 * GuestMenu Component
 * Menu for unauthenticated users.
 */
const GuestMenu = () => (
  <div className="flex space-x-4">
    <CustomNavLink to="/login" label="Login" />
    <CustomNavLink to="/register" label="Register" />
  </div>
);

/**
 * CartItem Component
 * Represents an individual item in the cart dropdown.
 */
const CartItem = ({ item, addItemToCart, removeItemFromCart }) => {
  return (
    <div className="flex items-center justify-between px-4 py-2 border-b border-gray-200">
      <div className="flex items-center">
        <img
          src={getImageUrl(item.product?.imageUrl)}
          alt={item.product?.name || 'Product Image'}
          className="h-10 w-10 object-cover rounded-full mr-4"
        />
        <div>
          <span className="text-sm font-medium text-black">{item.product?.name}</span>
          {item.size && <p className="text-xs text-gray-400">Size: {item.size}</p>}
          {item.ingredients && item.ingredients.length > 0 && (
            <div className="mt-1 text-xs text-gray-400">
              Ingredients:
              <ul className="list-disc list-inside">
                {item.ingredients.map((ing) => (
                  <li key={ing.ingredientId}>
                    {ing.name} x {ing.quantity}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center">
        <button
          onClick={() => removeItemFromCart(item)}
          className="px-2 py-1 text-sm bg-gray-200 rounded-md hover:bg-gray-300 transition duration-150"
          aria-label={`Decrease quantity of ${item.product?.name}`}
        >
          <FaMinus />
        </button>
        <span className="px-2 text-sm text-black">{item.quantity}</span>
        <button
          onClick={() => addItemToCart(item)}
          className="px-2 py-1 text-sm bg-gray-200 rounded-md hover:bg-gray-300 transition duration-150"
          aria-label={`Increase quantity of ${item.product?.name}`}
        >
          <FaPlus />
        </button>
      </div>
    </div>
  );
};

/**
 * CartDropdown Component
 * Dropdown menu displaying cart items.
 */
const CartDropdown = forwardRef(
  (
    {
      isCartDropdownOpen,
      setIsCartDropdownOpen,
      cart,
      cartItemCount,
      clearCart,
      addItemToCart,
      removeItemFromCart,
    },
    ref
  ) => {
    return (
      <div
        className="relative"
        ref={ref}
        onMouseEnter={() => setIsCartDropdownOpen(true)}
        onMouseLeave={() => setIsCartDropdownOpen(false)}
      >
        <Tooltip title="View Cart" arrow>
          <button
            className="relative p-1 rounded-full text-black hover:text-pink-600 focus:outline-none"
            aria-label="View cart"
            aria-haspopup="true"
            aria-expanded={isCartDropdownOpen}
          >
            <FiShoppingCart className="h-6 w-6" />
            {cartItemCount > 0 && (
              <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-pink-600 rounded-full">
                {cartItemCount}
              </span>
            )}
          </button>
        </Tooltip>
        <Transition
          show={isCartDropdownOpen}
          as={React.Fragment}
          enter="transition ease-out duration-200 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-150 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className="absolute right-0 mt-2 w-80 bg-white border border-gray-200 rounded-md shadow-lg z-50"
            role="menu"
            aria-label="Shopping cart"
          >
            <div className="p-4 border-b border-gray-200">
              <h3 className="text-lg font-semibold text-black">Your Cart</h3>
            </div>
            <div className="max-h-60 overflow-y-auto">
              {cart.length > 0 ? (
                cart.map((item) => (
                  <CartItem
                    key={item.id}
                    item={item}
                    addItemToCart={addItemToCart}
                    removeItemFromCart={removeItemFromCart}
                  />
                ))
              ) : (
                <div className="px-4 py-2 text-sm text-gray-500">Your cart is empty</div>
              )}
            </div>
            {cart.length > 0 && (
              <div className="p-4 border-t border-gray-200">
                <Link
                  to="/view-cart"
                  className="block w-full text-center px-4 py-2 mb-2 bg-pink-500 text-white rounded-md hover:bg-pink-600 transition duration-150"
                  onClick={() => setIsCartDropdownOpen(false)}
                >
                  View Cart
                </Link>
                <button
                  onClick={clearCart}
                  className="w-full px-4 py-2 text-center bg-red-500 text-white rounded-md hover:bg-red-600 transition duration-150"
                >
                  Clear Cart
                </button>
              </div>
            )}
          </div>
        </Transition>
      </div>
    );
  }
);

/**
 * Notifications Component
 * Dropdown menu displaying user notifications and popup notifications.
 */
const Notifications = forwardRef(
  ({ isNotificationsOpen, setIsNotificationsOpen, notifications }, ref) => {
    const [popup, setPopup] = useState(null); // State for popup
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Track previous notifications to detect new ones
    const prevNotificationsRef = useRef([]);
    useEffect(() => {
      const prevNotifications = prevNotificationsRef.current;
      if (notifications.length > prevNotifications.length) {
        const newNotification = notifications[0]; // Assuming latest is at index 0
        console.log('New notification detected:', newNotification);
        setPopup(newNotification);
        // Auto-hide the popup after 5 seconds
        const timer = setTimeout(() => {
          setPopup(null);
        }, 5000);
        return () => clearTimeout(timer);
      }
      prevNotificationsRef.current = notifications;
    }, [notifications]);

    /**
     * Handle marking a notification as read
     */
    const handleMarkAsReadInternal = (id) => {
      const exists = notifications.find((n) => n.id === id);
      if (!exists) {
        console.log(`Notification with id ${id} already removed.`);
        return; // Already removed
      }
      console.log(`Dismissing notification with id ${id}`);
      dispatch(removeNotification(id));
      toast.info('Notification dismissed.');
    };

    /**
     * Handle clicking on a notification
     * Navigates to order details if orderId is present
     */
    const handleNotificationClick = (notification) => {
      dispatch(removeNotification(notification.id));
      if (notification.orderId) {
        navigate(`/orders/${notification.orderId}`);
      } else {
        // Handle other types of notifications if necessary
        toast.info(notification.message);
      }
      setPopup(null);
      setIsNotificationsOpen(false);
    };

    return (
      <div className="relative" ref={ref}>
        {/* Bell Icon with Notification Count */}
        <button
          onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
          className="relative p-1 rounded-full text-black hover:text-pink-600 focus:outline-none"
          aria-label="View notifications"
          aria-haspopup="true"
          aria-expanded={isNotificationsOpen}
        >
          <Badge
            badgeContent={notifications.length}
            color="error"
            invisible={notifications.length === 0} // Hide badge if no notifications
          >
            <FiBell className="h-6 w-6" />
          </Badge>
        </button>

        {/* Popup Notification */}
        <AnimatePresence>
          {popup && (
            <motion.div
              initial={{ opacity: 0, x: 100, y: -20 }}
              animate={{ opacity: 1, x: 0, y: 0 }}
              exit={{ opacity: 0, x: 100, y: -20 }}
              transition={{ duration: 0.3 }}
              className="fixed top-16 right-4 w-80 bg-white border border-gray-200 rounded-md shadow-lg z-50"
            >
              <div className="p-4 relative">
                {/* Close '×' Button */}
                <button
                  onClick={() => {
                    handleMarkAsReadInternal(popup.id);
                    setPopup(null); // Hide the popup
                  }}
                  className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                  aria-label="Dismiss notification"
                >
                  <FiX size={18} />
                </button>
                {/* Notification Content */}
                <h4 className="text-lg font-semibold text-black">New Notification</h4>
                <p className="mt-2 text-black">{popup.message}</p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Dropdown Notifications */}
        <Transition
          show={isNotificationsOpen}
          as={React.Fragment}
          enter="transition ease-out duration-200 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-150 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.2 }}
            className="absolute right-0 mt-2 w-80 bg-white border border-gray-200 rounded-md shadow-lg z-50"
            role="menu"
            aria-label="Notifications"
          >
            <div className="p-4 border-b border-gray-200">
              <h3 className="text-lg font-semibold text-black">Notifications</h3>
            </div>
            <div className="max-h-60 overflow-y-auto">
              {notifications.length > 0 ? (
                notifications.map((notification) => (
                  <div
                    key={notification.id}
                    className="px-4 py-2 hover:bg-gray-200 cursor-pointer flex justify-between items-center"
                    role="button"
                    tabIndex={0}
                    onClick={() => handleNotificationClick(notification)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') handleNotificationClick(notification);
                    }}
                  >
                    <span className="text-black">{notification.message}</span>
                    <button
                      className="text-red-500 hover:text-red-700"
                      aria-label="Mark as read"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent parent onClick
                        handleMarkAsReadInternal(notification.id);
                      }}
                    >
                      &times;
                    </button>
                  </div>
                ))
              ) : (
                <div className="px-4 py-2 text-sm text-gray-500">No new notifications</div>
              )}
            </div>
          </motion.div>
        </Transition>
      </div>
    );
  }
);

/**
 * MobileMenuButton Component
 * Button to toggle the mobile menu.
 */
const MobileMenuButton = ({ isMobileMenuOpen, setIsMobileMenuOpen }) => (
  <button
    onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
    className="p-2 rounded-md text-black hover:text-pink-600 md:hidden"
    aria-label="Toggle Mobile Menu"
    aria-expanded={isMobileMenuOpen}
  >
    {isMobileMenuOpen ? <FiX className="h-6 w-6" /> : <FiMenu className="h-6 w-6" />}
  </button>
);

/**
 * MobileMenu Component
 * The mobile version of the Navbar with slide-in/out animation.
 */
const MobileMenu = forwardRef(
  (
    {
      isMobileMenuOpen,
      setIsMobileMenuOpen,
      user,
      logout,
      searchQuery,
      setSearchQuery,
      suggestions,
      showSuggestions,
      noMatches,
      handleSelectSuggestion,
      handleExecuteSearch,
      setShowSuggestions, // Added prop
    },
    ref
  ) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { nearestStore } = useStore(); // Access nearestStore directly

    /**
     * Handle executing a search from mobile menu
     */
    const handleMobileExecuteSearch = () => {
      if (searchQuery.length > 2) {
        navigate(`/store?query=${encodeURIComponent(searchQuery)}`);
        setShowSuggestions(false);
      }
    };

    return (
      <Transition
        show={isMobileMenuOpen}
        as={React.Fragment}
        enter="transition ease-out duration-300 transform"
        enterFrom="-translate-y-full opacity-0"
        enterTo="translate-y-0 opacity-100"
        leave="transition ease-in duration-200 transform"
        leaveFrom="translate-y-0 opacity-100"
        leaveTo="-translate-y-full opacity-0"
      >
        <div className="md:hidden bg-white border-t border-gray-200" ref={ref}>
          <div className="px-4 py-3 space-y-1 sm:px-3">
            {/* Conditionally render "Shop" link based on nearestStore */}
            {nearestStore && (
              <CustomNavLink to="/store" label="Shop" onClick={() => setIsMobileMenuOpen(false)} />
            )}
            <CategoryDropdownMobile />
            <CustomNavLink to="/aboutus" label="About Us" onClick={() => setIsMobileMenuOpen(false)} />
            <CustomNavLink to="/contactus" label="Contact Us" onClick={() => setIsMobileMenuOpen(false)} />
            <CustomNavLink
              to="/terms-and-conditions"
              label="Terms & Conditions"
              onClick={() => setIsMobileMenuOpen(false)}
            />
            {user && user.role === 'admin' && (
              <CustomNavLink
                to="/admin/dashboard"
                label="Dashboard"
                onClick={() => setIsMobileMenuOpen(false)}
              />
            )}

            {/* Search Bar */}
            <div className="mt-3 relative">
              <div className="flex items-center">
                <FiSearch className="absolute ml-3 text-gray-400" />
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleMobileExecuteSearch();
                    }
                  }}
                  placeholder="Search desserts..."
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-pink-500 transition text-black"
                  aria-label="Search desserts"
                />
                <button
                  onClick={handleMobileExecuteSearch}
                  className="absolute right-2 text-pink-500 hover:text-pink-600 focus:outline-none"
                  aria-label="Execute search"
                >
                  <FiSearch />
                </button>
              </div>
              {showSuggestions && (
                <div className="absolute top-full left-0 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg z-50">
                  {suggestions.map((product) => (
                    <div
                      key={product.id}
                      className="px-4 py-2 hover:bg-gray-200 cursor-pointer text-black"
                      onClick={() => handleSelectSuggestion(product)}
                      role="button"
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') handleSelectSuggestion(product);
                      }}
                    >
                      {product.name}
                    </div>
                  ))}
                  {noMatches && <div className="px-4 py-2 text-gray-500">No matches found</div>}
                </div>
              )}
            </div>

            {/* "Order Now" Button */}
            <Link
              to="/store"
              className="block w-full text-center px-5 py-2 bg-pink-500 text-white rounded-full text-sm font-semibold hover:bg-pink-600 transition duration-150 shadow-md"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Order Now
            </Link>

            {/* User Links */}
            {user ? (
              <div className="space-y-1">
                <CustomNavLink to="/profile" label="Profile" onClick={() => setIsMobileMenuOpen(false)} />
                <CustomNavLink to="/orders" label="Orders" onClick={() => setIsMobileMenuOpen(false)} />
                {user.role === 'admin' && (
                  <CustomNavLink
                    to="/admin/dashboard"
                    label="Dashboard"
                    onClick={() => setIsMobileMenuOpen(false)}
                  />
                )}
                <button
                  onClick={() => {
                    logout();
                    setIsMobileMenuOpen(false);
                  }}
                  className="block w-full text-left px-4 py-2 text-sm text-black hover:bg-gray-200 transition duration-150 rounded-md"
                  role="menuitem"
                >
                  Logout
                </button>
              </div>
            ) : (
              <div className="space-y-1">
                <CustomNavLink to="/login" label="Login" onClick={() => setIsMobileMenuOpen(false)} />
                <CustomNavLink to="/register" label="Register" onClick={() => setIsMobileMenuOpen(false)} />
              </div>
            )}
          </div>
        </div>
      </Transition>
    );
  }
);

/**
 * CategoryDropdownMobile Component
 * Dropdown menu for product categories in the mobile menu.
 */
const CategoryDropdownMobile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const categories = [
    { name: 'Cakes', path: '/category/cakes' },
    { name: 'Cookies', path: '/category/cookies' },
    { name: 'Ice Cream', path: '/category/ice-cream' },
    { name: 'Pastries', path: '/category/pastries' },
    { name: 'Donuts', path: '/category/donuts' },
  ];

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full text-left px-3 py-2 rounded-md text-base font-medium text-black hover:bg-gray-200 transition duration-150 flex justify-between items-center"
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        Categories{' '}
        <FiChevronDown
          className={`transform ${isOpen ? 'rotate-180' : 'rotate-0'} transition-transform duration-200`}
        />
      </button>
      <Transition
        show={isOpen}
        as={React.Fragment}
        enter="transition ease-out duration-200 transform"
        enterFrom="opacity-0 max-h-0"
        enterTo="opacity-100 max-h-screen"
        leave="transition ease-in duration-150 transform"
        leaveFrom="opacity-100 max-h-screen"
        leaveTo="opacity-0 max-h-0"
      >
        <div className="ml-4 overflow-hidden transition-all duration-300">
          {categories.map((category) => (
            <CustomNavLink
              key={category.name}
              to={category.path}
              label={category.name}
              onClick={() => setIsOpen(false)}
            />
          ))}
        </div>
      </Transition>
    </div>
  );
};

/**
 * Navbar Component
 * The main navigation bar component with show/hide functionality based on scroll.
 */
const Navbar = () => {
  // Destructure cart functionalities from CartContext
  const { cartItems, clearCart, addItemToCart, removeItemFromCart } = useCart();
  const { user, logout } = useUserContext();
  const { nearestStore } = useStore(); // Access nearestStore directly
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isCartDropdownOpen, setIsCartDropdownOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [noMatches, setNoMatches] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation(); // Get current route

  // Access notifications from Redux store
  const notifications = useSelector((state) => state.notifications, shallowEqual) || [];

  // Debugging: Log notifications
  useEffect(() => {
    console.log('Navbar - Notifications:', notifications);
  }, [notifications]);

  // Calculate total number of items in the cart
  const cartItemCount = cartItems.reduce((count, item) => count + item.quantity, 0);

  // Debounced search function to optimize API calls
  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (query.length > 2) {
        try {
          const token = localStorage.getItem('token'); // Ensure token is accessible
          const response = await axios.get(
            `${config.API_URL}/products/search`, // Use config.API_URL
            {
              params: { query },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (response.data.success && response.data.data.length > 0) {
            setSuggestions(response.data.data);
            setNoMatches(false);
          } else {
            setSuggestions([]);
            setNoMatches(true);
          }
          setShowSuggestions(true);
        } catch (error) {
          console.error('Failed to fetch search results:', error);
          setSuggestions([]);
          setNoMatches(true);
          setShowSuggestions(true); // Show suggestions area even if no results
        }
      } else {
        setSuggestions([]);
        setShowSuggestions(false);
      }
    }, 300),
    []
  );

  useEffect(() => {
    debouncedSearch(searchQuery);
    // Cancel the debounce on useEffect cleanup.
    return debouncedSearch.cancel;
  }, [searchQuery, debouncedSearch]);

  /**
   * Handle selecting a search suggestion
   */
  const handleSelectSuggestion = (product) => {
    setShowSuggestions(false);
    navigate(`/products/${product.slug}`); // Navigate to the product detail page
  };

  /**
   * Handle executing a search
   */
  const handleExecuteSearch = () => {
    if (searchQuery.length > 2) {
      navigate(`/store?query=${encodeURIComponent(searchQuery)}`);
      setShowSuggestions(false);
    }
  };

  // Close dropdowns when clicking outside
  const profileRef = useRef();
  const cartRef = useRef();
  const notificationsRef = useRef();
  const mobileMenuRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setIsProfileOpen(false);
      }
      if (cartRef.current && !cartRef.current.contains(event.target)) {
        setIsCartDropdownOpen(false);
      }
      if (notificationsRef.current && !notificationsRef.current.contains(event.target)) {
        setIsNotificationsOpen(false);
      }
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        setIsMobileMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  /**
   * Navbar show/hide based on scroll direction
   */
  const [showNavbar, setShowNavbar] = useState(true);
  const lastScrollY = useRef(0);

  const controlNavbar = () => {
    if (typeof window !== 'undefined') {
      if (window.scrollY > lastScrollY.current) {
        // if scroll down hide the navbar
        setShowNavbar(false);
      } else {
        // if scroll up show the navbar
        setShowNavbar(true);
      }
      lastScrollY.current = window.scrollY;
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, []);

  /**
   * Handle marking a notification as read
   */
  const handleMarkAsRead = (id) => {
    dispatch(removeNotification(id));
    console.log(`Notification with id ${id} removed.`);
    // Optionally, show a toast notification
    toast.info('Notification dismissed.');
  };

  /**
   * Effect to handle user access on public routes
   * Redirect authenticated users away from public routes
   */
  useEffect(() => {
    const publicRoutes = ['/login', '/register'];
    if (user && publicRoutes.includes(location.pathname)) {
      // If user is authenticated and on a public route, redirect them
    }
  }, [user, location, navigate]);

  return (
    <>
      <ToastContainer />
      <nav
        className={`bg-white shadow-lg fixed w-full z-50 transition-transform duration-300 ${
          showNavbar ? 'top-0' : '-top-20'
        }`}
      >
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-20">
            {/* Logo */}
            <div className="flex-shrink-0">
              <Link to="/" className="flex items-center">
                <motion.img
                  src="/logo.png" // Use getImageUrl if needed
                  alt="Dessert Delight"
                  className="h-10 w-auto"
                  whileHover={{ scale: 1.1 }}
                  transition={{ type: 'spring', stiffness: 300 }}
                />
              </Link>
            </div>

            {/* Desktop Menu */}
            <div className="hidden md:flex items-center space-x-6">
              {/* Navigation Links */}
              <div className="flex items-center space-x-4">
                {/* Conditionally render "Shop" link based on nearestStore */}
                {nearestStore && <CustomNavLink to="/store" label="Shop" />}
                <CustomNavLink to="/aboutus" label="About Us" />
                <CustomNavLink to="/contactus" label="Contact Us" />
                {user && user.role === 'admin' && <CustomNavLink to="/admin" label="Admin" />}
              </div>

              {/* Search Bar with Search Button */}
              <div className="relative flex items-center">
                <FiSearch className="absolute ml-3 text-gray-400" />
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleExecuteSearch();
                    }
                  }}
                  placeholder="Search desserts..."
                  className="hidden md:block w-64 pl-10 pr-4 py-2 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-pink-500 transition text-black"
                  aria-label="Search desserts"
                />
                <button
                  onClick={handleExecuteSearch}
                  className="absolute right-2 text-pink-500 hover:text-pink-600 focus:outline-none"
                  aria-label="Execute search"
                >
                  <FiSearch />
                </button>
                {showSuggestions && (
                  <div className="absolute top-full left-0 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg z-50">
                    {suggestions.map((product) => (
                      <div
                        key={product.id}
                        className="px-4 py-2 hover:bg-gray-200 cursor-pointer text-black"
                        onClick={() => handleSelectSuggestion(product)}
                        role="button"
                        tabIndex={0}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') handleSelectSuggestion(product);
                        }}
                      >
                        {product.name}
                      </div>
                    ))}
                    {noMatches && <div className="px-4 py-2 text-gray-500">No matches found</div>}
                  </div>
                )}
              </div>

              {/* Cart and Notifications */}
              <div className="flex items-center space-x-4">
                {/* Notifications */}
                <Notifications
                  isNotificationsOpen={isNotificationsOpen}
                  setIsNotificationsOpen={setIsNotificationsOpen}
                  notifications={notifications}
                  handleMarkAsRead={handleMarkAsRead}
                  ref={notificationsRef}
                />

                {/* Cart Dropdown */}
                <CartDropdown
                  isCartDropdownOpen={isCartDropdownOpen}
                  setIsCartDropdownOpen={setIsCartDropdownOpen}
                  cart={cartItems} // Updated prop name
                  cartItemCount={cartItemCount}
                  clearCart={clearCart}
                  addItemToCart={addItemToCart}
                  removeItemFromCart={removeItemFromCart}
                  ref={cartRef}
                />

                {/* User Profile */}
                {user ? (
                  <UserProfileMenu
                    isProfileOpen={isProfileOpen}
                    setIsProfileOpen={setIsProfileOpen}
                    logout={logout}
                    user={user}
                    ref={profileRef}
                  />
                ) : (
                  <GuestMenu />
                )}
              </div>

              {/* "Order Now" Button */}
              <Link
                to="/store"
                className={`px-5 py-2 bg-pink-500 text-white rounded-full text-sm font-semibold hover:bg-pink-600 transition duration-150 shadow-md ${
                  nearestStore ? '' : 'hidden' // Hide if nearestStore is not set
                }`}
              >
                Order Now
              </Link>
            </div>

            {/* Mobile Menu Button */}
            <MobileMenuButton
              isMobileMenuOpen={isMobileMenuOpen}
              setIsMobileMenuOpen={setIsMobileMenuOpen}
            />
          </div>
        </div>

        {/* Mobile Menu */}
        <MobileMenu
          isMobileMenuOpen={isMobileMenuOpen}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
          user={user}
          logout={logout}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          suggestions={suggestions}
          showSuggestions={showSuggestions}
          noMatches={noMatches}
          handleSelectSuggestion={handleSelectSuggestion}
          handleExecuteSearch={handleExecuteSearch}
          setShowSuggestions={setShowSuggestions}
          ref={mobileMenuRef}
        />
      </nav>
    </>
  );
};

export default Navbar; // Exported Navbar at the top level
