import React from 'react';
import { FaTiktok, FaInstagram, FaCcVisa, FaCcMastercard, FaCcApplePay, FaCcPaypal } from 'react-icons/fa';
import { SiGooglepay } from 'react-icons/si'; // Importing SiGooglepay from Simple Icons
import { IconContext } from 'react-icons';

function Footer() {
  return (
    <footer className="bg-gray-50 text-gray-600 p-6">
      {/* Main Footer Content */}
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-3 gap-6 items-center">
        {/* Contact Information Section */}
        <div className="text-center sm:text-left">
          <h4 className="text-lg font-semibold mb-2">Contact Us</h4>
          <p className="text-sm">📧 Email: cncluton@gmail.com</p>
        </div>


        {/* Google Translate Section */}
        <div className="text-center sm:text-right">
          <div id="google_translate_element" className="inline-block"></div>
        </div>
      </div>

      {/* Payment Methods Section */}
      <div className="flex justify-center mt-6">
        <IconContext.Provider value={{ size: '40px', className: 'text-gray-600 hover:text-gray-800 transition-colors duration-200 mx-2' }}>
          <div className="flex flex-wrap justify-center items-center">
            <FaCcVisa aria-label="Visa" />
            <FaCcMastercard aria-label="MasterCard" />
            <FaCcApplePay aria-label="Apple Pay" />
            <SiGooglepay aria-label="Google Pay" /> {/* Using SiGooglepay */}
            <FaCcPaypal aria-label="PayPal" />
            {/* Add more payment icons as needed */}
          </div>
        </IconContext.Provider>
      </div>

      {/* Footer Navigation Links */}
      <div className="container mx-auto px-6 mt-6 flex flex-col sm:flex-row justify-between items-center">
        {/* Navigation Links */}
        <div className="flex space-x-4 mb-4 sm:mb-0">
          <a href="/about" className="text-sm hover:text-blue-500 transition-colors duration-200" aria-label="About Us">About Us</a>
          <a href="/contact" className="text-sm hover:text-blue-500 transition-colors duration-200" aria-label="Contact">Contact</a>
          <a href="/privacy" className="text-sm hover:text-blue-500 transition-colors duration-200" aria-label="Privacy Policy">Privacy Policy</a>
          <a href="/terms" className="text-sm hover:text-blue-500 transition-colors duration-200" aria-label="Terms of Service">Terms of Service</a>
        </div>
        {/* Attribution */}
        <div className="text-sm text-gray-400">
          © 2024, Cookies&Cream. Proudly created by 
          <a href="https://quantaum.co.uk" target="_blank" rel="noopener noreferrer" className="hover:text-blue-300 ml-1">Quantaum</a>.
        </div>
      </div>
    </footer>
  );
}

export default Footer;
