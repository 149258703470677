// frontend/src/components/IngredientsSelection.js

import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FaPlus, FaMinus, FaSearch, FaTimes, FaCheck, FaTimesCircle } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';

/**
 * IngredientsSelection Component
 * 
 * Handles the selection of ingredients with quantity adjustment, search, categorization, bulk actions, and real-time price display.
 * 
 * Props:
 * - ingredients: Array of ingredient objects.
 * - selectedIngredients: Array of selected ingredient objects with ingredientId and quantity.
 * - setSelectedIngredients: Function to update the selected ingredients state.
 */
const IngredientsSelection = ({ ingredients, selectedIngredients, setSelectedIngredients }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  
  const MAX_QUANTITY = 10;

  /**
   * Get unique categories including 'All'
   */
  const categories = useMemo(() => {
    const uniqueCategories = [...new Set(ingredients.map((ing) => ing.category))].filter(Boolean);
    return ['All', ...uniqueCategories];
  }, [ingredients]);

  /**
   * Filtered Ingredients Based on Search Term and Selected Category
   */
  const filteredIngredients = useMemo(() => {
    return ingredients.filter((ingredient) => {
      const matchesSearch = ingredient.name.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesCategory = selectedCategory === 'All' || ingredient.category === selectedCategory;
      return matchesSearch && matchesCategory;
    });
  }, [ingredients, searchTerm, selectedCategory]);

  /**
   * Get the quantity of a specific ingredient
   */
  const getIngredientQuantity = useCallback((ingredientId) => {
    const ingredient = selectedIngredients.find((ing) => ing.ingredientId === ingredientId);
    return ingredient ? ingredient.quantity : 0;
  }, [selectedIngredients]);

  /**
   * Increment the quantity of an ingredient
   */
  const incrementQuantity = useCallback((ingredientId) => {
    const ingredient = ingredients.find((ing) => ing.id === ingredientId);
    if (!ingredient) return;

    setSelectedIngredients((prev) => {
      const existingIngredient = prev.find((ing) => ing.ingredientId === ingredientId);
      if (existingIngredient) {
        if (existingIngredient.quantity < MAX_QUANTITY) {
          return prev.map((ing) =>
            ing.ingredientId === ingredientId ? { ...ing, quantity: ing.quantity + 1 } : ing
          );
        } else {
          toast.error(`Maximum quantity of ${MAX_QUANTITY} reached for ${ingredient.name}.`);
          return prev;
        }
      } else {
        return [...prev, { ingredientId, quantity: 1 }];
      }
    });
  }, [ingredients, MAX_QUANTITY, setSelectedIngredients]);

  /**
   * Decrement the quantity of an ingredient
   */
  const decrementQuantity = useCallback((ingredientId) => {
    const ingredient = ingredients.find((ing) => ing.id === ingredientId);
    if (!ingredient) return;

    setSelectedIngredients((prev) => {
      const existingIngredient = prev.find((ing) => ing.ingredientId === ingredientId);
      if (existingIngredient) {
        if (existingIngredient.quantity > 1) {
          return prev.map((ing) =>
            ing.ingredientId === ingredientId ? { ...ing, quantity: ing.quantity - 1 } : ing
          );
        } else {
          return prev.filter((ing) => ing.ingredientId !== ingredientId);
        }
      }
      return prev;
    });
  }, [ingredients, setSelectedIngredients]);

  /**
   * Handle Search Input Change
   */
  const handleSearchChange = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []);

  /**
   * Handle Category Selection Change
   */
  const handleCategoryChange = useCallback((category) => {
    setSelectedCategory(category);
  }, []);

  /**
   * Handle Select All Ingredients
   */
  const handleSelectAll = useCallback(() => {
    const allIngredients = ingredients.map((ingredient) => ({
      ingredientId: ingredient.id,
      quantity: 1,
    }));
    setSelectedIngredients(allIngredients);
    toast.success('All ingredients selected.');
  }, [ingredients, setSelectedIngredients]);

  /**
   * Handle Clear All Ingredients
   */
  const handleClearAll = useCallback(() => {
    setSelectedIngredients([]);
    toast.info('All ingredient selections cleared.');
  }, [setSelectedIngredients]);

  return (
    <div className="mb-8">
      <h3 className="text-2xl font-semibold text-gray-800 mb-6">Customize Your Ingredients</h3>
      
      {/* Search Bar */}
      <div className="relative mb-6">
        <FaSearch className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search ingredients..."
          className="pl-12 pr-4 py-3 w-full border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500 transition-colors duration-200"
          aria-label="Search Ingredients"
        />
        {searchTerm && (
          <button
            type="button"
            onClick={() => setSearchTerm('')}
            className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600 focus:outline-none"
            aria-label="Clear Search"
          >
            <FaTimes />
          </button>
        )}
      </div>

      {/* Category Filters */}
      <div className="mb-6 flex flex-wrap gap-3">
        {categories.map((category, idx) => (
          <button
            type="button"
            key={`${category}-${idx}`} // Ensuring unique keys by combining category name with index
            onClick={() => handleCategoryChange(category)}
            className={`px-5 py-2 rounded-full border transition-colors duration-200 flex items-center space-x-2 ${
              selectedCategory === category
                ? 'bg-pink-500 text-white border-pink-500'
                : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-100'
            }`}
            aria-label={`Filter by ${category}`}
          >
            {selectedCategory === category ? <FaCheck /> : <FaTimesCircle />}
            <span>{category}</span>
          </button>
        ))}
      </div>

      {/* Bulk Action Icons */}
      <div className="mb-6 flex space-x-4">
        <button
          type="button"
          onClick={handleSelectAll}
          className="flex items-center px-4 py-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-green-500"
          aria-label="Select All Ingredients"
        >
          <FaCheck className="mr-2" />
          Select All
        </button>
        <button
          type="button"
          onClick={handleClearAll}
          className="flex items-center px-4 py-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-red-500"
          aria-label="Clear All Ingredients"
        >
          <FaTimesCircle className="mr-2" />
          Clear All
        </button>
      </div>

      {/* Ingredients Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {filteredIngredients.length > 0 ? (
          filteredIngredients.map((ingredient) => {
            const quantity = getIngredientQuantity(ingredient.id);
            const isSelected = quantity > 0;
            const totalIngredientPrice = parseFloat(ingredient.price) * quantity;

            return (
              <motion.div
                key={ingredient.id}
                className={`relative bg-white rounded-lg shadow-md p-5 flex flex-col items-center transition-transform transform ${
                  isSelected ? 'scale-105 border-2 border-pink-500' : ''
                }`}
                whileHover={{ scale: 1.05 }}
              >
                {/* Ingredient Image */}
                {ingredient.imageUrl && (
                  <img
                    src={
                      /^https?:\/\//i.test(ingredient.imageUrl)
                        ? ingredient.imageUrl
                        : `${process.env.REACT_APP_API_URL}${ingredient.imageUrl}`
                    }
                    alt={ingredient.name}
                    className="w-16 h-16 object-cover rounded-full mb-3"
                    loading="lazy"
                  />
                )}

                {/* Ingredient Name */}
                <h4 className="text-lg font-medium text-gray-800 mb-1">{ingredient.name}</h4>

                {/* Ingredient Price */}
                <p className="text-sm text-gray-600 mb-2">£{parseFloat(ingredient.price).toFixed(2)}</p>

                {/* Total Ingredient Price */}
                {isSelected && (
                  <motion.p
                    className="text-sm text-gray-700 mb-3"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    Total: £{totalIngredientPrice.toFixed(2)}
                  </motion.p>
                )}

                {/* Quantity Controls */}
                <div className="flex items-center space-x-2">
                  {/* Decrement Quantity */}
                  <button
                    type="button"
                    onClick={() => decrementQuantity(ingredient.id)}
                    className={`p-2 rounded-full ${
                      quantity > 0
                        ? 'bg-pink-500 text-white hover:bg-pink-600'
                        : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                    } focus:outline-none focus:ring-2 focus:ring-pink-500 transition-colors duration-200`}
                    disabled={quantity === 0}
                    aria-label={`Decrease quantity of ${ingredient.name}`}
                  >
                    <FaMinus />
                  </button>

                  {/* Quantity Display */}
                  <motion.span
                    className="text-gray-700 font-medium w-6 text-center"
                    key={quantity}
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.2 }}
                  >
                    {quantity}
                  </motion.span>

                  {/* Increment Quantity */}
                  <button
                    type="button"
                    onClick={() => incrementQuantity(ingredient.id)}
                    className={`p-2 rounded-full ${
                      quantity < MAX_QUANTITY
                        ? 'bg-pink-500 text-white hover:bg-pink-600'
                        : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                    } focus:outline-none focus:ring-2 focus:ring-pink-500 transition-colors duration-200`}
                    disabled={quantity >= MAX_QUANTITY}
                    aria-label={`Increase quantity of ${ingredient.name}`}
                  >
                    <FaPlus />
                  </button>
                </div>
              </motion.div>
            );
          })
        ) : (
          <p className="text-gray-500 col-span-full text-center">No ingredients match your search.</p>
        )}
      </div>
    </div>
  );
};

// Define PropTypes for type checking
IngredientsSelection.propTypes = {
  ingredients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      category: PropTypes.string.isRequired,
      imageUrl: PropTypes.string, // Optional: If you have images for ingredients
    })
  ).isRequired,
  selectedIngredients: PropTypes.arrayOf(
    PropTypes.shape({
      ingredientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      quantity: PropTypes.number.isRequired,
    })
  ).isRequired,
  setSelectedIngredients: PropTypes.func.isRequired,
};

export default IngredientsSelection;
