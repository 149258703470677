// src/components/contexts/OrderContext.js

import React, { createContext, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useStore } from './StoreContext';
import { useUserContext } from './UserContext';
import api from '../../api';
import logger from '../../utils/logger';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../features/notifcation/notificationsSlice'; // Corrected import path
import { v4 as uuidv4 } from 'uuid'; // Import uuid

// Create OrderContext with default values
const OrderContext = createContext({
  order: null,
  fetchOrder: () => {},
  createOrder: () => {},
  updateOrder: () => {},
  cancelOrder: () => {},
  trackOrder: () => {},
  downloadInvoice: () => {},
  loading: false,
  error: null,
});

// Custom hook for consuming OrderContext
export const useOrder = () => {
  const context = useContext(OrderContext);
  if (!context) {
    throw new Error('useOrder must be used within an OrderProvider');
  }
  return context;
};

// OrderProvider component
export const OrderProvider = ({ children }) => {
  // Destructure only 'token' from useUserContext to fix ESLint error
  const { user, token } = useUserContext();
  
  // Destructure 'setOrders' from StoreContext
  const { setOrders } = useStore(); // Assuming StoreContext manages orders
  
  // Initialize Redux dispatch
  const dispatch = useDispatch();
  
  // Local state
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * Fetch order details by ID, including option groups and option choices.
   * @param {number|string} orderId - The ID of the order to fetch.
   * @returns {Promise<Object|null>} - The fetched order or null if failed.
   */
  const fetchOrder = useCallback(
    async (orderId) => {
      if (!token) {
        logger.warn('fetchOrder called without a valid token.');
        setError('Authentication required to fetch order.');
        toast.error('You must be logged in to view orders.');
        return null;
      }

      if (!orderId) {
        setError('Order ID is required to fetch order details.');
        toast.error('Order ID is required.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        const response = await api.get(`/orders/${orderId}`, {
          params: { include: 'optionGroups,optionChoices' }, // Include options
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const fetchedOrder = response.data.data;
        setOrder(fetchedOrder);
        logger.info(`Fetched order details for Order ID ${orderId}`);
        console.log('Fetched order details:', fetchedOrder);
        return fetchedOrder;
      } catch (err) {
        logger.error(`Failed to fetch order ID ${orderId}:`, err);
        setError('Failed to fetch order details.');
        toast.error('Failed to fetch order details.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Create a new order with selected option groups and choices.
   * @param {Object} orderData - The order data.
   * @param {string} orderData.orderType - 'pickup' or 'delivery'.
   * @param {Object} orderData.address - Delivery address (if orderType is 'delivery').
   * @param {Array} orderData.items - Array of cart items with options.
   * @returns {Promise<Object|null>} - The created order or null if failed.
   */
  const createOrder = useCallback(
    async (orderData) => {
      if (!orderData) {
        setError('Order data is required.');
        toast.error('Order data is required.');
        return null;
      }

      if (!token) {
        logger.warn('createOrder called without a valid token.');
        setError('Authentication required to create order.');
        toast.error('You must be logged in to create an order.');
        return null;
      }

      const { orderType, address, items } = orderData;

      // Validate orderType
      if (!orderType || !['pickup', 'delivery'].includes(orderType)) {
        setError('Valid orderType is required.');
        toast.error('Please select a valid order type (pickup or delivery).');
        return null;
      }

      // If delivery, address is required
      if (orderType === 'delivery' && !address) {
        setError('Delivery address is required for delivery orders.');
        toast.error('Delivery address is required for delivery orders.');
        return null;
      }

      // Validate items
      if (!items || !Array.isArray(items) || items.length === 0) {
        setError('At least one cart item is required to place an order.');
        toast.error('Your cart is empty. Please add items to your cart.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log('Creating order with data:', orderData);
        const response = await api.post('/orders', orderData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        const newOrder = response.data.data;
        setOrder(newOrder);
        setOrders((prevOrders) => [newOrder, ...prevOrders]);
        logger.info(`Order created successfully: Order ID ${newOrder.id}`);
        toast.success('Order created successfully!');
        console.log('Created order:', newOrder);
        return newOrder;
      } catch (err) {
        logger.error('Failed to create order:', err);
        setError('Failed to create order.');
        toast.error('Failed to create order.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token, setOrders]
  );

  /**
   * Update an existing order's status or details.
   * @param {number|string} orderId - The ID of the order to update.
   * @param {Object} updates - The updates to apply (e.g., status).
   * @returns {Promise<Object|null>} - The updated order or null if failed.
   */
  const updateOrder = useCallback(
    async (orderId, updates) => {
      if (!token) {
        logger.warn('updateOrder called without a valid token.');
        setError('Authentication required to update order.');
        toast.error('You must be logged in to update orders.');
        return null;
      }

      if (!orderId || !updates) {
        setError('Order ID and updates are required.');
        toast.error('Order ID and updates are required.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Updating order ID ${orderId} with updates:`, updates);
        const response = await api.put(`/orders/${orderId}`, updates, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const updatedOrder = response.data.data;
        setOrder(updatedOrder);
        setOrders((prevOrders) =>
          prevOrders.map((ord) => (ord.id === orderId ? updatedOrder : ord))
        );
        logger.info(`Order ID ${orderId} updated successfully.`);
        toast.success('Order updated successfully!');
        console.log('Updated order:', updatedOrder);
        return updatedOrder;
      } catch (err) {
        logger.error(`Failed to update order ID ${orderId}:`, err);
        setError('Failed to update order.');
        toast.error('Failed to update order.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token, setOrders]
  );

  /**
   * Cancel an existing order.
   * @param {number|string} orderId - The ID of the order to cancel.
   * @returns {Promise<Object|null>} - The canceled order or null if failed.
   */
  const cancelOrder = useCallback(
    async (orderId) => {
      if (!token) {
        logger.warn('cancelOrder called without a valid token.');
        setError('Authentication required to cancel order.');
        toast.error('You must be logged in to cancel orders.');
        return null;
      }

      if (!orderId) {
        setError('Order ID is required to cancel order.');
        toast.error('Order ID is required.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Cancelling order ID ${orderId}`);
        const response = await api.post(`/orders/${orderId}/cancel`, {}, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const canceledOrder = response.data.data;
        setOrder(canceledOrder);
        setOrders((prevOrders) =>
          prevOrders.map((ord) => (ord.id === orderId ? canceledOrder : ord))
        );
        logger.info(`Order ID ${orderId} canceled successfully.`);
        toast.success('Order canceled successfully.');
        console.log('Canceled order:', canceledOrder);
        return canceledOrder;
      } catch (err) {
        logger.error(`Failed to cancel order ID ${orderId}:`, err);
        setError('Failed to cancel order.');
        toast.error('Failed to cancel order.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token, setOrders]
  );

  /**
   * Track an order by fetching its current status and tracking number.
   * @param {number|string} orderId - The ID of the order to track.
   * @returns {Promise<Object|null>} - The tracking details or null if failed.
   */
  const trackOrder = useCallback(
    async (orderId) => {
      if (!token) {
        logger.warn('trackOrder called without a valid token.');
        setError('Authentication required to track order.');
        toast.error('You must be logged in to track orders.');
        return null;
      }

      if (!orderId) {
        setError('Order ID is required to track order.');
        toast.error('Order ID is required.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Tracking order ID ${orderId}`);
        const response = await api.get(`/orders/${orderId}/track`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const trackingDetails = response.data.data; // Expected: { status, trackingNumber }
        logger.info(`Tracked Order ID ${orderId}:`, trackingDetails);
        console.log('Tracking details:', trackingDetails);
        return trackingDetails;
      } catch (err) {
        logger.error(`Failed to track order ID ${orderId}:`, err);
        setError('Failed to track order.');
        toast.error('Failed to track order.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Download an invoice PDF for a specific order.
   * @param {number|string} orderId - The ID of the order.
   * @returns {Promise<void>} - Resolves when the invoice is downloaded.
   */
  const downloadInvoice = useCallback(
    async (orderId) => {
      if (!token) {
        logger.warn('downloadInvoice called without a valid token.');
        setError('Authentication required to download invoice.');
        toast.error('You must be logged in to download an invoice.');
        return;
      }

      if (!orderId) {
        setError('Order ID is required to download invoice.');
        toast.error('Order ID is required.');
        return;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Downloading invoice for order ID ${orderId}`);
        const response = await api.get(`/orders/${orderId}/invoice`, {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Create a blob from the PDF stream
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `invoice_${orderId}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        logger.info(`Invoice downloaded for Order ID ${orderId}`);
        toast.success('Invoice downloaded successfully!');
        console.log('Invoice downloaded successfully');
      } catch (err) {
        logger.error(`Failed to download invoice for Order ID ${orderId}:`, err);
        setError('Failed to download invoice.');
        toast.error('Failed to download invoice.');
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Send a notification (e.g., email) about order updates.
   * @param {number|string} orderId - The ID of the order.
   * @param {string} type - The type of notification (e.g., 'email').
   * @returns {Promise<Object|null>} - The result of the notification or null if failed.
   */
  const sendNotification = useCallback(
    async (orderId, type) => {
      if (!token) {
        logger.warn('sendNotification called without a valid token.');
        setError('Authentication required to send notifications.');
        toast.error('You must be logged in to send notifications.');
        return null;
      }

      if (!orderId || !type) {
        setError('Order ID and notification type are required.');
        toast.error('Order ID and notification type are required.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Sending ${type} notification for order ID ${orderId}`);
        await api.post(
          `/orders/${orderId}/notify`,
          { type },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        logger.info(`Notification sent via ${type} for Order ID ${orderId}`);
        toast.success(`Notification sent via ${type} successfully!`);
        console.log(`Notification sent via ${type} successfully!`);
      } catch (err) {
        logger.error(`Failed to send ${type} notification for Order ID ${orderId}:`, err);
        setError(`Failed to send ${type} notification.`);
        toast.error(`Failed to send ${type} notification.`);
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Update the quantity of a specific option choice within an order.
   * @param {number|string} orderId - The ID of the order.
   * @param {number|string} optionGroupId - The ID of the option group.
   * @param {number|string} choiceId - The ID of the option choice.
   * @param {number} newQuantity - The new quantity to set.
   * @returns {Promise<Object|null>} - The updated order or null if failed.
   */
  const updateOptionChoiceQuantity = useCallback(
    async (orderId, optionGroupId, choiceId, newQuantity) => {
      if (!token) {
        logger.warn('updateOptionChoiceQuantity called without a valid token.');
        setError('Authentication required to update order options.');
        toast.error('You must be logged in to update order options.');
        return null;
      }

      if (!orderId || !optionGroupId || !choiceId || newQuantity == null) {
        setError('Order ID, Option Group ID, Choice ID, and new quantity are required.');
        toast.error('Missing required information to update option quantity.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Updating choice ID ${choiceId} in option group ID ${optionGroupId} for order ID ${orderId} to quantity ${newQuantity}`);

        // Prepare the payload
        const payload = {
          optionGroups: [
            {
              optionGroupId,
              selectedChoiceIds: [
                {
                  choiceId,
                  quantity: newQuantity,
                },
              ],
            },
          ],
        };

        // Send the update request
        const response = await api.put(`/orders/${orderId}`, payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        const updatedOrder = response.data.data;
        setOrder(updatedOrder);
        setOrders((prevOrders) =>
          prevOrders.map((ord) => (ord.id === orderId ? updatedOrder : ord))
        );
        logger.info(`Updated choice ID ${choiceId} in order ID ${orderId} to quantity ${newQuantity}`);
        toast.success('Order option updated successfully!');
        console.log('Updated order:', updatedOrder);
        return updatedOrder;
      } catch (err) {
        logger.error(`Failed to update option choice quantity for Order ID ${orderId}:`, err);
        setError('Failed to update order option.');
        toast.error('Failed to update order option.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token, setOrders]
  );

  return (
    <OrderContext.Provider
      value={{
        order,
        fetchOrder,
        createOrder,
        updateOrder,
        cancelOrder,
        trackOrder,
        downloadInvoice,
        sendNotification,
        updateOptionChoiceQuantity,
        loading,
        error,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

// Define PropTypes for type checking
OrderProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OrderContext;
