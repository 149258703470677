// frontend/src/components/CartItem.js

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { FaMinus, FaPlus, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { getImageUrl } from '../utils/joinUrl';

const CartItem = ({
  item,
  products,
  onRemove,
  onIncreaseQuantity,
  onDecreaseQuantity,
  onIncreaseIngredient,
  onDecreaseIngredient,
}) => {
  // Find the associated product using productId
  const product = products.find((p) => p.id === item.productId);

  // If product not found, do not render the cart item
  if (!product) {
    console.warn(`Product with ID ${item.productId} not found.`);
    return null;
  }

  // Debugging Logs (Consider removing in production)
  console.log('Cart Item:', item);
  console.log('Product:', product);

  // Handler to remove the item from the cart
  const handleRemove = () => {
    onRemove(item);
  };

  // Handler to increase quantity by 1
  const handleIncreaseQuantity = () => {
    onIncreaseQuantity();
  };

  // Handler to decrease quantity by 1
  const handleDecreaseQuantity = () => {
    onDecreaseQuantity();
  };

  // Function to calculate the total price for this cart item
  const calculateItemTotal = () => {
    let total = parseFloat(product.price) * item.quantity;

    // Add price for each ingredient
    if (item.attributes?.ingredients) {
      item.attributes.ingredients.forEach((ing) => {
        const ingredient = product.ingredients.find((i) => i.id === ing.ingredientId);
        if (ingredient) {
          total += parseFloat(ingredient.price) * ing.quantity * item.quantity;
        }
      });
    }

    // Add price for each selected option
    if (item.selectedOptions && Array.isArray(item.selectedOptions)) {
      item.selectedOptions.forEach((so) => {
        // Find the option group
        const group = product.optionGroups.find((g) => g.id === so.optionGroupId);
        if (group && group.optionChoices) {
          // Find the choice within the group
          const choice = group.optionChoices.find((c) => c.id === so.optionChoiceId);
          if (choice) {
            total += parseFloat(choice.additionalPrice) * item.quantity;
          }
        }
      });
    }

    return total.toFixed(2);
  };

  // Function to render selected options with their group and choice names
  const renderSelectedOptions = () => {
    if (!item.selectedOptions || !Array.isArray(item.selectedOptions) || item.selectedOptions.length === 0) {
      console.log('No selected options for this item.');
      return null;
    }

    return (
      <div className="mt-2 text-sm text-gray-600 dark:text-gray-400">
        <strong>Options:</strong>
        <ul className="list-disc list-inside">
          {item.selectedOptions.map((so) => {
            // Find the option group
            const group = product.optionGroups.find((g) => g.id === so.optionGroupId);
            // Find the choice within the group
            const choice = group && group.optionChoices.find((c) => c.id === so.optionChoiceId);
            return (
              <li key={`${so.optionGroupId}-${so.optionChoiceId}`}>
                {group ? group.name : 'Unknown Group'}: {choice ? choice.name : 'Unknown Choice'}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.95 }}
      transition={{ duration: 0.3 }}
      className="flex flex-col lg:flex-row items-center bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow"
    >
      {/* Product Image */}
      <Link to={`/product/${product.slug}`} className="flex-shrink-0">
        <img
          src={getImageUrl(product.imageUrl)}
          alt={product.name}
          className="w-24 h-24 object-cover rounded"
          loading="lazy"
        />
      </Link>

      {/* Product Details */}
      <div className="ml-0 lg:ml-6 flex-1">
        <Link to={`/product/${product.slug}`}>
          <h2 className="text-xl font-semibold hover:underline">{product.name}</h2>
        </Link>

        {/* Selected Size */}
        {item.attributes?.size ? (
          <p className="text-sm text-gray-600 dark:text-gray-400">Size: {item.attributes.size}</p>
        ) : (
          <p className="text-sm text-gray-600 dark:text-gray-400">Size: Not selected</p>
        )}

        {/* Selected Options */}
        {renderSelectedOptions()}

        {/* Selected Ingredients */}
        {item.attributes?.ingredients && item.attributes.ingredients.length > 0 && (
          <div className="mt-2 text-sm text-gray-600 dark:text-gray-400">
            <strong>Ingredients:</strong>
            <ul className="list-disc list-inside">
              {item.attributes.ingredients.map((ing) => {
                const ingredient = product.ingredients.find((i) => i.id === ing.ingredientId);
                return (
                  <li key={ing.ingredientId} className="flex items-center justify-between">
                    <span>{ingredient ? ingredient.name : 'Unknown Ingredient'}</span>
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={() => onDecreaseIngredient(ing.ingredientId)}
                        className="p-1 bg-gray-200 dark:bg-gray-700 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
                        aria-label={`Decrease ${ingredient ? ingredient.name : 'ingredient'} quantity`}
                      >
                        <FaMinus size={12} />
                      </button>
                      <span>{ing.quantity}</span>
                      <button
                        onClick={() => onIncreaseIngredient(ing.ingredientId)}
                        className="p-1 bg-gray-200 dark:bg-gray-700 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
                        aria-label={`Increase ${ingredient ? ingredient.name : 'ingredient'} quantity`}
                      >
                        <FaPlus size={12} />
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>

      {/* Quantity Controls and Total Price */}
      <div className="mt-4 lg:mt-0 flex flex-col items-center space-y-4 lg:space-y-2">
        {/* Quantity Controls */}
        <div className="flex items-center space-x-2">
          <button
            onClick={handleDecreaseQuantity}
            className="p-2 bg-gray-200 dark:bg-gray-700 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
            aria-label="Decrease quantity"
          >
            <FaMinus />
          </button>
          <span className="font-bold">{item.quantity}</span>
          <button
            onClick={handleIncreaseQuantity}
            className="p-2 bg-gray-200 dark:bg-gray-700 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
            aria-label="Increase quantity"
          >
            <FaPlus />
          </button>
        </div>

        {/* Total Price */}
        <div>
          <span className="font-semibold">£{calculateItemTotal()}</span>
        </div>

        {/* Remove Item */}
        <button
          onClick={handleRemove}
          className="p-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors"
          aria-label="Remove item"
        >
          <FaTrash />
        </button>
      </div>
    </motion.div>
  );
};

CartItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    selectedOptions: PropTypes.arrayOf(
      PropTypes.shape({
        optionGroupId: PropTypes.number.isRequired,
        optionChoiceId: PropTypes.number.isRequired,
      })
    ),
    quantity: PropTypes.number.isRequired,
    attributes: PropTypes.shape({
      size: PropTypes.string,
      ingredients: PropTypes.arrayOf(
        PropTypes.shape({
          ingredientId: PropTypes.number.isRequired,
          quantity: PropTypes.number.isRequired,
        })
      ),
    }),
  }).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      sizeOptions: PropTypes.arrayOf(PropTypes.string),
      stockOptions: PropTypes.arrayOf(
        PropTypes.shape({
          size: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
          stock: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        })
      ),
      optionGroups: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          optionChoices: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired,
              name: PropTypes.string.isRequired,
              additionalPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            })
          ).isRequired,
        })
      ),
      optionChoices: PropTypes.array, // Now empty or unused
      ingredients: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
          name: PropTypes.string.isRequired,
          price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        })
      ),
    })
  ).isRequired,
  onRemove: PropTypes.func.isRequired,
  onIncreaseQuantity: PropTypes.func.isRequired,
  onDecreaseQuantity: PropTypes.func.isRequired,
  onIncreaseIngredient: PropTypes.func.isRequired,
  onDecreaseIngredient: PropTypes.func.isRequired,
};

export default memo(CartItem);
