// frontend/src/components/ProductCard.js

import React, { useState } from 'react';
import {
  FaHeart,
  FaStar,
  FaShoppingCart,
  FaRegEye,
} from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

/**
 * Animation variants for the details section
 */
const detailsVariants = {
  hidden: { opacity: 0, height: 0 },
  visible: { opacity: 1, height: 'auto' },
  exit: { opacity: 0, height: 0 },
};

/**
 * ProductCard Component
 * Displays individual product information with options to add to cart and wishlist.
 * Adjusts layout based on viewMode ('grid' or 'list').
 */
const ProductCard = ({ product, handleWishlist, handleAddToCart, isAdmin, viewMode }) => {
  // State for expandable details
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  /**
   * Handle Add to Cart Click
   */
  const handleAddToCartClick = (e) => {
    e.stopPropagation(); // Prevent triggering any parent events
    if (handleAddToCart) {
      handleAddToCart(product); // Invoke the passed handler with the product
    }
  };

  /**
   * Handle toggling the expandable details
   */
  const toggleDetails = () => {
    setIsDetailsOpen((prev) => !prev);
  };

  /**
   * Handle Wishlist Addition
   */
  const handleAddToWishlistClick = async (e) => {
    e.stopPropagation(); // Prevent triggering parent event
    if (handleWishlist) {
      await handleWishlist(product.id);
    }
  };

  // Determine the image URL. Adjust if you have a utility function for this.
  const imageUrl = product.imageUrl?.match(/^https?:\/\//i)
    ? product.imageUrl
    : `${process.env.REACT_APP_API_URL}${product.imageUrl}`;

  // Define CSS classes for grid and list views
  const containerClasses =
    viewMode === 'grid'
      ? 'flex flex-col border border-gray-200 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300 cursor-pointer'
      : 'flex justify-between items-center p-4 border-b border-gray-200 cursor-pointer';

  const imageClasses =
    viewMode === 'grid'
      ? 'object-cover h-48 w-full rounded-t-lg shadow-md'
      : 'object-cover h-24 w-24 rounded-lg shadow-md';

  const contentClasses =
    viewMode === 'grid'
      ? 'mt-4 flex-1'
      : 'flex-1 ml-4 flex flex-col';

  const actionButtonsClasses =
    viewMode === 'grid'
      ? 'mt-4 flex flex-col sm:flex-row sm:justify-between sm:items-center space-y-2 sm:space-y-0'
      : 'flex items-center space-x-4';

  /**
   * Renders the product card in list mode
   */
  const renderListMode = () => (
    <motion.div
      className={containerClasses}
      onClick={toggleDetails}
      initial={false}
      animate={{
        boxShadow: isDetailsOpen
          ? '0px 10px 20px rgba(0,0,0,0.15)'
          : '0px 0px 0px rgba(0,0,0,0)',
      }}
      transition={{ duration: 0.3 }}
      role="button"
      aria-labelledby={`product-title-${product.id}`}
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          toggleDetails();
        }
      }}
    >
      <div className="flex items-center w-full">
        {/* List mode: Smaller image on the left */}
        <div className="flex-shrink-0">
          <Zoom>
            <img
              src={imageUrl}
              alt={product.name}
              className={imageClasses}
              loading="lazy"
            />
          </Zoom>
        </div>

        <div className={contentClasses}>
          {/* Product Name */}
          <h2
            id={`product-title-${product.id}`}
            className="text-xl font-semibold text-gray-800 dark:text-gray-100"
          >
            {product.name}
          </h2>

          {/* Rating */}
          <div className="flex items-center mt-1">
            {[...Array(5)].map((_, index) => (
              <FaStar
                key={index}
                className={
                  index < product.rating ? 'text-yellow-500' : 'text-gray-300 dark:text-gray-500'
                }
                size={14}
                aria-hidden="true"
              />
            ))}
            <span className="ml-2 text-gray-600 dark:text-gray-300">
              ({product.reviewCount || 0})
            </span>
          </div>

          {/* Price */}
          <p className="text-lg font-bold mt-2 text-gray-800 dark:text-gray-100">
            £{parseFloat(product.price).toFixed(2)}
          </p>
        </div>
      </div>

      {/* Action Buttons */}
      <div
        className={actionButtonsClasses}
        onClick={(e) => e.stopPropagation()} // Prevent triggering toggleDetails when clicking buttons
      >
        {/* Cart Icon Button */}
        {handleAddToCart && (
          <button
            onClick={handleAddToCartClick}
            className="text-green-500 hover:text-green-600 focus:outline-none"
            aria-label="Add to Cart"
            type="button"
          >
            <FaShoppingCart size={20} />
          </button>
        )}

        {/* Wishlist Button */}
        {handleWishlist && (
          <button
            onClick={handleAddToWishlistClick}
            className="text-pink-500 hover:text-pink-700 dark:text-pink-300 dark:hover:text-pink-500 focus:outline-none"
            aria-label="Add to Wishlist"
            type="button"
          >
            <FaHeart size={20} />
          </button>
        )}
      </div>

      {/* Expandable Details Section */}
      <AnimatePresence>
        {isDetailsOpen && (
          <motion.div
            key="details"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={detailsVariants}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            className="overflow-hidden bg-gray-50 dark:bg-gray-700 rounded-lg shadow-inner p-4 mt-4"
          >
            {/* Detailed Product Information */}
            <h3 className="text-xl font-bold mb-2 text-gray-800 dark:text-gray-100">
              Product Details
            </h3>
            <p className="text-gray-700 dark:text-gray-300 mb-4">{product.description}</p>

            {/* Option Groups Details */}
            {product.optionGroups && product.optionGroups.length > 0 && (
              <div className="mt-4">
                <h4 className="text-lg font-semibold mb-2 text-gray-800 dark:text-gray-100">
                  Options:
                </h4>
                {product.optionGroups.map((group) => (
                  <div key={group.id} className="mb-2">
                    <span className="font-medium text-gray-700 dark:text-gray-200">
                      {group.name}:
                    </span>{' '}
                    {
                      // This is a placeholder. Adjust logic to display selected options if needed.
                      group.choices && group.choices.length > 0
                        ? group.choices.map((choice) => choice.name).join(', ')
                        : 'None'
                    }
                  </div>
                ))}
              </div>
            )}

            {/* Recipe Section */}
            {product.recipe && (
              <div className="mt-4">
                <h4 className="text-lg font-semibold mb-2 text-gray-800 dark:text-gray-100">
                  Recipe
                </h4>
                <p className="text-gray-600 dark:text-gray-300 mb-4">
                  {product.recipe.instructions}
                </p>
                {product.recipe.recipeIngredients &&
                  product.recipe.recipeIngredients.length > 0 && (
                    <div>
                      <h5 className="text-md font-medium mb-2 text-gray-700 dark:text-gray-200">
                        Ingredients:
                      </h5>
                      <ul className="list-disc list-inside text-gray-600 dark:text-gray-300">
                        {product.recipe.recipeIngredients.map((ing) => (
                          <li key={ing.id}>
                            {ing.quantity} {ing.unit} of {ing.ingredient.name}{' '}
                            {ing.note && `(${ing.note})`}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
              </div>
            )}

            {/* Reviews Section */}
            <div className="mt-4">
              <h4 className="text-lg font-semibold mb-2 text-gray-800 dark:text-gray-100">
                Reviews
              </h4>
              {product.reviews && product.reviews.length > 0 ? (
                product.reviews.map((review) => (
                  <div key={review.id} className="mb-4">
                    <div className="flex items-center">
                      {[...Array(5)].map((_, index) => (
                        <FaStar
                          key={index}
                          className={
                            index < review.rating
                              ? 'text-yellow-500'
                              : 'text-gray-300 dark:text-gray-500'
                          }
                          size={14}
                          aria-hidden="true"
                        />
                      ))}
                      <span className="ml-2 text-gray-600 dark:text-gray-300">
                        {review.comment}
                      </span>
                    </div>
                    <p className="text-sm text-gray-500 dark:text-gray-400">— {review.user.name}</p>
                  </div>
                ))
              ) : (
                <p className="text-gray-600 dark:text-gray-300">No reviews yet.</p>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );

  /**
   * Renders the product card in grid mode
   */
  const renderGridMode = () => (
    <>
      <motion.div
        whileHover={{
          scale: 1.02,
          boxShadow: '0px 10px 20px rgba(0,0,0,0.15)',
        }}
        className={containerClasses}
        role="button"
        aria-labelledby={`product-title-${product.id}`}
        tabIndex={0}
        onClick={toggleDetails} // Toggle details on card click
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            toggleDetails();
          }
        }}
      >
        {/* Product Image */}
        <div className="relative">
          <Zoom>
            <img
              src={imageUrl}
              alt={product.name}
              className={imageClasses}
              loading="lazy"
            />
          </Zoom>
          {/* Wishlist Button */}
          {handleWishlist && (
            <button
              onClick={handleAddToWishlistClick}
              className="absolute top-3 right-3 text-pink-500 hover:text-pink-700 dark:text-pink-300 dark:hover:text-pink-500 focus:outline-none"
              aria-label="Add to Wishlist"
              type="button"
            >
              <FaHeart size={24} />
            </button>
          )}
        </div>

        <div className={contentClasses}>
          {/* Product Category */}
          {product.category && (
            <p className="text-sm text-gray-500 mt-3 dark:text-gray-400">
              {product.category.name}
            </p>
          )}

          {/* Product Name */}
          <h2
            id={`product-title-${product.id}`}
            className="text-xl font-semibold mt-2 flex items-center text-gray-800 dark:text-gray-100"
          >
            {product.name}
            {/* Optional Recipe Icon */}
            {product.recipe && (
              <FaRegEye
                className="ml-2 text-gray-400 hover:text-gray-600 dark:text-gray-300 dark:hover:text-gray-100 cursor-pointer"
                title="View Recipe"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDetails();
                }}
                aria-label="View Recipe"
                type="button"
              />
            )}
          </h2>

          {/* Product Rating */}
          <div className="flex items-center mt-2">
            {[...Array(5)].map((_, index) => (
              <FaStar
                key={index}
                className={
                  index < product.rating ? 'text-yellow-500' : 'text-gray-300 dark:text-gray-500'
                }
                size={16}
                aria-hidden="true"
              />
            ))}
            <span className="ml-2 text-gray-600 dark:text-gray-300">
              ({product.reviewCount || 0})
            </span>
          </div>

          {/* Product Price */}
          <p className="text-2xl font-bold mt-3 text-gray-800 dark:text-gray-100">
            £{parseFloat(product.price).toFixed(2)}
          </p>

          {/* Action Buttons */}
          <div
            className={actionButtonsClasses}
            onClick={(e) => e.stopPropagation()} // Prevent triggering toggleDetails when clicking buttons
          >
            {/* Add to Cart Button */}
            {handleAddToCart && (
              <button
                onClick={handleAddToCartClick}
                className="flex items-center justify-center bg-green-500 hover:bg-green-600 dark:bg-green-600 dark:hover:bg-green-700 text-white font-semibold py-2 px-4 rounded-md shadow transition-colors duration-300"
                aria-label="Add to Cart"
                type="button"
              >
                <FaShoppingCart className="mr-2" /> Add to Cart
              </button>
            )}

            {/* View Details Button */}
            <button
              onClick={toggleDetails}
              className="flex items-center justify-center bg-blue-500 hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-md shadow transition-colors duration-300"
              aria-label="View Details"
              type="button"
            >
              <FaRegEye className="mr-2" />{' '}
              {isDetailsOpen ? 'Hide Details' : 'View Details'}
            </button>
          </div>
        </div>
      </motion.div>

      {/* Expandable Details Section */}
      <AnimatePresence>
        {isDetailsOpen && (
          <motion.div
            key="details"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={detailsVariants}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            className="overflow-hidden bg-gray-50 dark:bg-gray-700 rounded-b-lg shadow-inner p-6"
          >
            {/* Detailed Product Information */}
            <h3 className="text-2xl font-bold mb-4 text-gray-800 dark:text-gray-100">
              Product Details
            </h3>
            <p className="text-gray-700 dark:text-gray-300 mb-4">{product.description}</p>

            {/* Option Groups Details */}
            {product.optionGroups && product.optionGroups.length > 0 && (
              <div className="mt-6">
                <h4 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-100">
                  Options:
                </h4>
                {product.optionGroups.map((group) => (
                  <div key={group.id} className="mb-2">
                    <span className="font-medium text-gray-700 dark:text-gray-200">
                      {group.name}:
                    </span>{' '}
                    {
                      // This is a placeholder. Adjust logic to display selected options if needed.
                      group.choices && group.choices.length > 0
                        ? group.choices.map((choice) => choice.name).join(', ')
                        : 'None'
                    }
                  </div>
                ))}
              </div>
            )}

            {/* Recipe Section */}
            {product.recipe && (
              <div className="mt-6">
                <h4 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-100">
                  Recipe
                </h4>
                <p className="text-gray-600 dark:text-gray-300 mb-4">
                  {product.recipe.instructions}
                </p>
                {product.recipe.recipeIngredients &&
                  product.recipe.recipeIngredients.length > 0 && (
                    <div>
                      <h5 className="text-lg font-medium mb-2 text-gray-700 dark:text-gray-200">
                        Ingredients:
                      </h5>
                      <ul className="list-disc list-inside text-gray-600 dark:text-gray-300">
                        {product.recipe.recipeIngredients.map((ing) => (
                          <li key={ing.id}>
                            {ing.quantity} {ing.unit} of {ing.ingredient.name}{' '}
                            {ing.note && `(${ing.note})`}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
              </div>
            )}

            {/* Reviews Section */}
            <div className="mt-6">
              <h4 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-100">
                Reviews
              </h4>
              {product.reviews && product.reviews.length > 0 ? (
                product.reviews.map((review) => (
                  <div key={review.id} className="mb-4">
                    <div className="flex items-center">
                      {[...Array(5)].map((_, index) => (
                        <FaStar
                          key={index}
                          className={
                            index < review.rating
                              ? 'text-yellow-500'
                              : 'text-gray-300 dark:text-gray-500'
                          }
                          size={14}
                          aria-hidden="true"
                        />
                      ))}
                      <span className="ml-2 text-gray-600 dark:text-gray-300">
                        {review.comment}
                      </span>
                    </div>
                    <p className="text-sm text-gray-500 dark:text-gray-400">— {review.user.name}</p>
                  </div>
                ))
              ) : (
                <p className="text-gray-600 dark:text-gray-300">No reviews yet.</p>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );

  // Return the final markup based on viewMode
  return viewMode === 'list' ? renderListMode() : renderGridMode();
};

// Define propTypes after the component definition
ProductCard.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    description: PropTypes.string,
    imageUrl: PropTypes.string,
    rating: PropTypes.number,
    reviewCount: PropTypes.number,
    categoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    category: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    }),
    recipe: PropTypes.shape({
      instructions: PropTypes.string,
      recipeIngredients: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
          unit: PropTypes.string,
          quantity: PropTypes.number,
          ingredient: PropTypes.shape({
            name: PropTypes.string,
          }),
          note: PropTypes.string,
        })
      ),
    }),
    reviews: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        rating: PropTypes.number.isRequired,
        comment: PropTypes.string.isRequired,
        user: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired,
      })
    ),
    optionGroups: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        required: PropTypes.bool,
        choices: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
            additionalPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          })
        ),
      })
    ),
  }).isRequired,
  handleWishlist: PropTypes.func.isRequired,
  handleAddToCart: PropTypes.func, // Made optional in case it's not provided
  isAdmin: PropTypes.bool,
  viewMode: PropTypes.oneOf(['grid', 'list']).isRequired,
};

ProductCard.defaultProps = {
  isAdmin: false,
  handleAddToCart: null,
};

export default React.memo(ProductCard);
